"use client";
import { useSearchParams } from "next/navigation";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { NextImage } from "src/components/next-image/NextImage";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { RichText } from "../../base-components/rich-text/RichText";
import { Button } from "../button/Button";
import { Grid } from "../../base-components/grid/Grid";
import {
  IGenPageInfo,
  IGenReleaseNote_ConnectionEdge,
} from "../../utils/types_gen";
import { SCompChangelogReleases } from "./styles/SCompChangelogReleases";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { SCompChangelogReleasesDate } from "./styles/SCompChangelogReleasesDate";
import { SCompChangelogReleasesImageCont } from "./styles/SCompChangelogReleasesImageCont";
import { SCompChangelogReleasesRightContent } from "./styles/SCompChangelogReleasesRightContent";
import { SCompChangelogReleasesImageWrapper } from "./styles/SCompChangelogReleasesImageWrapper";
import { SCompChangelogReleasesText } from "./styles/SCompChangelogReleasesText";
import { SCompChangelogReleasesImage } from "./styles/SCompChangelogReleasesImage";
import { SCompChangelogReleasesButtonsWrapper } from "./styles/SCompChangelogReleasesButtonsWrapper";
import { SCompChangelogReleasesLoadMoreButton } from "./styles/SCompChangelogReleasesLoadMoreButton";

export interface ICompChangelogReleases {
  edges?: IGenReleaseNote_ConnectionEdge[];
  pageInfo?: IGenPageInfo;
}

const handleDateFormat = (date, locale) => {
  const releaseRate = new Date(date);
  const getDate = (options: Intl.DateTimeFormatOptions) =>
    releaseRate.toLocaleString(locale, options);
  return `${getDate({ day: "numeric" })} ${getDate({
    month: "long",
  })} ${getDate({ year: "numeric" })}`;
};

export const CompChangelogReleasesInner: React.FC<ICompChangelogReleases> = ({
  edges,
  pageInfo,
}) => {
  if (!edges) return null;

  const router = useSearchParams();
  const [cursor, setCurser] = useState(pageInfo?.endCursor);
  const hasNextPage = useRef(pageInfo?.hasNextPage);
  const [releases, setReleases] = useState(edges);

  const getReleases = useCallback(async () => {
    const r = await (
      await fetch(
        `/api/landing/changelog?locale=${
          router?.get("locale") || "en"
        }&cursor=${cursor}`,
        {
          method: "GET",
        }
      )
    ).json();

    if (r?.data?.allReleaseNote) {
      hasNextPage.current = r.data.allReleaseNote.pageInfo?.hasNextPage;
      setReleases([...releases, ...r.data.allReleaseNote.edges]);
      setCurser(r.data.allReleaseNote.pageInfo?.endCursor);
    }
  }, [cursor]);
  const lacelsHNAA = router.get("locale");
  console.log({ lacelsHNAA });

  return (
    <SCompChangelogReleases>
      <GridPadding>
        <Grid>
          {releases?.map((item, index) => (
            <Fragment key={`${item?.node?.id}-${index}-releases`}>
              <GridItem
                bronze={{ start: 1, end: 12 }}
                gold={{ start: 1, end: 4 }}
                diamond={{ start: 2, end: 3 }}
              >
                {item.node?.releaseDate && (
                  <SCompChangelogReleasesDate>
                    {handleDateFormat(
                      item.node.releaseDate,
                      router.get("locale") || "en"
                    )}
                  </SCompChangelogReleasesDate>
                )}
              </GridItem>
              <GridItem
                bronze={{ start: 1, end: 12 }}
                gold={{ start: 5, end: 12 }}
                diamond={{ start: 4, end: 11 }}
              >
                {item?.node?.blocks?.map(
                  (block) =>
                    block && (
                      <SCompChangelogReleasesRightContent
                        key={block.id}
                        isLast={index === releases.length - 1}
                      >
                        {!!block?.images?.length && (
                          <SCompChangelogReleasesImageWrapper>
                            {block?.images?.map(
                              (img) =>
                                img &&
                                img.src &&
                                block.images && (
                                  <SCompChangelogReleasesImageCont
                                    key={img?.id}
                                  >
                                    <SCompChangelogReleasesImage
                                      moreThanOne={block.images.length > 1}
                                    >
                                      <NextImage
                                        alt={
                                          (img.description || img.title) ?? ""
                                        }
                                        src={img.src}
                                        fill
                                        style={{ objectFit: "cover" }}
                                        priority
                                      />
                                    </SCompChangelogReleasesImage>
                                  </SCompChangelogReleasesImageCont>
                                )
                            )}
                          </SCompChangelogReleasesImageWrapper>
                        )}
                        {!!block?.text?.json?.content?.length && (
                          <SCompChangelogReleasesText
                            hasImages={!!block?.images?.length}
                          >
                            <RichText content={block.text?.json.content} />
                          </SCompChangelogReleasesText>
                        )}
                        {!!block?.links?.length && (
                          <SCompChangelogReleasesButtonsWrapper>
                            {block.links.map(
                              (btn) =>
                                btn && (
                                  <Button
                                    key={btn.id ?? ""}
                                    id={btn.id || ""}
                                    {...btn}
                                  >
                                    {btn.title}
                                  </Button>
                                )
                            )}
                          </SCompChangelogReleasesButtonsWrapper>
                        )}
                      </SCompChangelogReleasesRightContent>
                    )
                )}
              </GridItem>
            </Fragment>
          ))}
        </Grid>
        {hasNextPage.current && (
          <SCompChangelogReleasesLoadMoreButton>
            <Button
              onClick={() => {
                getReleases();
              }}
            >
              Load more
            </Button>
          </SCompChangelogReleasesLoadMoreButton>
        )}
      </GridPadding>
    </SCompChangelogReleases>
  );
};
