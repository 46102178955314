"use client";
import styled from "styled-components";

export const SOnboardingTemplatesTemplateFooter = styled.div`
  margin: 20px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
