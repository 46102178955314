"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ICompImageSliderImageWrapper {
  hasTwo?: boolean;
  index?: boolean;
}

const Bronze = css<ICompImageSliderImageWrapper>`
  span {
    border-radius: 12px;
  }

  position: relative;
  flex-grow: 1;
  position: relative;
  padding-top: ${({ hasTwo }) => (hasTwo ? "77.2%" : "167%")};

  > div:first-child {
    position: absolute;
    left: 50%;
    bottom: 24px;
    z-index: 10;
    > a {
      margin-left: -50%;
    }
  }
`;

const Silver = css<ICompImageSliderImageWrapper>``;

const Gold = css<ICompImageSliderImageWrapper>`
  padding-top: ${({ hasTwo }) => (hasTwo ? "66.995%" : "69.9028341535%")};
`;

const Platinum = css<ICompImageSliderImageWrapper>``;

const Diamond = css<ICompImageSliderImageWrapper>``;

export const SCompImageSliderImageWrapper = styled.div<ICompImageSliderImageWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
