"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading01,
  CSSHeading01Small,
  CSSHeading01XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompTableBlockTitle {
  isSectionBgWhite: boolean;
}

const Bronze = css<ISCompTableBlockTitle>`
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  ${CSSHeading01Small};
  margin: 0 auto 40px;
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
`;

const Silver = css<ISCompTableBlockTitle>``;

const Gold = css<ISCompTableBlockTitle>`
  ${CSSHeading01};
  margin-bottom: 56px;
`;

const Platinum = css<ISCompTableBlockTitle>``;

const Diamond = css<ISCompTableBlockTitle>`
  ${CSSHeading01XLarge};
`;

export const SCompTableBlockTitle = styled.div<ISCompTableBlockTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
