"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  display: none;
`;

const Silver = css``;

const Gold = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 56px;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  color: var(--icon-02);
  line-height: 0;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const Platinum = css``;

const Diamond = css``;

export const SCompQuoteCardCompanyGridSvgWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
