"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISSCompSubpageHero {
  $bgGradient?: string;
  $backgroundColor?: string;
}

const Bronze = css<ISSCompSubpageHero>`
  background: ${({ $bgGradient, $backgroundColor }) =>
    $backgroundColor
      ? `${$backgroundColor}`
      : $bgGradient
      ? backgroundGradients[$bgGradient]
      : "var(--blue-gradient)"};
  padding: 100px 0 33px 0;
`;

const Silver = css<ISSCompSubpageHero>``;

const Gold = css<ISSCompSubpageHero>`
  padding: 100px 0 99px 0;
`;

const Platinum = css<ISSCompSubpageHero>``;

const Diamond = css<ISSCompSubpageHero>`
  /* padding: 42px 0 42px 0; */
`;

export const SCompSubpageHero = styled.div<ISSCompSubpageHero>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
