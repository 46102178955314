"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  border-radius: 12px;
`;

const Silver = css``;

const Gold = css`
  background: #111d34;
  padding: 44px;
`;

const Platinum = css``;

const Diamond = css`
  padding: 80px;
`;

export const SRoadmapContainer = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
