"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompHero {
  bgGradient?: string;
  hasButton?: boolean;
}

const Bronze = css<ISCompHero>`
  background: ${({ bgGradient }) =>
    bgGradient ? backgroundGradients[bgGradient] : "var(--blue-gradient)"};
  padding-top: 100px;
  padding-bottom: ${({ hasButton }) => (hasButton ? "100px" : "180px")};
`;

const Silver = css<ISCompHero>``;

const Gold = css<ISCompHero>`
  padding-top: 152px;
  padding-bottom: ${({ hasButton }) => (hasButton ? "194px" : "290px")};
`;

const Platinum = css<ISCompHero>``;

const Diamond = css<ISCompHero>`
  padding-top: 200px;
  padding-bottom: ${({ hasButton }) => (hasButton ? "340px" : "452px")};
`;

export const SCompHero = styled.div<ISCompHero>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
