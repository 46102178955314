import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css<{ width: number }>`
  overflow: hidden;
  position: sticky;
  top: 68px;
  background-color: white;
  z-index: 2;
  max-width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  ${({ width }) => width && `width: ${width}px;`};
  border-bottom: 2px solid var(--active-ui-01-2);
`;

const Silver = css`
  overflow: visible;
`;

const Gold = css`
  top: 80px;
`;

const Platinum = css``;

const Diamond = css`
  top: 100px;
`;

export const SCompTableHeaderScroller = styled.div<{ width: number }>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
