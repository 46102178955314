"use client";
import React, { useState } from "react";
import { SCompMultiImageWithText } from "./styles/SCompMultiImageWithText";
import {
  IGenImageText,
  IGenMultiItemImageWithText,
} from "../../utils/types_gen";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { SCompMultiImageWithTextHeadline } from "./styles/SCompMultiImageWithTextHeadline";
import { SCompMultiImageWithTextButtonsWrapper } from "./styles/SCompMultiImageWithTextButtonsWrapper";
import { SCompMultiImageWithTextButton } from "./styles/SCompMultiImageWithTextButton";
import { SCompMultiImageWithTextItem } from "./styles/SCompMultiImageWithTextItem";
import { CompImageText } from "../comp-image-text/CompImageText";
import { SCompMultiImageWithTextHeightFaker } from "./styles/SCompMultiImageWithTextHeightFaker";
import { SCompMultiImageWithTextHeightVisibleElement } from "./styles/SCompMultiImageWithTextHeightVisibleElement";

function findElementWithHighestAspectRatio(tabs: any) {
  return tabs.reduce((maxRatioElement, currentElement) => {
    if (!currentElement?.smallScreenImage?.width) return maxRatioElement;
    if (!currentElement?.smallScreenImage?.height) return maxRatioElement;

    const currentRatio =
      currentElement.smallScreenImage.width /
      currentElement.smallScreenImage.height;
    const maxRatio =
      maxRatioElement.smallScreenImage.width /
      maxRatioElement.smallScreenImage.height;
    return currentRatio < maxRatio ? currentElement : maxRatioElement;
  });
}

export type IMultiImageWithText = Omit<
  IGenMultiItemImageWithText,
  "__typename"
>;
export const CompMultiImageWithTextInner: React.FC<IMultiImageWithText> = ({
  backgroundGradient = "green",
  headline,
  tabs,
}) => {
  const [selectedItem, setSelectedItem] = useState<{
    index: number;
    before: number;
  }>({ index: 0, before: -1 });

  return tabs ? (
    <GridPadding>
      <SCompMultiImageWithText $backgroundGradient={backgroundGradient}>
        <SCompMultiImageWithTextHeadline>
          {headline}
        </SCompMultiImageWithTextHeadline>
        <SCompMultiImageWithTextButtonsWrapper>
          {tabs.map((item, i) => {
            const selected: boolean = selectedItem.index === i;

            return (
              <SCompMultiImageWithTextButton
                key={`custom-wrapper-button-${i}`}
                $backgroundGradient={backgroundGradient}
                selected={selected}
                onClick={() =>
                  setSelectedItem((prev) => ({
                    index: i,
                    before: prev.index,
                  }))
                }
              >
                {item?.tabLabel ?? item?.title ?? "Default"}
              </SCompMultiImageWithTextButton>
            );
          })}
        </SCompMultiImageWithTextButtonsWrapper>
        <SCompMultiImageWithTextItem>
          {tabs.map((item, i) => {
            const shiftRight = i < selectedItem.index;
            return (
              <SCompMultiImageWithTextHeightVisibleElement
                key={`tab-${i}`}
                $active={selectedItem.index === i}
                $shiftRight={shiftRight}
              >
                <CompImageText
                  {...(item as unknown as IGenImageText)}
                  reverseOrderOnSmallScreen
                />
              </SCompMultiImageWithTextHeightVisibleElement>
            );
          })}
          {tabs[0] && (
            <SCompMultiImageWithTextHeightFaker tabsLength={1}>
              <div>
                <CompImageText
                  key={`tab-fake-${selectedItem}`}
                  {...(findElementWithHighestAspectRatio(tabs) ||
                    (tabs[0] as unknown as IGenImageText))}
                  reverseOrderOnSmallScreen
                />
              </div>
            </SCompMultiImageWithTextHeightFaker>
          )}
        </SCompMultiImageWithTextItem>
      </SCompMultiImageWithText>
    </GridPadding>
  ) : (
    <></>
  );
};
