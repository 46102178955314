"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { backgroundGradients } from "../../../constants/styles/gradients";
import { CSSmallButtons } from "../../../constants/styles/typographies/CSSTypographies";

interface IMultiItemButton {
  selected?: boolean;
  $backgroundGradient?: string;
}

const Bronze = css<IMultiItemButton>`
  cursor: pointer;
  ${CSSmallButtons};
  padding: 8px 16px;
  margin: 0 8px;
  background: ${({ selected, $backgroundGradient }) =>
    selected ? backgroundGradients[$backgroundGradient] : "var(--ui-01)"};
  box-shadow: ${({ selected }) =>
    selected ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "none"};
  color: ${({ selected }) => (selected ? "var(--ui-01)" : "var(--text-01)")};
  border: 0;
  outline: 0;
  border-radius: 100px;
  height: 36px;
  white-space: nowrap;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompMultiImageWithTextButton = styled.button<IMultiItemButton>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
