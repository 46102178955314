"use client";
import React, { useRef } from "react";
import { RichText } from "../../../base-components/rich-text/RichText";
import MinusClear from "../../../svgs/MinusClear";
import PlusClear from "../../../svgs/PlusClear";
import { IGenFaqItem } from "../../../utils/types_gen";
import { SCompFaqItem } from "./styles/SCompFaqItem";
import { SCompFaqItemFaqHeader } from "./styles/SCompFaqItemFaqHeader";
import { SCompFaqItemItemHeaderWrapper } from "./styles/SCompFaqItemItemHeaderWrapper";
import { SCompFaqItemSvgWrapper } from "./styles/SCompFaqItemSvgWrapper";
import { SCompFaqItemText } from "./styles/SCompFaqItemText";
import { SCompFaqItemTextContainer } from "./styles/SCompFaqItemTextContainer";

interface ICompFaqItem extends IGenFaqItem {
  isActive?: boolean;
  setActiveFaq?: React.Dispatch<React.SetStateAction<string>>;
}

export const CompFaqItem: React.FC<ICompFaqItem> = ({
  id,
  title,
  text,
  isActive,
  setActiveFaq,
}) => {
  const answerHeight = useRef<HTMLDivElement>();

  return (
    <SCompFaqItem id={id}>
      <SCompFaqItemItemHeaderWrapper
        onClick={() => {
          setActiveFaq(isActive ? "" : id);
        }}
      >
        {title && <SCompFaqItemFaqHeader>{title}</SCompFaqItemFaqHeader>}
        <SCompFaqItemSvgWrapper>
          {isActive ? <MinusClear /> : <PlusClear />}
        </SCompFaqItemSvgWrapper>
      </SCompFaqItemItemHeaderWrapper>
      <SCompFaqItemTextContainer
        style={{
          height: isActive ? answerHeight.current?.scrollHeight : 0,
        }}
        ref={answerHeight}
      >
        <SCompFaqItemText>
          {!!text?.json?.content.length && (
            <RichText content={text?.json.content} />
          )}
        </SCompFaqItemText>
      </SCompFaqItemTextContainer>
    </SCompFaqItem>
  );
};
