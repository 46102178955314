"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompChangelogReleasesRightContent {
  isLast: boolean;
}

const Bronze = css<ISCompChangelogReleasesRightContent>`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: ${({ isLast }) => (isLast ? 0 : "32px")};
  }
`;

const Silver = css<ISCompChangelogReleasesRightContent>``;

const Gold = css<ISCompChangelogReleasesRightContent>`
  margin-bottom: 72px;

  &:last-of-type {
    margin-bottom: ${({ isLast }) => (isLast ? 0 : "72px")};
  }
`;

const Platinum = css<ISCompChangelogReleasesRightContent>``;

const Diamond = css<ISCompChangelogReleasesRightContent>``;

export const SCompChangelogReleasesRightContent = styled.div<ISCompChangelogReleasesRightContent>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
