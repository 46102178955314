"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import {
  CSSTitle02,
  CSSTitle02Xlarge,
} from "../../../../constants/styles/typographies/CSSTypographies";

interface ISCompFeaturedCardItemCardFooterTitle {
  isSectionBgWhite: boolean | undefined | null;
}

const Bronze = css<ISCompFeaturedCardItemCardFooterTitle>`
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
  ${CSSTitle02};
`;

const Silver = css<ISCompFeaturedCardItemCardFooterTitle>``;

const Gold = css<ISCompFeaturedCardItemCardFooterTitle>``;

const Platinum = css<ISCompFeaturedCardItemCardFooterTitle>``;

const Diamond = css<ISCompFeaturedCardItemCardFooterTitle>`
  ${CSSTitle02Xlarge};
`;

export const SCompFeaturedCardItemCardFooterTitle = styled.h3<ISCompFeaturedCardItemCardFooterTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
