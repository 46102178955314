"use client";
import React, { useEffect, useState } from "react";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { RichText } from "../../base-components/rich-text/RichText";
import { IGenImagesSlider, Maybe } from "../../utils/types_gen";
import { CompSectionHeadline } from "../comp-section-headline/CompSectionHeadline";
import { SCompImageSlider } from "./styles/SCompImageSlider";
import { SCompImageSliderSwiperContainer } from "./styles/SCompImageSliderSwiperContainer";
import { SCompImageSliderImagesContainer } from "./styles/SCompImageSliderImagesContainer";
import { NextImage } from "src/components/next-image/NextImage";
import { SCompImageSliderImageWrapper } from "./styles/SCompImageSliderImageWrapper";
import { Button } from "../button/Button";
import { SCompImageSliderMobileSwiperWrapper } from "./styles/SCompImageSliderMobileSwiperWrapper";
import { SCompImageSliderTitle } from "./styles/SCompImageSliderTitle";
import dynamic from "next/dynamic";

const CompSwiper = dynamic(() =>
  import("../comp-swiper/CompSwiper").then((mod) => mod.CompSwiper)
);
export interface ICompImageSlider extends IGenImagesSlider {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

export const CompImageSliderInner: React.FC<ICompImageSlider> = ({
  id,
  slides,
  richTitle,
  isSectionBgWhite,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>();
  const mobileArray = slides?.reduce((acc, current) => {
    if (current) {
      if (current.imageOne) {
        acc.push({
          id: current.id,
          image: current.imageOne,
          link: current.linkOne,
        });
      }
      if (current.imageTwo) {
        acc.push({
          id: current.id,
          image: current.imageTwo,
          link: current.linkTwo,
        });
      }
    }
    return acc;
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return null;
    const media = window.matchMedia("(max-width:1279.98px)");
    const checkMedia = () => {
      setIsMobile(media.matches);
    };
    media.addEventListener("change", checkMedia);
    checkMedia();
    return () => media.removeEventListener("change", checkMedia);
  }, []);

  return (
    <SCompImageSlider id={id}>
      <GridPadding>
        {!!richTitle?.json?.content?.length && (
          <SCompImageSliderTitle>
            <CompSectionHeadline isSectionBgWhite={isSectionBgWhite}>
              <RichText content={richTitle?.json.content} />
            </CompSectionHeadline>
          </SCompImageSliderTitle>
        )}
        <SCompImageSliderSwiperContainer>
          {!isMobile && (
            <CompSwiper
              slidesPerView="auto"
              spaceBetween={24}
              watchOverflow
              slides={slides?.map(
                (slide) =>
                  slide?.imageOne?.src && (
                    <SCompImageSliderImagesContainer
                      key={slide.id}
                      hasTwo={!!slide.imageTwo?.src}
                    >
                      <SCompImageSliderImageWrapper
                        hasTwo={!!slide.imageTwo?.src}
                      >
                        <div>
                          <Button {...slide.linkOne}>
                            {`${slide.linkOne.title ?? ""}`}
                          </Button>
                        </div>
                        <NextImage
                          alt={
                            slide.imageOne.description || slide.imageOne.title
                          }
                          src={slide.imageOne.src}
                          fill
                          priority
                        />
                      </SCompImageSliderImageWrapper>
                      {slide.imageTwo?.src && (
                        <SCompImageSliderImageWrapper
                          hasTwo={!!slide.imageTwo.src}
                        >
                          <div>
                            <Button {...slide.linkTwo}>
                              {`${slide.linkTwo.title ?? ""}`}
                            </Button>
                          </div>
                          <NextImage
                            alt={
                              slide.imageTwo.description || slide.imageTwo.title
                            }
                            src={slide.imageTwo.src}
                            fill
                            priority
                          />
                        </SCompImageSliderImageWrapper>
                      )}
                    </SCompImageSliderImagesContainer>
                  )
              )}
            />
          )}
          {isMobile && (
            <CompSwiper
              slidesPerView={1.081}
              spaceBetween={24}
              slides={mobileArray?.map(
                (_, i) =>
                  i % 2 === 0 && (
                    <SCompImageSliderMobileSwiperWrapper
                      key={mobileArray[i].id}
                    >
                      <SCompImageSliderImageWrapper hasTwo>
                        <div>
                          <Button {...mobileArray[i].link}>
                            {`${mobileArray[i].link.title ?? ""}`}
                          </Button>
                        </div>
                        <NextImage
                          src={mobileArray[i].image.src}
                          fill
                          alt={
                            mobileArray[i].image.description ||
                            mobileArray[i].image.title
                          }
                          priority
                        />
                      </SCompImageSliderImageWrapper>
                      {i + 2 <= mobileArray.length && (
                        <SCompImageSliderImageWrapper
                          index={i % 2 === 0}
                          hasTwo
                        >
                          {mobileArray[i + 1]?.link ? (
                            <div>
                              <Button {...mobileArray[i + 1].link}>
                                {`${mobileArray[i + 1].link.title ?? ""}`}
                              </Button>
                            </div>
                          ) : null}
                          <NextImage
                            src={mobileArray[i + 1]?.image?.src}
                            fill
                            alt={
                              mobileArray[i + 1]?.image?.description ||
                              mobileArray[i + 1]?.image?.title
                            }
                            priority
                          />
                        </SCompImageSliderImageWrapper>
                      )}
                    </SCompImageSliderMobileSwiperWrapper>
                  )
              )}
            />
          )}
        </SCompImageSliderSwiperContainer>
      </GridPadding>
    </SCompImageSlider>
  );
};
