"use client";
import React, { useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import { IGenPricingHeaderWrapper } from "../../utils/types_gen";
import { SCompPricingHeaderWrapper } from "./styles/SCompPricingHeaderWrapper";
import { SCompPricingHeaderWrapperBottomCardsWrapper } from "./styles/SCompPricingHeaderWrapperBottomCardsWrapper";
import { SCompPricingHeaderWrapperButtonBatchesWrapper } from "./styles/SCompPricingHeaderWrapperButtonBatchesWrapper";
import { SCompPricingHeaderWrapperCard } from "./styles/SCompPricingHeaderWrapperCard";
import { SCompPricingHeaderWrapperCardOption } from "./styles/SCompPricingHeaderWrapperCardOption";
import { SCompPricingHeaderWrapperCardOptionSvg } from "./styles/SCompPricingHeaderWrapperCardOptionSvg";
import { SCompPricingHeaderWrapperCardOptionsWrapper } from "./styles/SCompPricingHeaderWrapperCardOptionsWrapper";
import { SCompPricingHeaderWrapperCardTitle } from "./styles/SCompPricingHeaderWrapperCardTitle";
import { SCompPricingHeaderWrapperCardTop } from "./styles/SCompPricingHeaderWrapperCardTop";
import { SCompPricingHeaderWrapperCardButton } from "./styles/SCompPricingHeaderWrapperCardButton";
import { SCompPricingHeaderWrapperCustomGrid } from "./styles/SCompPricingHeaderWrapperCustomGrid";
import { SCompPricingHeaderWrapperCardOptionText } from "./styles/SCompPricingHeaderWrapperCardOptionText";
import { SCompPricingHeaderWrapperDiscountBatch } from "./styles/SCompPricingHeaderWrapperDiscountBatch";
import { SCompPricingHeaderWrapperDurationWithDiscountBatch } from "./styles/SCompPricingHeaderWrapperDurationWithDiscountBatch";
import { SCompPricingHeaderWrapperHeadline } from "./styles/SCompPricingHeaderWrapperHeadline";
import { SCompPricingHeaderWrapperPricingMonth } from "./styles/SCompPricingHeaderWrapperPricingMonth";
import { SCompPricingHeaderWrapperPricingMonthText } from "./styles/SCompPricingHeaderWrapperPricingMonthText";
import { SCompPricingHeaderWrapperPricingYear } from "./styles/SCompPricingHeaderWrapperPricingYear";
import { SCompPricingHeaderWrapperPricingYearText } from "./styles/SCompPricingHeaderWrapperPricingYearText";
import { SCompPricingHeaderWrapperPromoButtonCustom } from "./styles/SCompPricingHeaderWrapperPromoButtonCustom";
import { SCompPricingHeaderWrapperSubscriptionDurationWrapper } from "./styles/SCompPricingHeaderWrapperSubscriptionDurationWrapper";
import { SCompPricingHeaderWrapperTitle } from "./styles/SCompPricingHeaderWrapperTitle";
import GradientCheckmarkIcon from "../../svgs/GradientCheckmarkIcon";
import { Button } from "../button/Button";

export const CompPricingHeaderWrapperInner: React.FC<
  IGenPricingHeaderWrapper
> = ({
  batchSwitch2,
  headline,
  id,
  pricingCards,
  switch1,
  switch2,
  batchSwitch1,
}) => {
  const [highlightPricingType, setHighlightPricingType] = useState(switch2);

  return (
    <SCompPricingHeaderWrapper id={id}>
      <SCompPricingHeaderWrapperHeadline>
        <GridPadding>
          <Grid>
            <GridItem
              bronze={{ start: 1, end: 12 }}
              gold={{ start: 2, end: 11 }}
            >
              {headline && (
                <SCompPricingHeaderWrapperTitle>
                  {headline}
                </SCompPricingHeaderWrapperTitle>
              )}
              {(switch1 || batchSwitch2) && (
                <SCompPricingHeaderWrapperButtonBatchesWrapper>
                  {switch1 && (
                    <SCompPricingHeaderWrapperPromoButtonCustom>
                      <Button
                        buttonStyle={
                          highlightPricingType === switch1
                            ? "promo"
                            : "tertiary"
                        }
                        promoText={batchSwitch1}
                        onClick={() => setHighlightPricingType(switch1)}
                        activated={highlightPricingType === switch1}
                      >
                        {switch1}
                      </Button>
                    </SCompPricingHeaderWrapperPromoButtonCustom>
                  )}
                  {switch2 && (
                    <SCompPricingHeaderWrapperPromoButtonCustom>
                      <Button
                        // shape="small"
                        buttonStyle={
                          highlightPricingType === switch2
                            ? "promo"
                            : "tertiary"
                        }
                        onClick={() => setHighlightPricingType(switch2)}
                        activated={highlightPricingType === switch2}
                      >
                        {switch2}
                      </Button>
                    </SCompPricingHeaderWrapperPromoButtonCustom>
                  )}
                </SCompPricingHeaderWrapperButtonBatchesWrapper>
              )}
            </GridItem>
          </Grid>
        </GridPadding>
      </SCompPricingHeaderWrapperHeadline>
      <SCompPricingHeaderWrapperBottomCardsWrapper>
        <GridPadding>
          {!!pricingCards?.length && (
            <SCompPricingHeaderWrapperCustomGrid>
              {pricingCards.map(
                (card) =>
                  card && (
                    <SCompPricingHeaderWrapperCard
                      bgGradient={card.gradientBackground}
                      colorIdentification={card.gradientAccent}
                      key={card.id}
                    >
                      <SCompPricingHeaderWrapperCardTop>
                        {card.headline && (
                          <SCompPricingHeaderWrapperCardTitle>
                            {card.headline}
                          </SCompPricingHeaderWrapperCardTitle>
                        )}
                        {(card.pricingMonth || card.pricingYear) && (
                          <SCompPricingHeaderWrapperSubscriptionDurationWrapper
                            bgGradient={card.gradientBackground}
                          >
                            {card.pricingYear &&
                              highlightPricingType === switch1 && (
                                <SCompPricingHeaderWrapperDurationWithDiscountBatch>
                                  <SCompPricingHeaderWrapperPricingYear
                                    highlightPricingType={
                                      highlightPricingType === switch1
                                    }
                                    bgGradient={card.gradientBackground}
                                  >
                                    <span>{card.pricingYear}</span>
                                    <SCompPricingHeaderWrapperPricingYearText
                                      highlightPricingType={
                                        highlightPricingType === switch1
                                      }
                                      bgGradient={card.gradientBackground}
                                    >
                                      {card.pricingYear}
                                    </SCompPricingHeaderWrapperPricingYearText>
                                  </SCompPricingHeaderWrapperPricingYear>
                                  {card.discountBatch && (
                                    <SCompPricingHeaderWrapperDiscountBatch
                                      bgGradient={card.gradientAccent}
                                    >
                                      {card.discountBatch}
                                    </SCompPricingHeaderWrapperDiscountBatch>
                                  )}
                                </SCompPricingHeaderWrapperDurationWithDiscountBatch>
                              )}
                            {card.pricingMonth &&
                              highlightPricingType === switch2 && (
                                <SCompPricingHeaderWrapperPricingMonth
                                  bgGradient={card.gradientBackground}
                                  highlightPricingType={
                                    highlightPricingType === switch2
                                  }
                                >
                                  <span>{card.pricingMonth}</span>
                                  <SCompPricingHeaderWrapperPricingMonthText
                                    bgGradient={card.gradientBackground}
                                    highlightPricingType={
                                      highlightPricingType === switch2
                                    }
                                  >
                                    {card.pricingMonth}
                                  </SCompPricingHeaderWrapperPricingMonthText>
                                </SCompPricingHeaderWrapperPricingMonth>
                              )}
                          </SCompPricingHeaderWrapperSubscriptionDurationWrapper>
                        )}
                        {!!card.options?.length && (
                          <SCompPricingHeaderWrapperCardOptionsWrapper>
                            {card.options.map(
                              (option) =>
                                option && (
                                  <SCompPricingHeaderWrapperCardOption
                                    key={option.id}
                                  >
                                    <SCompPricingHeaderWrapperCardOptionSvg
                                      gradientAccent={card.gradientAccent}
                                      bgGradient={card.gradientBackground}
                                    >
                                      <GradientCheckmarkIcon xkey={option.id} />
                                    </SCompPricingHeaderWrapperCardOptionSvg>
                                    <SCompPricingHeaderWrapperCardOptionText
                                      bgGradient={card.gradientBackground}
                                    >
                                      {option.option}
                                    </SCompPricingHeaderWrapperCardOptionText>
                                  </SCompPricingHeaderWrapperCardOption>
                                )
                            )}
                          </SCompPricingHeaderWrapperCardOptionsWrapper>
                        )}
                      </SCompPricingHeaderWrapperCardTop>
                      {card.button?.map(
                        (btn) =>
                          btn && (
                            <SCompPricingHeaderWrapperCardButton
                              {...btn}
                              key={btn.id}
                              gradientAccent={card.gradientAccent}
                            >
                              {btn.title}
                            </SCompPricingHeaderWrapperCardButton>
                          )
                      )}
                    </SCompPricingHeaderWrapperCard>
                  )
              )}
            </SCompPricingHeaderWrapperCustomGrid>
          )}
        </GridPadding>
      </SCompPricingHeaderWrapperBottomCardsWrapper>
    </SCompPricingHeaderWrapper>
  );
};
