"use client";
import styled, { css } from "styled-components";
import {
  MIN_DIAMOND,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_SILVER,
} from "../../../../constants/styles/mediaquerys";

interface ICompFaqItemFaqHeader {
  showDescription?: boolean;
}

const Bronze = css<ICompFaqItemFaqHeader>`
  max-width: 69.4189602%;
`;

const Silver = css<ICompFaqItemFaqHeader>``;

const Gold = css<ICompFaqItemFaqHeader>`
  max-width: 100%;
`;

const Platinum = css<ICompFaqItemFaqHeader>``;

const Diamond = css<ICompFaqItemFaqHeader>``;

export const SCompFaqItemFaqHeader = styled.h4<ICompFaqItemFaqHeader>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
