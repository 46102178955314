import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import styled, { css } from "styled-components";

const Bronze = css<{ styleType?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Silver = css``;

const Gold = css`
  flex-direction: column-reverse;
`;

const Platinum = css``;

const Diamond = css``;

export const SImageAnimationWrapper = styled.div<any>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
