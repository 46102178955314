import React, { PropsWithChildren } from "react";
import { SBrowserWindowImageFrame } from "./styles/SBrowserWindowImageFrame";

export const BrowserWindowImageFrame: React.FC<
  PropsWithChildren & { withFrame?: boolean }
> = ({ children, withFrame }) => {
  return (
    <SBrowserWindowImageFrame withFrame={withFrame}>
      <div className="browser">
        {[...Array(3)].map((_, index) => (
          <span key={index} />
        ))}
      </div>
      <div className="body">{children}</div>
    </SBrowserWindowImageFrame>
  );
};
