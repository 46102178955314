import React from "react";

function PlusClear() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.908 8.9a.9.9 0 00-1.8 0v6.207H8.9a.9.9 0 000 1.8h6.208v6.207a.9.9 0 101.8 0v-6.207h6.206a.9.9 0 100-1.8h-6.206V8.9z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PlusClear;
