import { FC } from "react";
import { SOnboardingTemplatesTabItemIcon } from "./styles/SOnboardingTemplatesTabItemIcon";
import { SOnboardingTemplatesTabs } from "./styles/SOnboardingTemplatesTabs";
import { SOnboardingTemplatesTabsItem } from "./styles/SOnboardingTemplatesTabsItem";
import { NextImage } from "../../next-image/NextImage";

interface IOnboardingTab {
  label: string;
  icon: { src: string };
  id: string;
}

interface IOnboardingTemplateTabs {
  selectedTab: string;
  onSelectTab: (id: string) => void;
  tabs: IOnboardingTab[];
}

export const OnboardingTemplatesTabs: FC<IOnboardingTemplateTabs> = ({
  onSelectTab,
  selectedTab,
  tabs,
}) => {
  return (
    <SOnboardingTemplatesTabs>
      {tabs.map((tab) => (
        <SOnboardingTemplatesTabsItem
          onClick={() => onSelectTab(tab.id)}
          key={tab.id}
          active={tab.id === selectedTab}
        >
          <SOnboardingTemplatesTabItemIcon active={tab.id === selectedTab}>
            <NextImage
              alt={tab.label}
              src={tab.icon.src}
              width={24}
              height={24}
              style={{ objectFit: "contain" }}
            />
          </SOnboardingTemplatesTabItemIcon>
          {tab.label}
        </SOnboardingTemplatesTabsItem>
      ))}
    </SOnboardingTemplatesTabs>
  );
};
