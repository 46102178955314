"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  position: relative;
  padding-top: 88.5%;
  margin: 30px -24px 0;
`;

const Silver = css``;

const Gold = css`
  margin: 0 0 0 auto;
  padding-top: 87.26%;
`;

const Platinum = css``;

const Diamond = css`
  margin-left: 0;
  padding-top: 81.84%;
`;

export const SCompSubpageHeroImageWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
