"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompPricingHeaderWrapperHeadline {
  bgGradient?: string;
}

const Bronze = css<ISCompPricingHeaderWrapperHeadline>`
  background: ${({ bgGradient }) =>
    bgGradient ? backgroundGradients[bgGradient] : "var(--blue-gradient)"};
  padding: 100px 0 80px;
`;

const Silver = css<ISCompPricingHeaderWrapperHeadline>``;

const Gold = css<ISCompPricingHeaderWrapperHeadline>`
  padding-top: 152px;
  padding-bottom: 120px;
`;

const Platinum = css<ISCompPricingHeaderWrapperHeadline>``;

const Diamond = css<ISCompPricingHeaderWrapperHeadline>`
  padding: 200px 0 120px;
`;

export const SCompPricingHeaderWrapperHeadline = styled.div<ISCompPricingHeaderWrapperHeadline>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
