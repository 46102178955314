"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompPricingHeaderWrapperSubscriptionDurationWrapper {
  bgGradient?: string;
}

const Bronze = css<ISCompPricingHeaderWrapperSubscriptionDurationWrapper>`
  border: 1px solid
    ${({ bgGradient }) =>
      bgGradient !== "white" ? "rgba(255, 255, 255, 0.08)" : "var(--ui-03)"};
  border-radius: 12px;
  width: fit-content;
  margin-top: 16px;
  padding: 7px 16px;
`;

const Silver = css<ISCompPricingHeaderWrapperSubscriptionDurationWrapper>``;

const Gold = css<ISCompPricingHeaderWrapperSubscriptionDurationWrapper>`
  padding: 7px 16px;
`;

const Platinum = css<ISCompPricingHeaderWrapperSubscriptionDurationWrapper>``;

const Diamond = css<ISCompPricingHeaderWrapperSubscriptionDurationWrapper>`
  padding: 8px 16px;
`;

export const SCompPricingHeaderWrapperSubscriptionDurationWrapper = styled.div<ISCompPricingHeaderWrapperSubscriptionDurationWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
