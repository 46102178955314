"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ICompImageSliderImagesContainer {
  hasTwo?: boolean;
}

const Bronze = css<ICompImageSliderImagesContainer>`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  width: 80vw;
`;

const Silver = css<ICompImageSliderImagesContainer>``;

const Gold = css<ICompImageSliderImagesContainer>`
  width: ${({ hasTwo }) => (hasTwo ? "29.37500vw" : "60.62500vw")};
`;

const Platinum = css<ICompImageSliderImagesContainer>``;

const Diamond = css<ICompImageSliderImagesContainer>`
  width: ${({ hasTwo }) =>
    hasTwo ? "min(19.5833333vw, 376px)" : "min(40.4166667vw, 776px)"};
`;

export const SCompImageSliderImagesContainer = styled.div<ICompImageSliderImagesContainer>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
