"use client";
import React from "react";
import dynamic from "next/dynamic";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { RichText } from "../../base-components/rich-text/RichText";
import { IGenFeatureCardsWrapper, Maybe } from "../../utils/types_gen";
import { Button } from "../button/Button";
import { CompFeaturedCardItem } from "./comp-featured-card-item/CompFeaturedCardItem";
import { SCompFeatureCardsWrapper } from "./styles/SCompFeatureCardsWrapper";
import { SCompFeatureCardsWrapperBlockHeader } from "./styles/SCompFeatureCardsWrapperBlockHeader";
import { SCompFeatureCardsWrapperBlockTitle } from "./styles/SCompFeatureCardsWrapperBlockTitle";
import { SCompFeatureCardsWrapperCardsHolder } from "./styles/SCompFeatureCardsWrapperCardsHolder";
import { SCompFeatureCardsButton } from "./styles/SCompFeatureCardsButton";
import { SCompFeatureCardsWrapperTitleAndBtn } from "./styles/SCompFeatureCardsWrapperTitleAndBtn";
import { SCompFeatureCardsWrapperCards } from "./styles/SCompFeatureCardsWrapperCards";

const CompSwiper = dynamic(() =>
  import("../comp-swiper/CompSwiper").then((mod) => mod.CompSwiper)
);

export interface ICompFeatureCardsWrapper extends IGenFeatureCardsWrapper {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

export const CompFeatureCardsWrapperInner: React.FC<
  ICompFeatureCardsWrapper
> = ({ richTitle, isSwiper, button, isSectionBgWhite, id, cards }) => {
  return (
    <SCompFeatureCardsWrapper id={id ?? ""} isSectionBgWhite={isSectionBgWhite}>
      <GridPadding>
        <SCompFeatureCardsWrapperBlockHeader>
          <SCompFeatureCardsWrapperTitleAndBtn>
            {!!richTitle?.json?.content?.length && (
              <SCompFeatureCardsWrapperBlockTitle
                withButton={!!button?.length}
                isSectionBgWhite={isSectionBgWhite}
              >
                <RichText content={richTitle?.json?.content} />
              </SCompFeatureCardsWrapperBlockTitle>
            )}
            {!!button?.length && (
              <SCompFeatureCardsButton>
                {button.map(
                  (btn) =>
                    btn && (
                      <Button {...btn} key={btn.id}>
                        {btn.title}
                      </Button>
                    )
                )}
              </SCompFeatureCardsButton>
            )}
          </SCompFeatureCardsWrapperTitleAndBtn>
        </SCompFeatureCardsWrapperBlockHeader>
        {!!cards?.length && (
          <SCompFeatureCardsWrapperCards>
            {isSwiper ? (
              <CompSwiper
                slides={cards?.map(
                  (card) =>
                    card && (
                      <CompFeaturedCardItem
                        key={card.id}
                        {...card}
                        isSectionBgWhite={isSectionBgWhite}
                        isSwiper={isSwiper}
                      />
                    )
                )}
                slidesPerView={1.1}
                spaceBetween={24}
                breakpoints={{
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                  },

                  1928: {
                    slidesPerView: 3.11,
                    spaceBetween: 24,
                  },
                }}
              />
            ) : (
              <SCompFeatureCardsWrapperCardsHolder>
                {cards.map(
                  (card) =>
                    card && (
                      <CompFeaturedCardItem
                        key={card.id}
                        {...card}
                        isSectionBgWhite={isSectionBgWhite}
                        isSwiper={isSwiper}
                      />
                    )
                )}
              </SCompFeatureCardsWrapperCardsHolder>
            )}
          </SCompFeatureCardsWrapperCards>
        )}
      </GridPadding>
    </SCompFeatureCardsWrapper>
  );
};
