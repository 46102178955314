"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading01,
  CSSHeading01Small,
  CSSHeading01XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISCompFaqHeader {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ISCompFaqHeader>`
  ${CSSHeading01Small};
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
  margin-bottom: 40px;
`;

const Silver = css<ISCompFaqHeader>``;

const Gold = css<ISCompFaqHeader>`
  ${CSSHeading01};
  margin-bottom: 80px;
`;

const Platinum = css<ISCompFaqHeader>``;

const Diamond = css<ISCompFaqHeader>`
  ${CSSHeading01XLarge};
`;

export const SCompFaqHeader = styled.h2<ISCompFaqHeader>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
