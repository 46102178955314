export const MiddleRightLine = () => (
  <svg
    id="middle-right"
    width="195"
    height="1"
    viewBox="0 0 195 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="middle-right-path"
      d="M195 0.500977L5.17149e-06 0.500977"
      stroke="url(#paint0_linear_7928_195528)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7928_195528"
        x1="142.011"
        y1="0.992356"
        x2="-38.1527"
        y2="0.992356"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.288449" stopColor="#33425F" />
        <stop offset="1" stopColor="#008CFF" />
      </linearGradient>
    </defs>
  </svg>
);
