"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { CSSBody03Semi } from "../../../../constants/styles/typographies/CSSTypographies";

interface ISOnboardingTemplatesTabsItem {
  active: boolean;
}

const CSSActive = css`
  background-color: var(--active-ui-01-1);
`;

const Bronze = css<ISOnboardingTemplatesTabsItem>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  ${CSSBody03Semi};
  color: var(--text-01);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 200ms;
  min-width: max-content;
  ${({ active }) => active && CSSActive}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOnboardingTemplatesTabsItem = styled.div<ISOnboardingTemplatesTabsItem>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
