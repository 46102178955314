export const MiddleLeftLine = () => (
  <svg
    id="middle-left"
    width="195"
    height="1"
    viewBox="0 0 195 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="middle-left-path"
      d="M4.068e-05 0.500977L195 0.500977"
      stroke="url(#paint0_linear_7928_195509)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7928_195509"
        x1="52.9893"
        y1="0.992356"
        x2="233.153"
        y2="0.992356"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.288449" stopColor="#33425F" />
        <stop offset="1" stopColor="#008CFF" />
      </linearGradient>
    </defs>
  </svg>
);
