"use client";
import React from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import { RichText } from "../../base-components/rich-text/RichText";
import { Button } from "../button/Button";
import { SCompTextWithGraphicButton } from "./styles/SCompTextWithGraphicButton";
import { SCompTextWithGraphicButtonIcon } from "./styles/SCompTextWithGraphicButtonIcon";
import { SCompTextWithGraphicTitleAndImage } from "./styles/SCompTextWithGraphicTitleAndImage";
import { SCompTextWithGraphic } from "./styles/SCompTextWithGraphic";
import { SCompTextWithGraphicContentWrapper } from "./styles/SCompTextWithGraphicContentWrapper";
import { SCompTextWithGraphicDescription } from "./styles/SCompTextWithGraphicDescription";
import { SCompTextWithGraphicHeader } from "./styles/SCompTextWithGraphicHeader";
import { NextImage } from "src/components/next-image/NextImage";
import { SCompTextWithGraphicImageWrapper } from "./styles/SCompTextWithGraphicImageWrapper";
import { IGenTextWithGraphic } from "../../utils/types_gen";
import useResponseImage from "../../hooks/useResponseImage";

export const CompTextWithGraphicInner: React.FC<IGenTextWithGraphic> = ({
  id,
  smallScreenImage,
  mediumScreenImage,
  largeScreenImage,
  sourceLink,
  richText,
  richTitle,
}) => {
  const imageForDisplay = useResponseImage({
    smallScreenImage,
    mediumScreenImage,
    largeScreenImage,
  });

  return (
    <SCompTextWithGraphic id={id}>
      <GridPadding>
        <Grid>
          <GridItem
            bronze={{
              col: { start: 1, end: 12 },
              row: { start: 2, end: 2 },
            }}
            gold={{
              col: { start: 2, end: 7 },
              row: { start: 1, end: 1 },
            }}
            diamond={{ start: 2, end: 6 }}
          >
            <SCompTextWithGraphicContentWrapper>
              {!!richTitle?.json?.content?.length && (
                <SCompTextWithGraphicHeader>
                  <RichText content={richTitle?.json.content} />
                </SCompTextWithGraphicHeader>
              )}
              {!!richText?.json?.content?.length && (
                <SCompTextWithGraphicDescription>
                  <RichText content={richText?.json.content} />
                </SCompTextWithGraphicDescription>
              )}
              {sourceLink && (
                <SCompTextWithGraphicButton>
                  <Button {...sourceLink} disableStyle>
                    {sourceLink.icon?.src && (
                      <SCompTextWithGraphicButtonIcon>
                        <NextImage
                          alt={
                            sourceLink.icon.description || sourceLink.icon.title
                          }
                          src={sourceLink.icon.src}
                          fill
                        />
                      </SCompTextWithGraphicButtonIcon>
                    )}
                    {sourceLink.title}
                  </Button>
                </SCompTextWithGraphicButton>
              )}
            </SCompTextWithGraphicContentWrapper>
          </GridItem>
          <GridItem
            bronze={{
              col: { start: 1, end: 12 },
              row: { start: 1, end: 1 },
            }}
            gold={{
              col: { start: 8, end: 12 },
              row: { start: 1, end: 1 },
            }}
            diamond={{ start: 7, end: 12 }}
          >
            <SCompTextWithGraphicTitleAndImage>
              {!!richTitle?.json?.content?.length && (
                <SCompTextWithGraphicHeader>
                  <RichText content={richTitle?.json.content} />
                </SCompTextWithGraphicHeader>
              )}
              {imageForDisplay?.src && (
                <SCompTextWithGraphicImageWrapper>
                  <NextImage
                    alt={imageForDisplay.description || imageForDisplay.title}
                    src={imageForDisplay.src}
                    fill
                    loading="lazy"
                  />
                </SCompTextWithGraphicImageWrapper>
              )}
            </SCompTextWithGraphicTitleAndImage>
          </GridItem>
        </Grid>
      </GridPadding>
    </SCompTextWithGraphic>
  );
};
