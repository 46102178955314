"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSTitle02,
  CSSTitle02Xlarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompCardWithBackgroundColorTitle {
  isText: boolean;
}

const Bronze = css<ISCompCardWithBackgroundColorTitle>`
  ${CSSTitle02};
  color: var(--text-02);
  margin-bottom: ${({ isText }) => (isText ? "8px" : "0")};
`;

const Silver = css<ISCompCardWithBackgroundColorTitle>``;

const Gold = css<ISCompCardWithBackgroundColorTitle>`
  margin-bottom: ${({ isText }) => (isText ? "12px" : "0")};
  ${CSSTitle02Xlarge};
`;

const Platinum = css<ISCompCardWithBackgroundColorTitle>``;

const Diamond = css<ISCompCardWithBackgroundColorTitle>``;

export const SCompCardWithBackgroundColorTitle = styled.p<ISCompCardWithBackgroundColorTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
