"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 24px;
`;

const Silver = css`
  grid-template-columns: 2fr repeat(5, 1fr);
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompTableHeaderRow = styled.tr`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
