import React from "react";

function MinusClear() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 16.006a.9.9 0 01.9-.9h14.214a.9.9 0 110 1.8H8.9a.9.9 0 01-.9-.9z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default MinusClear;
