"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompImageTextDescriptionWrapper {
  isCompHasIcon: boolean;
}

const Bronze = css<ISCompImageTextDescriptionWrapper>`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ isCompHasIcon }) => (isCompHasIcon ? "24px" : "40px")};
`;

const Silver = css<ISCompImageTextDescriptionWrapper>``;

const Gold = css<ISCompImageTextDescriptionWrapper>`
  margin-top: ${({ isCompHasIcon }) => (isCompHasIcon ? "8px" : "0")};
`;

const Platinum = css<ISCompImageTextDescriptionWrapper>``;

const Diamond = css<ISCompImageTextDescriptionWrapper>``;

export const SCompImageTextDescriptionWrapper = styled.div<ISCompImageTextDescriptionWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
