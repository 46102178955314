"use client";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { Grid } from "../../base-components/grid/Grid";
import { QuotationMark } from "../../svgs/QuotationMark";
import { IGenQuoteCardCompanyGrid, Maybe } from "../../utils/types_gen";
import { SCompQuoteCardCompanyGrid } from "./styles/SCompQuoteCardCompanyGrid";
import { SCompQuoteCardCompanyGridCardButton } from "./styles/SCompQuoteCardCompanyGridCardButton";
import { SCompQuoteCardCompanyGridCardLogo } from "./styles/SCompQuoteCardCompanyGridCardLogo";
import { SCompQuoteCardCompanyGridCardNameText } from "./styles/SCompQuoteCardCompanyGridCardNameText";
import { SCompQuoteCardCompanyGridCardNameWrapper } from "./styles/SCompQuoteCardCompanyGridCardNameWrapper";
import { SCompQuoteCardCompanyGridHeaderWrapper } from "./styles/SCompQuoteCardCompanyGridHeaderWrapper";
import { SCompQuoteCardCompanyGridCardItem } from "./styles/SCompQuoteCardCompanyGridCardItem";
import { SCompQuoteCardCompanyGridCardLeftWrapper } from "./styles/SCompQuoteCardCompanyGridCardLeftWrapper";
import { SCompQuoteCardCompanyGridLogoWrapper } from "./styles/SCompQuoteCardCompanyGridLogoWrapper";
import { NextImage } from "src/components/next-image/NextImage";
import { SCompQuoteCardCompanyGridCardTitle } from "./styles/SCompQuoteCardCompanyGridCardTitle";
import { SCompQuoteCardCompanyGridCardText } from "./styles/SCompQuoteCardCompanyGridCardText";
import { Button } from "../button/Button";
import { SCompQuoteCardGridRightSideWrapper } from "./styles/SCompQuoteCardGridRightSideWrapper";
import { SCompQuoteCardCompanyGridCardQuote } from "./styles/SCompQuoteCardCompanyGridCardQuote";
import { SCompQuoteCardCompanyGridSvgWrapper } from "./styles/SCompQuoteCardCompanyGridSvgWrapper";
import { SCompQuoteCardCompanyGridQuote } from "./styles/SCompQuoteCardCompanyGridQuote";
import { SCompQuoteCardCompanyGridCardFooter } from "./styles/SCompQuoteCardCompanyGridCardFooter";
import { SCompQuoteCardCompanyGridCardFooterAvatar } from "./styles/SCompQuoteCardCompanyGridCardFooterAvatar";
import { SCompQuoteCardCompanyGridCardName } from "./styles/SCompQuoteCardCompanyGridCardName";
import { SCompQuoteCardCompanyGridQuoteContentP } from "./styles/SCompQuoteCardCompanyGridQuoteContentP";
import { SCompQuoteCardCompanyGridMobileGrid } from "./styles/SCompQuoteCardCompanyGridMobileGrid";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import dynamic from "next/dynamic";

const CompSwiper = dynamic(() =>
  import("../comp-swiper/CompSwiper").then((mod) => mod.CompSwiper)
);

export interface ICompQuoteCardCompanyGrid extends IGenQuoteCardCompanyGrid {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

export const CompQuoteCardCompanyGrid: React.FC<ICompQuoteCardCompanyGrid> = ({
  title,
  quoteCardCompany,
  id,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return null;
    const media = window.matchMedia("(max-width:1279.98px)");
    const checkMedia = () => {
      setIsMobile(media.matches);
    };

    media.addEventListener("change", checkMedia);
    checkMedia();
    return () => media.removeEventListener("change", checkMedia);
  }, []);

  const Card = useCallback((card, isMobile) => {
    return (
      <SCompQuoteCardCompanyGridCardItem>
        <SCompQuoteCardCompanyGridCardLeftWrapper>
          {card.logo?.src && (
            <SCompQuoteCardCompanyGridCardLogo>
              <SCompQuoteCardCompanyGridLogoWrapper>
                <NextImage
                  src={card.logo.src}
                  fill
                  style={{ objectFit: "contain" }}
                  alt={card.logo.description || card.logo.title}
                />
              </SCompQuoteCardCompanyGridLogoWrapper>
            </SCompQuoteCardCompanyGridCardLogo>
          )}
          {card.clientTitle && (
            <SCompQuoteCardCompanyGridCardTitle>
              {card.clientTitle}
            </SCompQuoteCardCompanyGridCardTitle>
          )}
          {card.clientText && (
            <SCompQuoteCardCompanyGridCardText>
              {card.clientText}
            </SCompQuoteCardCompanyGridCardText>
          )}
          {card.button?.map(
            (btn) =>
              btn && (
                <SCompQuoteCardCompanyGridCardButton
                  key={`${btn.id}-${card.id}`}
                >
                  <Button {...btn}>{btn.title}</Button>
                </SCompQuoteCardCompanyGridCardButton>
              )
          )}
        </SCompQuoteCardCompanyGridCardLeftWrapper>
        <SCompQuoteCardGridRightSideWrapper>
          {card.quote && (
            <SCompQuoteCardCompanyGridCardQuote>
              <SCompQuoteCardCompanyGridSvgWrapper>
                <QuotationMark />
              </SCompQuoteCardCompanyGridSvgWrapper>
              <SCompQuoteCardCompanyGridQuote>
                {isMobile ? (
                  <SCompQuoteCardCompanyGridQuoteContentP>
                    {` “ ${card.quote} ”`}
                  </SCompQuoteCardCompanyGridQuoteContentP>
                ) : (
                  <SCompQuoteCardCompanyGridQuoteContentP>
                    {card.quote}
                  </SCompQuoteCardCompanyGridQuoteContentP>
                )}
                <SCompQuoteCardCompanyGridCardFooter>
                  {card.avatar?.src && (
                    <SCompQuoteCardCompanyGridCardFooterAvatar>
                      <NextImage
                        src={card.avatar.src}
                        fill
                        style={{ objectFit: "cover" }}
                        alt={card.avatar.description || card.avatar.title}
                      />
                    </SCompQuoteCardCompanyGridCardFooterAvatar>
                  )}
                  {card.name && (
                    <SCompQuoteCardCompanyGridCardName>
                      <SCompQuoteCardCompanyGridCardNameText>
                        {card.name}
                      </SCompQuoteCardCompanyGridCardNameText>
                      <SCompQuoteCardCompanyGridCardNameWrapper>
                        {card.name.includes(",") ? (
                          <>
                            {(() => {
                              const splitName = card.name.split(",");
                              return (
                                <>
                                  {splitName[0] && <p>{splitName[0]}</p>}
                                  {splitName[1] && <p>{splitName[1]}</p>}
                                </>
                              );
                            })()}
                          </>
                        ) : (
                          <p>{card.name}</p>
                        )}
                      </SCompQuoteCardCompanyGridCardNameWrapper>
                    </SCompQuoteCardCompanyGridCardName>
                  )}
                </SCompQuoteCardCompanyGridCardFooter>
              </SCompQuoteCardCompanyGridQuote>
            </SCompQuoteCardCompanyGridCardQuote>
          )}
        </SCompQuoteCardGridRightSideWrapper>
      </SCompQuoteCardCompanyGridCardItem>
    );
  }, []);

  return (
    <SCompQuoteCardCompanyGrid id={id}>
      <GridPadding>
        <Grid>
          <GridItem bronze={{ start: 1, end: 12 }} gold={{ start: 2, end: 11 }}>
            {title && (
              <SCompQuoteCardCompanyGridHeaderWrapper>
                {title}
              </SCompQuoteCardCompanyGridHeaderWrapper>
            )}
          </GridItem>
        </Grid>
        {quoteCardCompany?.length > 0 && !isMobile ? (
          <CompSwiper
            slidesPerView={1.139}
            spaceBetween={quoteCardCompany.length !== 1 ? 24 : 0}
            watchOverflow
            centeredSlides={quoteCardCompany.length === 1}
            breakpoints={{
              1920: {
                slidesPerView: 1.258,
              },
            }}
            slides={quoteCardCompany.map((card) => card && Card(card, false))}
          />
        ) : (
          <SCompQuoteCardCompanyGridMobileGrid>
            {quoteCardCompany.map(
              (card) =>
                card && <Fragment key={card.id}>{Card(card, true)}</Fragment>
            )}
          </SCompQuoteCardCompanyGridMobileGrid>
        )}
      </GridPadding>
    </SCompQuoteCardCompanyGrid>
  );
};
