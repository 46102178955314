"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompCardWrapperCards {
  amountOfCols: number;
}

const Bronze = css<ISCompCardWrapperCards>`
  display: grid;
  grid-template-columns: 100%;
  gap: 24px;
`;

const Silver = css<ISCompCardWrapperCards>``;

const Gold = css<ISCompCardWrapperCards>`
  grid-template-columns: repeat(
    ${({ amountOfCols }) => `min(${amountOfCols}, 3)`},
    1fr
  );
`;

const Platinum = css<ISCompCardWrapperCards>``;

const Diamond = css<ISCompCardWrapperCards>``;

export const SCompCardWrapperCards = styled.div<ISCompCardWrapperCards>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
