"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSSubtitle01 } from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-04);
  ${CSSSubtitle01};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Silver = css``;

const Gold = css`
  ${CSSSubtitle01}
`;

const Platinum = css``;

const Diamond = css`
  ${CSSSubtitle01}
`;

export const SCompBlogCategoryTeaserSecondaryArticleDescription = styled.p`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
