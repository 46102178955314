export const QuotationMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
      d="M18.664 18h6.667c.736 0 1.333.597 1.333 1.333v5.334c0 .736-.597 1.333-1.333 1.333h-5.334a1.334 1.334 0 01-1.333-1.333V18c0-8 1.333-9.333 5.333-12M5.332 18h6.667c.736 0 1.333.597 1.333 1.333v5.334c0 .736-.597 1.333-1.333 1.333H6.665a1.334 1.334 0 01-1.333-1.333V18c0-8 1.333-9.333 5.333-12"
    />
  </svg>
);
