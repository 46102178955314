"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompChangelogReleasesImageCont {
  moreThanOne?: boolean;
}

const Bronze = css<ISCompChangelogReleasesImageCont>`
  width: ${({ moreThanOne }) => (moreThanOne ? "50%" : "100%")};
`;

const Silver = css<ISCompChangelogReleasesImageCont>``;

const Gold = css``;

const Platinum = css<ISCompChangelogReleasesImageCont>``;

const Diamond = css<ISCompChangelogReleasesImageCont>``;

export const SCompChangelogReleasesImageCont = styled.div<ISCompChangelogReleasesImageCont>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
