"use client";
import styled from "styled-components";

export const SOnboardingTemplatesTemplateStack = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 8px;
  border-radius: 6px;
  background: var(--disabled-interactional-03);
  border: 1.33333px solid var(--disabled-interactional-04);
`;
