"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody02,
  CssBody02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { SButton } from "../../button/styles/SButton";

const Bronze = css`
  margin-top: 32px;

  ${SButton} {
    display: flex;
    align-items: center;
    gap: 12px;
    ${CSSBody02};
    color: var(--text-04);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${SButton} {
    ${CssBody02XLarge};
  }
`;

export const SCompTextWithGraphicButton = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
