"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompPricingHeaderWrapperCard {
  bgGradient?: string;
  colorIdentification?: string;
}

const Bronze = css<ISCompPricingHeaderWrapperCard>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  background: ${({ bgGradient }) =>
    bgGradient !== "white" ? backgroundGradients[bgGradient] : "var(--ui-01)"};

  color: ${({ bgGradient, colorIdentification }) => {
    if (colorIdentification === "grey") {
      return "var(--text-04)";
    }
    if (bgGradient !== "white") {
      return "var(--text-02)";
    } else {
      return "var(--text-01)";
    }
  }};
`;

const Silver = css<ISCompPricingHeaderWrapperCard>``;

const Gold = css<ISCompPricingHeaderWrapperCard>`
  padding: 40px 40px;
`;

const Platinum = css<ISCompPricingHeaderWrapperCard>``;

const Diamond = css<ISCompPricingHeaderWrapperCard>`
  padding: 40px 40px;
`;

export const SCompPricingHeaderWrapperCard = styled.div<ISCompPricingHeaderWrapperCard>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
