"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ICompQuoteCardCompanyGridCardItem {
  index?: boolean;
}

const Bronze = css<ICompQuoteCardCompanyGridCardItem>`
  display: flex;
  background: linear-gradient(102.45deg, #434343 16.49%, #2f2f2f 89.22%);
  flex-direction: column;
  box-shadow: var(--big-elevation);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 40px;
`;

const Silver = css<ICompQuoteCardCompanyGridCardItem>``;

const Gold = css<ICompQuoteCardCompanyGridCardItem>`
  justify-content: flex-start;
  align-items: start;
  padding: 52px;
  flex-direction: row;
  height: 100%;
`;

const Platinum = css<ICompQuoteCardCompanyGridCardItem>``;

const Diamond = css<ICompQuoteCardCompanyGridCardItem>`
  padding: 80px;
  width: 100%;
`;

export const SCompQuoteCardCompanyGridCardItem = styled.div<ICompQuoteCardCompanyGridCardItem>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
