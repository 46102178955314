"use client";
import { NextImage } from "src/components/next-image/NextImage";
import React from "react";
import { IGenLogoBand } from "../../utils/types_gen";
import { SCompLogoBand } from "./styles/SCompLogoBrand";
import { Autoplay } from "swiper";
import { SCompLogoBandSingleSlideWrapper } from "./styles/SCompLogoBrandSingleSlideWrapper";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Maybe } from "graphql/jsutils/Maybe";
import dynamic from "next/dynamic";

const CompSwiper = dynamic(() =>
  import("../comp-swiper/CompSwiper").then((mod) => mod.CompSwiper)
);

export interface ICompLogoBrand extends IGenLogoBand {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

export const CompLogoBrandInner: React.FC<ICompLogoBrand> = ({ id, logos }) => {
  return (
    <SCompLogoBand id={id ?? ""}>
      <GridPadding>
        {!!logos?.length && (
          <CompSwiper
            slidesPerView={4}
            spaceBetween={32}
            breakpoints={{
              1280: {
                slidesPerView: 8.5,
                spaceBetween: 60,
              },
              1920: {
                slidesPerView: 7.75,
                spaceBetween: 120,
              },
            }}
            centeredSlides
            watchOverflow={false}
            loop
            speed={5000}
            autoplay={{ delay: 1, disableOnInteraction: false }}
            freeMode
            simulateTouch={false}
            plugins={[Autoplay]}
            slides={logos?.map(
              (logo) =>
                logo?.src && (
                  <SCompLogoBandSingleSlideWrapper key={logo.id}>
                    <NextImage
                      src={logo.src}
                      alt={logo.description || logo.title || ""}
                      fill
                      style={{ objectFit: "contain" }}
                    />
                  </SCompLogoBandSingleSlideWrapper>
                )
            )}
          />
        )}
      </GridPadding>
    </SCompLogoBand>
  );
};
