"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSPrimaryButtons } from "../../../constants/styles/typographies/CSSTypographies";
import { Button } from "../../button/Button";

interface ISCompPricingHeaderWrapperCardButton {
  gradientAccent?: string;
}

const CSSGradient = css<ISCompPricingHeaderWrapperCardButton>`
  background: ${({ gradientAccent }) =>
    gradientAccent && backgroundGradients[gradientAccent]};
  border: none;
  padding: 16px 24px;
  color: var(--text-02);
`;

const CSSWhite = css`
  padding: 15px 23px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: var(--text-02);
`;

const CSSGrey = css`
  padding: 15px 23px;
  color: var(--text-01);
  background: var(--interactional-tertiary-03);
  border: 1px solid var(--interactional-secondary-08);
`;

const Bronze = css<ISCompPricingHeaderWrapperCardButton>`
  width: 100%;
  border-radius: 100px;
  margin-top: 40px;
  ${CSSPrimaryButtons};

  ${({ gradientAccent }) => /Gradient/.test(gradientAccent) && CSSGradient};
  ${({ gradientAccent }) => gradientAccent === "White" && CSSWhite};
  ${({ gradientAccent }) => gradientAccent === "Grey" && CSSGrey};

  &:hover {
    ${({ gradientAccent }) => /Gradient/.test(gradientAccent) && CSSGradient};
    ${({ gradientAccent }) => gradientAccent === "White" && CSSWhite};
    ${({ gradientAccent }) => gradientAccent === "Grey" && CSSGrey};
  }
`;

const Silver = css<ISCompPricingHeaderWrapperCardButton>``;

const Gold = css<ISCompPricingHeaderWrapperCardButton>``;

const Platinum = css<ISCompPricingHeaderWrapperCardButton>``;

const Diamond = css<ISCompPricingHeaderWrapperCardButton>`
  margin-top: 78px;
`;

export const SCompPricingHeaderWrapperCardButton = styled(
  Button
)<ISCompPricingHeaderWrapperCardButton>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
