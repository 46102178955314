"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSSubtitle01Med,
  CSSSubtitle01MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompInfoComponentWithCtaSubHeadline {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ICompInfoComponentWithCtaSubHeadline>`
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
  ${CSSSubtitle01Med};
  margin-top: 16px;
  opacity: 0.9;
`;

const Silver = css<ICompInfoComponentWithCtaSubHeadline>``;

const Gold = css<ICompInfoComponentWithCtaSubHeadline>`
  margin: 24px auto 0;
  width: 58.81%;
`;

const Platinum = css<ICompInfoComponentWithCtaSubHeadline>``;

const Diamond = css<ICompInfoComponentWithCtaSubHeadline>`
  width: 66.39%;
  ${CSSSubtitle01MedXLarge};
`;

export const SCompInfoComponentWithCtaSubHeadline = styled.div<ICompInfoComponentWithCtaSubHeadline>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
