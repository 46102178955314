"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSSubtitle01Med,
  CSSSubtitle01MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: rgba(255, 255, 255, 0.9);
  margin-top: 12px;
  ${CSSSubtitle01Med}
`;

const Silver = css``;

const Gold = css`
  margin-top: 12px;
  ${CSSSubtitle01Med}
`;

const Platinum = css``;

const Diamond = css`
  ${CSSSubtitle01MedXLarge}
`;

export const SCompSubpageHeroBlockDescription = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
