import { NextImage } from "src/components/next-image/NextImage";
import React from "react";
import { RichText } from "../../../base-components/rich-text/RichText";
import { IGenFeatureCard, Maybe } from "../../../utils/types_gen";
import { Button } from "../../button/Button";
import { SCompFeatureCardItemImageWrapper } from "./styles/SCompFeatureCardItemImageWrapper";
import { SCompFeaturedCardItem } from "./styles/SCompFeaturedCardItem";
import { SCompFeaturedCardItemButtonWrapper } from "./styles/SCompFeaturedCardItemButtonWrapper";
import { SCompFeaturedCardItemCardContent } from "./styles/SCompFeaturedCardItemCardContent";
import { SCompFeaturedCardItemCardFooterDescription } from "./styles/SCompFeaturedCardItemCardFooterDescription";
import { SCompFeaturedCardItemCardFooterTitle } from "./styles/SCompFeaturedCardItemCardFooterTitle";
import { SCompFeaturedCardItemTitleAndDesc } from "./styles/SCompFeaturedCardItemTitleAndDesc";

interface ICompFeaturedCardItem extends IGenFeatureCard {
  isSectionBgWhite?: Maybe<boolean> | undefined;
  isSwiper?: boolean | undefined | null;
}

export const CompFeaturedCardItem: React.FC<ICompFeaturedCardItem> = ({
  id,
  cardButton,
  card,
  isSectionBgWhite,
  isSwiper,
}) => {
  const cardData = card?.[0] ? card?.[0] : null;
  if (!cardData) return null;

  const image =
    cardData.__typename === "BlogArticle"
      ? cardData.featuredImage
      : cardData.pageFeaturedImage;
  const title =
    cardData.__typename === "BlogArticle" ? cardData.title : cardData.pageTitle;
  const description =
    cardData.__typename === "BlogArticle"
      ? cardData.featuredDescription
      : cardData.pageDescription;

  return (
    <SCompFeaturedCardItem id={id ?? ""}>
      {image?.src && (
        <SCompFeatureCardItemImageWrapper isSwiper={isSwiper}>
          <NextImage
            src={image.src}
            alt={image.description || image.title || ""}
            fill
            style={{ objectFit: "cover" }}
            loading="lazy"
          />
        </SCompFeatureCardItemImageWrapper>
      )}
      <SCompFeaturedCardItemCardContent>
        <SCompFeaturedCardItemTitleAndDesc>
          {title && (
            <SCompFeaturedCardItemCardFooterTitle
              isSectionBgWhite={isSectionBgWhite}
            >
              {title}
            </SCompFeaturedCardItemCardFooterTitle>
          )}
          {!!description?.json?.content?.length && (
            <SCompFeaturedCardItemCardFooterDescription>
              <RichText content={description?.json.content} />
            </SCompFeaturedCardItemCardFooterDescription>
          )}
        </SCompFeaturedCardItemTitleAndDesc>
        {cardButton ? (
          <SCompFeaturedCardItemButtonWrapper>
            <Button {...cardButton}>{cardButton.title}</Button>
          </SCompFeaturedCardItemButtonWrapper>
        ) : (
          <SCompFeaturedCardItemButtonWrapper>
            <Button
              buttonStyle={isSectionBgWhite ? "Primary" : "Tertiary"}
              internalLink={[cardData] as any}
              shape="Big"
            >
              Read more
            </Button>
          </SCompFeaturedCardItemButtonWrapper>
        )}
      </SCompFeaturedCardItemCardContent>
    </SCompFeaturedCardItem>
  );
};
