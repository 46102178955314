"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISCompHeadlineWithStatisticsSvgWrapper {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ISCompHeadlineWithStatisticsSvgWrapper>`
  border-radius: 50%;
  border: 1px solid
    ${({ isSectionBgWhite }) =>
      isSectionBgWhite ? "var(--ui-03)" : "rgba(255, 255, 255, 0.15)"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;

const Silver = css<ISCompHeadlineWithStatisticsSvgWrapper>``;

const Gold = css<ISCompHeadlineWithStatisticsSvgWrapper>`
  width: 80px;
  height: 80px;
  margin-bottom: auto;
`;

const Platinum = css<ISCompHeadlineWithStatisticsSvgWrapper>``;

const Diamond = css<ISCompHeadlineWithStatisticsSvgWrapper>`
  width: 104px;
  height: 104px;
`;

export const SCompHeadlineWithStatisticsSvgWrapper = styled.div<ISCompHeadlineWithStatisticsSvgWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
