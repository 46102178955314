export function getCookie(name) {
  const dc = document.cookie;
  if (!dc || dc.length == 0) return null;

  const prefix = name + "=";
  let begin = dc.indexOf("; " + prefix);
  let end = dc.length - 1;
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}
