"use client";
import styled from "styled-components";

export const SOnboardingTemplatesTemplateStackIcon = styled.div`
  height: 20px;
  width: 20px;
  img {
    height: 20px;
    width: 20px;
  }
`;
