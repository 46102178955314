"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css<{
  tabsLength: number;
}>`
  position: relative;
  pointer-events: none;
  opacity: 0;
  > div {
    width: ${({ tabsLength }) => tabsLength * 100}%;
    display: flex;
    flexwrap: nowrap;
  }
  /* margin-top: 46px; */
`;

const Silver = css`
  /* margin-top: 67px; */
`;

const Gold = css`
  /* margin-top: 85px; */
`;

const Platinum = css``;

const Diamond = css``;

export const SCompMultiImageWithTextHeightFaker = styled.div<{
  tabsLength: number;
}>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
