"use client";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import styled, { css } from "styled-components";

const Bronze = css`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
`;

const Silver = css``;

const Gold = css`
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const Platinum = css``;

const Diamond = css``;

export const SOnboardingTemplatesGrid = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
