import { keyframes } from "styled-components";

export const item1SlideIn = keyframes`
0%{
    opacity: 0;
    translate: 50px -55%;
}
100%{
    opacity: 1;
    translate: 0px -55%;
}
`;

export const item1Position = keyframes`
0%{
    opacity: 1;
    top:49%;
    translate: 0px -55%;
}
100%{
    opacity: 1;
    top:16%;
    translate: 0px -50%;
}
`;
export const item2SlideIn = keyframes`
0%{
    opacity: 0;
    translate: -50px -55%;
}
100%{
    opacity: 1;
    translate: 0px -55%;
}
`;
export const item4SlideIn = keyframes`
0%{
    opacity: 0;
    translate: -50px -50%;
}
100%{
    opacity: 1;
    translate: 0px -50%;
}
`;

export const item2Position = keyframes`
0%{
    opacity: 1;
    top:49%;
    translate: 0px -55%;
}
100%{
    opacity: 1;
    top:16%;
    translate: 0px -50%;
}
`;
export const item3SlideIn = keyframes`
0%{
    opacity: 0;
    translate: -50px -50%;
}
100%{
    opacity: 1;
    translate: 0px -50%;
}
`;

export const item6SlideIn = keyframes`
0%{
    opacity: 0;
    
}
100%{
    opacity: 1;
   
}
`;

export const draw = keyframes`
0%{
    stroke-dashoffset: 500;
}
100%{   
    stroke-dashoffset: 0;
}
`;

export const undraw = keyframes`
0%{
    stroke-dashoffset: 0;
}
100%{   
    stroke-dashoffset: -500;
}
`;

export const dotPath = keyframes`
0%{
    offset-distance: 0%;
}

100%{
    offset-distance: 100%;
   opacity: 1;
}
`;

export const middleLeftDotPath = keyframes`
0%{
    left: -50%;
    opacity: 0;     
}

50%{
    left: -25%;
   opacity: 1;
}

100%{
    left: -10%;
   opacity: 0;
}
`;

export const middleRightDotPath = keyframes`
0%{
    left: 0%;
}

100%{
    left: 40%;
   opacity: 1;
}
`;

export const topLeftDotPath = keyframes`
0%{
    top: -35.5%;
   left: -40%;
   opacity:0;   
}

2%{
    top: -35.5%;
   left: -40%;
   opacity:1;   
}

50%{
    top: -35.5%;
   left: -25.2%;
   opacity:0;
}

55%{
    top: -28%;
   left: -16.2%;
   opacity:0;
}

56%{
    top: -28%;
   left: -16.2%;
   opacity: 0.1;
}
57%{
    top: -28%;
   left: -16.2%;
   opacity:1;
}

100%{
    top: -10%;
   left: -16.2%;
   opacity:0;
}
`;

export const bottomLeftDotPath = keyframes`
0%{
    top: 30.5%;
    left: -40%;
    opacity:0;  
}

25%{
    top: 30.5%;
    left: -32%;
    opacity:1;  
}

50%{
    top: 30.5%;
   left: -24%;
   opacity:0; 
}

55%{
    top: 20%;
   left: -16.15%;
   opacity:0;
}

77%{
    opacity:1;
}

100%{
    top: 10%;
   left: -16.15%;
   opacity:0;
}
`;
export const topRightDotPath = keyframes`
0%{
    opacity:0;
    top: -35.5%;
    left: 20%;
}
50%{
    opacity:1;
    top: -35.5%;
    left: 30%;
}
100%{
    opacity:0;
    top: -35.5%;
    left: 40%;
}
`;
export const bottomRightDotPath = keyframes`
0%{
    opacity:0;
    top: 6%;
    left: 11.3%;
}
20%{
    opacity:1;
    top: 12.5%;
    left: 11.3%;
}
40%{
    opacity:0;
    top: 20%;
    left: 11.3%;
}

50%{
    opacity:0;
  top: 30.5%;
    left: 20%;
}
90%{
    opacity:1;
  top: 30.5%;
    left: 37%;
}
100%{
    opacity:0;
  top: 30.5%;
    left: 38%;
} 
`;
