"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSTitle02,
  CSSTitle02Xlarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css<{
  $isSectionBgWhite: boolean;
}>`
  ${CSSTitle02};
  ${({ $isSectionBgWhite }) =>
    $isSectionBgWhite ? "color: var(--text-01)" : "color: var(--text-02)"};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${CSSTitle02Xlarge};
`;

export const SCompFeaturedBlogCardTitle = styled.h3<{
  $isSectionBgWhite: boolean;
}>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
