"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody02,
  CssBody02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ICompImageTextDescription {
  $isSectionBgWhite: boolean;
}

const Bronze = css<ICompImageTextDescription>`
  margin-top: 8px;
  ${CSSBody02};
  color: ${({ $isSectionBgWhite }) =>
    $isSectionBgWhite ? "var(--text-04)" : "rgba(255,255,255,0.75)"};
`;

const Silver = css<ICompImageTextDescription>``;

const Gold = css<ICompImageTextDescription>`
  margin-top: 16px;
`;

const Platinum = css<ICompImageTextDescription>``;

const Diamond = css<ICompImageTextDescription>`
  ${CssBody02XLarge}
`;

export const SCompImageTextDescription = styled.div<ICompImageTextDescription>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
