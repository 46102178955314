"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

// const InView = css`
//   opacity: 1;
//   transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
//   transform: translateY(0);
// `;

// const NotInView = css`
//   opacity: 0;
//   transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
//   transform: translate3d(0px, 200px, 0px);
// `;
// ${({ inView }) => (inView ? InView : NotInView)};

const Bronze = css<{ inView?: boolean }>``;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompImageText = styled.div<{ inView?: boolean }>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
