"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSBody03XLarge } from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  line-height: 0;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--text-01);

  svg {
    color: var(--icon-03);
    width: 20px;
    height: 20px;
  }
`;

const Silver = css`
  text-align: left;
  padding: 16px 12px;
  ${CSSBody03XLarge}
`;

const Gold = css`
  vertical-align: middle;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Platinum = css``;

const Diamond = css`
  padding: 20px 12px;
`;

export const SCompTableCell = styled.td`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
