"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { backgroundGradients } from "../../../constants/styles/gradients";

interface ICSSCompMultiImageWithText {
  $backgroundGradient?: string;
}

const Bronze = css<ICSSCompMultiImageWithText>`
  padding: 34px 0px;
  border-radius: 12px;
  background: ${({ $backgroundGradient }) =>
    $backgroundGradient
      ? backgroundGradients[$backgroundGradient]
      : "var(--blue-gradient)"};
  /* min-width: 327px; */
`;

const Silver = css``;

const Gold = css`
  padding: 52px 0px;
`;

const Platinum = css``;

const Diamond = css`
  padding: 100px 0px;
`;

export const SCompMultiImageWithText = styled.div<ICSSCompMultiImageWithText>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
