"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 16px 24px;
`;

const Silver = css`
  margin-bottom: 0;
  grid-template-columns: 2fr repeat(5, 1fr);
`;

const Gold = css`
  display: grid;
  padding-top: 0;
  border: none;
  margin-bottom: 0;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompTableCustomizedRow = styled.tr`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
