"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISSectionContainer {
  $isBgWhite: Maybe<boolean> | undefined;
  $isSectionBeforeOverlap: number | boolean | null | undefined;
  id: any;
}

const Bronze = css<ISSectionContainer>`
  background-color: ${({ $isBgWhite }) =>
    $isBgWhite ? "var(--ui-01)" : "var(--ui-07)"};
  padding-top: ${({ $isSectionBeforeOverlap }) =>
    $isSectionBeforeOverlap ? "40px" : ""};
`;

const Silver = css<ISSectionContainer>``;

const Gold = css<ISSectionContainer>``;

const Platinum = css<ISSectionContainer>`
  padding-top: ${({ $isSectionBeforeOverlap }) =>
    $isSectionBeforeOverlap ? "80px" : ""};
`;

const Diamond = css<ISSectionContainer>``;

export const SSectionContainer = styled.div<ISSectionContainer>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
