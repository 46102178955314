"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:first-of-type {
    margin-top: 0;
  }

  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
`;

const Silver = css``;

const Gold = css`
  gap: 12px;
`;

const Platinum = css``;

const Diamond = css`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SCompPricingHeaderWrapperCardOption = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
