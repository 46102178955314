"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSCaption01Med,
  CSSCaption01MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompCardWithIconText {
  hasTitle: boolean;
}

const Bronze = css<ISCompCardWithIconText>`
  ${CSSCaption01Med};
  text-align: center;
  color: ${({ hasTitle }) => (hasTitle ? "var(--text-04)" : "var(--text-02)")};
`;

const Silver = css<ISCompCardWithIconText>``;

const Gold = css<ISCompCardWithIconText>``;

const Platinum = css<ISCompCardWithIconText>``;

const Diamond = css<ISCompCardWithIconText>`
  ${CSSCaption01MedXLarge};
`;

export const SCompCardWithIconText = styled.div<ISCompCardWithIconText>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
