"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ICompQuoteRightWrapper {
  bgGradient?: string;
}

const Bronze = css<ICompQuoteRightWrapper>`
  background: ${({ bgGradient }) =>
    bgGradient ? backgroundGradients[bgGradient] : "var(--blue-gradient)"};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  direction: ltr;
  padding: 32px;
`;

const Silver = css<ICompQuoteRightWrapper>``;

const Gold = css<ICompQuoteRightWrapper>`
  padding: 40px;
  justify-content: space-between;
`;

const Platinum = css<ICompQuoteRightWrapper>``;

const Diamond = css<ICompQuoteRightWrapper>`
  padding: 52px 52px 60px 52px;
`;

export const SCompQuoteRightWrapper = styled.div<ICompQuoteRightWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
