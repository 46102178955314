"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHighlight,
  CSSHighlightMicro,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Button } from "../../button/Button";

const Bronze = css`
  border: 1px solid var(--interactional-secondary-08);
  background: transparent;
  color: var(--text-04);
  ${CSSHighlightMicro};
  padding: 11px;
  border-radius: 56px;
  text-transform: uppercase;

  &:hover {
    border: 1px solid var(--interactional-secondary-08);
  }
`;

const Silver = css``;

const Gold = css`
  ${CSSHighlight};
  padding: 15px;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompTableCustomButton = styled(Button)`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
