import React from "react";

function PlayButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21 12v0a9 9 0 01-9 9v0a9 9 0 01-9-9v0a9 9 0 019-9v0a9 9 0 019 9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.94 9.059l3.883 2.296a.75.75 0 010 1.29l-3.882 2.297a.75.75 0 01-1.132-.646V9.705a.75.75 0 011.132-.646v0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PlayButton;
