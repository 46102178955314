"use client";
import styled, { css } from "styled-components";
import { Grid } from "../../../base-components/grid/Grid";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  filter: drop-shadow(var(--big-elevation));

  // the following is here because safari has some issues rendering 'filter: drop-shadow' otherwise
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`;

const Silver = css``;

const Gold = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const Platinum = css``;

const Diamond = css``;

export const SCompPricingHeaderWrapperCustomGrid = styled(Grid)`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
