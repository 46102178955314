"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading01Small,
  CSSHeading01,
  CSSHeading01XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISCompHeadline {
  $isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ISCompHeadline>`
  color: ${({ $isSectionBgWhite }) =>
    $isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
  ${CSSHeading01Small};

  strong {
    color: var(--text-09);
  }

  p {
    display: inline;
  }
`;

const Silver = css<ISCompHeadline>``;

const Gold = css<ISCompHeadline>`
  ${CSSHeading01};

  p {
    display: block;
  }
`;

const Platinum = css<ISCompHeadline>``;

const Diamond = css<ISCompHeadline>`
  ${CSSHeading01XLarge};
`;

export const SCompHeadline = styled.div<ISCompHeadline>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
