"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSSmallButtonsXLarge } from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  > * {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    ${CSSSmallButtonsXLarge};
    box-sizing: border-box;
    p {
      background: var(--blue-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompPricingHeaderWrapperPromoButtonCustom = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
