export const CmsAnimatedLogo = () => (
  <svg
    width="135"
    height="135"
    viewBox="0 0 135 135"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="logo"
  >
    <circle
      className="logoCricles"
      cx="67.5"
      cy="67.5"
      r="67.5"
      fill="#008CFF"
      fillOpacity="0.1"
    />
    <circle cx="67.5" cy="67.5" r="49.5" fill="#008CFF" fillOpacity="0.05" />
    <rect x="36" y="35" width="64" height="64" rx="32" fill="#008CFF" />
    <path
      d="M69.072 65.3173C75.7703 73.7098 82.2458 63.7214 71.9952 58.9893L75.2296 58.7861C75.2296 58.7861 84.3562 66.0645 79.3358 70.826C74.3153 75.5876 68.6722 66.6445 68.6722 66.6445L69.072 65.3173ZM68.9279 79.33C68.8721 79.3496 68.8164 79.3628 68.7542 79.3791L68.4723 79.4447C66.9098 79.7881 65.3271 80.0322 63.7337 80.1755C63.4262 80.2027 63.1184 80.126 62.8596 79.9576C62.6008 79.7892 62.406 79.5388 62.3063 79.2466C62.2066 78.9544 62.2078 78.6371 62.3098 78.3457C62.4117 78.0542 62.6085 77.8054 62.8686 77.639C68.4199 74.1359 66.7355 71.2553 63.9828 70.9899C73.5256 70.5901 75.1575 77.6521 68.9279 79.33Z"
      fill="#91C6FF"
    />
    <path
      opacity="0.3"
      d="M69.2417 79.258C69.1402 79.2809 69.0353 79.317 68.914 79.3465C69.6133 79.0459 70.2238 78.5708 70.6869 77.9668C72.7318 75.1256 68.3143 72.481 66.122 74.6439C67.2755 72.6219 65.8828 71.1866 63.9854 71.0063C73.1087 71.393 75.0847 77.4359 69.2417 79.258Z"
      fill="#008CFF"
    />
    <path
      d="M68.8882 79.3433C74.079 77.8391 73.6104 71.4128 63.8809 70.9835C60.8955 70.7738 56.4223 71.4652 53.7482 67.287C51.297 63.4528 52.1851 58.3472 54.515 55.5257C55.934 53.7986 57.8937 52.927 59.9582 53.7298C61.269 54.2443 62.6421 55.447 63.9267 57.5542C69.3797 66.494 74.2134 73.107 78.313 69.9906C82.0325 67.1657 78.6014 60.9131 71.7949 58.9272C70.2482 58.4783 70.2711 56.2597 71.8506 55.9091C86.4204 52.632 89.9039 74.3228 68.8882 79.3433Z"
      fill="white"
    />
  </svg>
);
