"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISCompCardsWithTextWrapper {
  wideOnMobile?: Maybe<boolean> | undefined;
}

const Bronze = css<ISCompCardsWithTextWrapper>`
  display: grid;
  grid-template-columns: ${({ wideOnMobile }) =>
    wideOnMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"};
  gap: 48px 24px;
  margin-top: 76px;
`;

const Silver = css<ISCompCardsWithTextWrapper>``;

const Gold = css<ISCompCardsWithTextWrapper>`
  grid-template-columns: repeat(4, 1fr);
  gap: 48px 24px;
  margin-top: 64px;
`;

const Platinum = css<ISCompCardsWithTextWrapper>``;

const Diamond = css<ISCompCardsWithTextWrapper>`
  margin-top: 110px;
`;

export const SCompCardsWithTextWrapper = styled.div<ISCompCardsWithTextWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
