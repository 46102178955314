"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSCaption01Med,
  CSSCaption01MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  width: 100%;
  height: 100%;
  padding: 12px;
  text-align: center;
  ${CSSCaption01Med};
  font-weight: 600;
  color: var(--text-02);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  padding: 18px;
  ${CSSCaption01MedXLarge};
  font-weight: 600;
`;

export const SCompHowItWorksGraphicText = styled.p`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
