"use client";

import React from "react";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { RichText } from "../../base-components/rich-text/RichText";
import {
  IGenRoadmap,
  IGenRoadmapCard,
  IGenRoadmapColumn,
} from "../../utils/types_gen";
import { Button } from "../button/Button";
import { RoadmapCardColumn } from "./roadmap-card-column/RoadmapCardColumn";
import { SCompRoadmap } from "./styles/SCompRoadmap";
import { SCompRoadmapBtn } from "./styles/SCompRoadmapBtn";
import { SRoadmapColumnsContainer } from "./styles/SRoadmapColumnsContainer";
import { SRoadmapContainer } from "./styles/SRoadmapContainer";
import { SRoadmapContentTitle } from "./styles/SRoadmapContentTitle";
import { SRoadmapHeader } from "./styles/SRoadmapHeader";
import { SRoadmapHeaderContent } from "./styles/SRoadmapHeaderContent";
import { SRoadmapHeaderDescription } from "./styles/SRoadmapHeaderDescription";

const charsPerLine = 46;
const lineHeight = 20;

const getDesktopCardDescriptionHeight = (columns: IGenRoadmapColumn[]) => {
  let maxHeight = 0;
  columns?.forEach((column: IGenRoadmapColumn) => {
    column.cards.forEach((c: IGenRoadmapCard) => {
      let heightOfCard = 0;
      c.text?.json?.content?.forEach((clayer1) =>
        clayer1.content?.forEach((clayer2) => {
          if (clayer2.text?.length) {
            heightOfCard += (clayer2.text?.length / charsPerLine) * lineHeight;
          }
          if (heightOfCard < 20) {
            heightOfCard = 20;
          }
        })
      );
      if (heightOfCard > maxHeight) {
        maxHeight = heightOfCard;
      }
    });
  });

  if (!maxHeight) {
    return maxHeight;
  }

  const desktopCardDescriptionHeight =
    Math.ceil(maxHeight / lineHeight) * lineHeight;

  return desktopCardDescriptionHeight;
};

export const CompRoadmapInner: React.FC<IGenRoadmap> = ({
  button,
  columns,
  id,
  text,
  title,
}) => {
  const [showColumnCards, setShowColumnCards] = React.useState("");

  return (
    <SCompRoadmap id={id}>
      <GridPadding>
        <SRoadmapContainer>
          <SRoadmapHeader>
            <SRoadmapHeaderContent>
              {title && <SRoadmapContentTitle>{title}</SRoadmapContentTitle>}
              {!!text?.json?.content?.length && (
                <SRoadmapHeaderDescription>
                  <RichText content={text?.json.content} />
                </SRoadmapHeaderDescription>
              )}
            </SRoadmapHeaderContent>
            {button?.map(
              (btn) =>
                btn && (
                  <SCompRoadmapBtn key={btn.id}>
                    <Button {...btn}>{btn.title}</Button>
                  </SCompRoadmapBtn>
                )
            )}
          </SRoadmapHeader>
          {!!columns?.length && (
            <SRoadmapColumnsContainer>
              {columns.map(
                (col) =>
                  col && (
                    <RoadmapCardColumn
                      key={col.id}
                      {...col}
                      desktopCardDescriptionHeight={getDesktopCardDescriptionHeight(
                        columns as IGenRoadmapColumn[]
                      )}
                      showColumnCards={showColumnCards}
                      setShowColumnCards={setShowColumnCards}
                    />
                  )
              )}
            </SRoadmapColumnsContainer>
          )}
        </SRoadmapContainer>
      </GridPadding>
    </SCompRoadmap>
  );
};
