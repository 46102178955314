"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompImageTextAssetWrapper {
  imageHasShadow?: boolean;
  noBorderRadius?: boolean;
  showImageOnRightSide?: boolean;
  extendImageOverGrid?: boolean;
  aspectRatio?: number;
}
const CSSPaddingTopAspectRatio = css<ISCompImageTextAssetWrapper>`
  padding-top: ${({ aspectRatio }) => aspectRatio * 100}%;
  img {
    position: absolute;
    inset: 0;
  }
`;

const Bronze = css<ISCompImageTextAssetWrapper>`
  position: relative;
  img,
  .video-iframe-wrapper {
    border-radius: ${({ noBorderRadius }) =>
      noBorderRadius ? "none" : "12px"};

    filter: ${({ imageHasShadow }) =>
      imageHasShadow ? "drop-shadow(var(--big-elevation))!important" : ""};
  }

  .video-iframe-wrapper {
    overflow: hidden;
  }

  > div {
    position: relative;
    width: 100%;

    ${({ aspectRatio }) => aspectRatio && CSSPaddingTopAspectRatio}
  }
`;

// const Silver = css<ISCompImageTextAssetWrapper>`
//   margin: ${({ extendImageOverGrid, showImageOnRightSide }) =>
//     extendImageOverGrid
//       ? showImageOnRightSide
//         ? "0 -24px 0 0"
//         : "0 0 0 -24px"
//       : 0};

//   img {
//     border-radius: ${({
//       noBorderRadius,
//       extendImageOverGrid,
//       showImageOnRightSide,
//     }) =>
//       noBorderRadius
//         ? "none"
//         : !extendImageOverGrid
//         ? "12px"
//         : showImageOnRightSide
//         ? "12px 0 0 12px"
//         : "0 12px 12px 0"};
//   }
// `;
const Silver = css<ISCompImageTextAssetWrapper>``;

const Gold = css<ISCompImageTextAssetWrapper>`
  margin-right: ${({ extendImageOverGrid, showImageOnRightSide }) =>
    extendImageOverGrid && showImageOnRightSide ? "-52px" : 0};
  margin-left: ${({ extendImageOverGrid, showImageOnRightSide }) =>
    extendImageOverGrid && !showImageOnRightSide ? "-52px" : 0};

  img,
  .video-iframe-wrapper {
    border-radius: ${({
      noBorderRadius,
      extendImageOverGrid,
      showImageOnRightSide,
    }) =>
      noBorderRadius
        ? "none"
        : !extendImageOverGrid
        ? "12px"
        : showImageOnRightSide
        ? "12px 0 0 12px"
        : "0 12px 12px 0"};
  }
`;

const Platinum = css<ISCompImageTextAssetWrapper>``;

const Diamond = css<ISCompImageTextAssetWrapper>`
  margin-right: ${({ extendImageOverGrid, showImageOnRightSide }) =>
    extendImageOverGrid && showImageOnRightSide ? "-76px" : 0};
  margin-left: ${({ extendImageOverGrid, showImageOnRightSide }) =>
    extendImageOverGrid && !showImageOnRightSide ? "-76px" : 0};
  img,
  .video-iframe-wrapper {
    border-radius: ${({ noBorderRadius }) =>
      noBorderRadius ? "none" : "12px"};
  }
`;

export const SCompImageTextAssetWrapper = styled.div<ISCompImageTextAssetWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
