import { NextImage } from "src/components/next-image/NextImage";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import { RichText } from "../../base-components/rich-text/RichText";
import { IGenImageText, Maybe } from "../../utils/types_gen";
import { Button } from "../button/Button";
import { Label } from "../label/Label";
import { SCompImageText } from "./styles/SCompImageText";
import { SCompImageTextAssetWrapper } from "./styles/SCompImageTextAssetWrapper";
import { SCompImageTextBlockTitle } from "./styles/SCompImageTextBlockTitle";
import { SCompImageTextDescription } from "./styles/SCompImageTextDescription";
import { SCompImageTextDescriptionWrapper } from "./styles/SCompImageTextDescriptionWrapper";
import { SCompImageTextIcon } from "./styles/SCompImageTextIcon";
import { SCompImageTextIconWrapper } from "./styles/SCompImageTextIconWrapper";
import { SCompImageTextLabelBtnContainer } from "./styles/SCompImageTextLabelBtnContainer";
import { SCompImageTextIconTextBlockWrapper } from "./styles/SCompImageTextIconTextBlockWrapper";
// import { ImgV2 } from "../../base-components/img-v2/Img";
// import { AnimatedSvg } from "../animated-svg/AnimatedSvg";
import { ResponseDisplay } from "../responsive-display/ResponseDisplay";
import { BrowserWindowImageFrame } from "../browser-window-image-frame/BrowserWindowImageFrame";
import { Spacer } from "../spacer/Spacer";

interface ICompImageText extends IGenImageText {
  isSectionBgWhite?: Maybe<boolean> | undefined;
  reverseOrderOnSmallScreen?: Maybe<boolean> | undefined;
}

export const CompImageText: React.FC<ICompImageText> = ({
  id,
  icon,
  title,
  text,
  label,
  button,
  smallScreenImage,
  mediumScreenImage,
  largeScreenImage,
  showImageOnRightSide,
  isSectionBgWhite,
  imageHasShadow,
  noBorderRadius,
  extendImageOverGrid,
  alignTextblockInCenter,
  vimeoId,
  videoAspectRatio,
  renderWindowFrame,
  reverseOrderOnSmallScreen,
}) => {
  const imageFragment =
    smallScreenImage && smallScreenImage.src ? (
      <ResponseDisplay
        bronze={
          <NextImage
            alt={smallScreenImage.description || smallScreenImage.title}
            src={smallScreenImage.src}
            width={smallScreenImage.width}
            height={smallScreenImage.height}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            loading="lazy"
          />
        }
        gold={
          <NextImage
            alt={
              (mediumScreenImage || smallScreenImage).description ||
              (mediumScreenImage || smallScreenImage).title
            }
            src={(mediumScreenImage || smallScreenImage).src}
            width={(mediumScreenImage || smallScreenImage).width}
            height={(mediumScreenImage || smallScreenImage).height}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            loading="lazy"
          />
        }
        diamond={
          <NextImage
            alt={
              (largeScreenImage || mediumScreenImage || smallScreenImage)
                .description ||
              (largeScreenImage || mediumScreenImage || smallScreenImage).title
            }
            src={
              (largeScreenImage || mediumScreenImage || smallScreenImage).src
            }
            width={
              (largeScreenImage || mediumScreenImage || smallScreenImage).width
            }
            height={
              (largeScreenImage || mediumScreenImage || smallScreenImage).height
            }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            loading="lazy"
          />
        }
      />
    ) : null;

  const videoFragment = vimeoId ? (
    <div
      className="video-iframe-wrapper"
      style={{
        position: "relative",
        paddingTop: videoAspectRatio ? `${videoAspectRatio}%` : "56.25%",
        width: "100%",
      }}
    >
      <iframe
        src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&muted=1&loop=1&autopause=0&background=1`}
        allow="autoplay"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  ) : null;

  return (
    <SCompImageText id={id}>
      <GridPadding>
        <Grid
          style={{ alignItems: alignTextblockInCenter ? "center" : undefined }}
        >
          <GridItem
            bronze={{
              row: {
                start: reverseOrderOnSmallScreen ? 1 : 3,
                end: reverseOrderOnSmallScreen ? 1 : 3,
              },
              col: { start: 1, end: 12 },
            }}
            gold={{
              row: { start: 1, end: 1 },
              col: {
                start: showImageOnRightSide ? 2 : extendImageOverGrid ? 9 : 8,
                end: showImageOnRightSide ? 5 : 12,
              },
            }}
          >
            <SCompImageTextIconTextBlockWrapper>
              {icon?.src ? (
                <SCompImageTextIconWrapper>
                  <SCompImageTextIcon>
                    <NextImage
                      src={icon.src}
                      alt={icon.description || icon.title}
                      width={100}
                      height={100}
                      style={{ objectFit: "contain" }}
                    />
                  </SCompImageTextIcon>
                </SCompImageTextIconWrapper>
              ) : null}
              <SCompImageTextDescriptionWrapper isCompHasIcon={!!icon?.src}>
                <SCompImageTextBlockTitle $isSectionBgWhite={isSectionBgWhite}>
                  {title}
                </SCompImageTextBlockTitle>
                <SCompImageTextDescription $isSectionBgWhite={isSectionBgWhite}>
                  <RichText content={text?.json?.content} />
                </SCompImageTextDescription>
                <SCompImageTextLabelBtnContainer>
                  {label?.length > 0 &&
                    label &&
                    label?.map(
                      (item) => item && <Label key={item.id} {...item} />
                    )}
                  {button?.length > 0 &&
                    button &&
                    button?.map(
                      (item) =>
                        item && (
                          <Button key={item.id} {...item}>
                            {item.title}
                          </Button>
                        )
                    )}
                </SCompImageTextLabelBtnContainer>
              </SCompImageTextDescriptionWrapper>
            </SCompImageTextIconTextBlockWrapper>
          </GridItem>
          {reverseOrderOnSmallScreen && <Spacer bronze={24} />}
          {(imageFragment || videoFragment) && (
            <GridItem
              bronze={{
                row: {
                  start: reverseOrderOnSmallScreen ? 3 : 1,
                  end: reverseOrderOnSmallScreen ? 3 : 1,
                },
                col: { start: 1, end: 12 },
              }}
              gold={{
                row: { start: 1, end: 1 },
                col: {
                  start: showImageOnRightSide ? 7 : extendImageOverGrid ? 1 : 2,
                  end: showImageOnRightSide
                    ? extendImageOverGrid
                      ? 12
                      : 11
                    : 6,
                },
              }}
            >
              <SCompImageTextAssetWrapper
                noBorderRadius={noBorderRadius || renderWindowFrame}
                extendImageOverGrid={extendImageOverGrid}
                imageHasShadow={imageHasShadow}
                showImageOnRightSide={showImageOnRightSide}
              >
                {videoFragment ? (
                  <>
                    {renderWindowFrame ? (
                      <BrowserWindowImageFrame>
                        {videoFragment}
                      </BrowserWindowImageFrame>
                    ) : (
                      <>{videoFragment}</>
                    )}
                  </>
                ) : (
                  <div>
                    {renderWindowFrame ? (
                      <BrowserWindowImageFrame>
                        {imageFragment}
                      </BrowserWindowImageFrame>
                    ) : (
                      <>{imageFragment}</>
                    )}
                  </div>
                )}
              </SCompImageTextAssetWrapper>
            </GridItem>
          )}
        </Grid>
      </GridPadding>
    </SCompImageText>
  );
};
