export const LineCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clipPath="url(#clip0_8627_20831)">
      <g opacity="0.05" filter="url(#filter0_d_8627_20831)">
        <circle
          cx="9"
          cy="8.99951"
          r="9"
          fill="url(#paint0_linear_8627_20831)"
        />
      </g>
      <g opacity="0.1" filter="url(#filter1_d_8627_20831)">
        <circle
          cx="8.99986"
          cy="8.99937"
          r="6.42857"
          fill="url(#paint1_linear_8627_20831)"
        />
      </g>
      <g filter="url(#filter2_d_8627_20831)">
        <circle
          cx="8.99972"
          cy="8.99972"
          r="3.85714"
          fill="url(#paint2_linear_8627_20831)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_8627_20831"
        x="-52"
        y="-40.0005"
        width="122"
        height="122"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="26" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8627_20831"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8627_20831"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_8627_20831"
        x="-49.4287"
        y="-37.4292"
        width="116.857"
        height="116.857"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="26" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8627_20831"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8627_20831"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_8627_20831"
        x="-46.8574"
        y="-34.8574"
        width="111.714"
        height="111.714"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="26" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8627_20831"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8627_20831"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8627_20831"
        x1="2.66484"
        y1="4.34593"
        x2="17.9017"
        y2="7.70892"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008CFF" />
        <stop offset="1" stopColor="#0047FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8627_20831"
        x1="4.47475"
        y1="5.67538"
        x2="15.3582"
        y2="8.07752"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008CFF" />
        <stop offset="1" stopColor="#0047FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8627_20831"
        x1="6.28465"
        y1="7.00533"
        x2="12.8148"
        y2="8.44661"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008CFF" />
        <stop offset="1" stopColor="#0047FF" />
      </linearGradient>
      <clipPath id="clip0_8627_20831">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 -0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);
