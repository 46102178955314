"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSTitle01,
  CSSTitle01Xlarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompHeadlineWithStatisticsCardTitle {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ICompHeadlineWithStatisticsCardTitle>`
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
  ${CSSTitle01};
`;

const Silver = css<ICompHeadlineWithStatisticsCardTitle>``;

const Gold = css<ICompHeadlineWithStatisticsCardTitle>``;

const Platinum = css<ICompHeadlineWithStatisticsCardTitle>``;

const Diamond = css<ICompHeadlineWithStatisticsCardTitle>`
  ${CSSTitle01Xlarge};
`;

export const SCompHeadlineWithStatisticsCardTitle = styled.p<ICompHeadlineWithStatisticsCardTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
