"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompHeadlineWithStatistics {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ICompHeadlineWithStatistics>`
  background: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--ui-01)" : "var(--ui-07)"};
`;

const Silver = css<ICompHeadlineWithStatistics>``;

const Gold = css<ICompHeadlineWithStatistics>``;

const Platinum = css<ICompHeadlineWithStatistics>``;

const Diamond = css<ICompHeadlineWithStatistics>``;

export const SCompHeadlineWithStatistics = styled.div<ICompHeadlineWithStatistics>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
