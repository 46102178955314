"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody03Med,
  CSSBody03MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompPricingHeaderWrapperPricingYearText {
  highlightPricingType?: boolean;
  bgGradient?: string;
}

const Bronze = css<ISCompPricingHeaderWrapperPricingYearText>`
  ${CSSBody03Med};
  color: ${({ bgGradient }) =>
    bgGradient !== "White" ? "var(--text-10)" : "var(--text-04)"};
  position: absolute;
  left: 0;
  top: 0;
  opacity: ${({ highlightPricingType }) => (highlightPricingType ? 0 : 1)};
  transition: opacity 300ms;
`;

const Silver = css<ISCompPricingHeaderWrapperPricingYearText>``;

const Gold = css<ISCompPricingHeaderWrapperPricingYearText>``;

const Platinum = css<ISCompPricingHeaderWrapperPricingYearText>``;

const Diamond = css<ISCompPricingHeaderWrapperPricingYearText>`
  ${CSSBody03MedXLarge};
  font-size: 18px;
`;

export const SCompPricingHeaderWrapperPricingYearText = styled.span<ISCompPricingHeaderWrapperPricingYearText>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
