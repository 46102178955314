"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading03,
  CSSHeading03XLarge,
  CSSTitle01,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-01);
  ${CSSTitle01}
`;

const Silver = css``;

const Gold = css`
  ${CSSHeading03}
`;

const Platinum = css``;

const Diamond = css`
  ${CSSHeading03XLarge}
`;

export const SCompBlogCategoryTeaserMainArticleTitle = styled.h3`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
