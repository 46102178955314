"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading01,
  CSSHeading01Small,
  CSSHeading01XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-02);
  ${CSSHeading01Small}
`;

const Silver = css``;

const Gold = css`
  ${CSSHeading01}
`;

const Platinum = css``;
const Diamond = css`
  ${CSSHeading01XLarge}
`;

export const SCompHeroTitle = styled.h1`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
