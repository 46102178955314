"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css<{
  $active?: boolean;
  $shiftRight?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0 ease-in-out 0s, transform 0.45s ease-in-out;
  opacity: 0;
  overflow: hidden;
  ${({ $shiftRight }) => css`
    transform: translateX(${(!$shiftRight && 8) || -8}%);
  `};

  ${({ $active, $shiftRight }) =>
    $active
      ? css`
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.45s ease-in-out 0s, transform 0.45s ease-in-out;
        `
      : css`
          opacity: 0;
          transform: translateX(${(!$shiftRight && 8) || -8}%);
          transition: opacity 0 ease-in-out 0s, transform 0.45s ease-in-out;
        `};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompMultiImageWithTextHeightVisibleElement = styled.div<{
  $active?: boolean;
  $shiftRight?: boolean;
}>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
