import React from "react";
import { IGenSpacer } from "../../utils/types_gen";
import { SSpacer } from "./styles/SSpacer";

export const Spacer: React.FC<Omit<IGenSpacer, "__typename">> = ({
  bronze,
  gold,
  platinum,
}) => {
  return <SSpacer bronze={bronze} gold={gold} platinum={platinum} />;
};
