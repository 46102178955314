export const CmsAnimatiedSvgItemImgTranslatorTag = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="22" height="22" rx="11" fill="#3665CE" />
    <path
      d="M9.75 8V9.5"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 16L14.25 11.5L12.25 16"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6953 15H15.8053"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.75 13.5C9.7975 13.338 11.588 11.5475 11.7505 9.5H7.7505"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 13.5C10.4705 13.399 9.351 12.2795 9.25 11"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="#0B1527"
      strokeWidth="2"
    />
  </svg>
);
