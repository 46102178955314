"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSHighlight } from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  ${CSSHighlight};
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 4px;
`;

const Silver = css``;

const Gold = css`
  font-size: 16px;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompCardWithBackgroundColorStrap = styled.p`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
