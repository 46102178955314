"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface iRoadmapCardWrapper {
  showDescription: boolean;
}

const Bronze = css<iRoadmapCardWrapper>`
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 23px;
`;

const Silver = css<iRoadmapCardWrapper>``;

const Gold = css<iRoadmapCardWrapper>`
  display: flex;
`;

const Platinum = css<iRoadmapCardWrapper>``;

const Diamond = css<iRoadmapCardWrapper>`
  padding: 31px;
`;

export const SRoadmapColumnCard = styled.div<iRoadmapCardWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
