"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSTitle01,
  CSSTitle01Xlarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-04);
  ${CSSTitle01};

  strong,
  b {
    background-image: var(--blue-gradient);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    text-fill-color: transparent;
    width: fit-content;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${CSSTitle01Xlarge};
`;

export const SCompTextBlock = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
