"use client";
import React, { useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import { IGenFaq, Maybe } from "../../utils/types_gen";
import { CompFaqItem } from "./comp-faq-item/CompFaqItem";
import { SCompFaq } from "./styles/SCompFaq";
import { SCompFaqFaqItemsWrapper } from "./styles/SCompFaqFaqItemsWrapper";
import { SCompFaqHeader } from "./styles/SCompFaqHeader";

export interface ICompFaq extends IGenFaq {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

export const CompFaqInner: React.FC<ICompFaq> = ({
  faqItems,
  id,
  title,
  isSectionBgWhite,
}) => {
  const [activeFaq, setActiveFaq] = useState<string>();

  return (
    <SCompFaq id={id ?? ""}>
      <GridPadding>
        <Grid>
          {title && (
            <GridItem
              bronze={{ start: 1, end: 12 }}
              gold={{ start: 2, end: 11 }}
            >
              <SCompFaqHeader isSectionBgWhite={isSectionBgWhite}>
                {title}
              </SCompFaqHeader>
            </GridItem>
          )}
        </Grid>
        {!!faqItems?.length && (
          <SCompFaqFaqItemsWrapper>
            <Grid rowGap>
              {faqItems.map(
                (item) =>
                  item && (
                    <GridItem
                      key={item.id}
                      bronze={{ start: 1, end: 12 }}
                      gold={{ start: 2, end: 11 }}
                    >
                      <CompFaqItem
                        key={item.id}
                        {...item}
                        isActive={activeFaq === item.id}
                        setActiveFaq={setActiveFaq}
                      />
                    </GridItem>
                  )
              )}
            </Grid>
          </SCompFaqFaqItemsWrapper>
        )}
      </GridPadding>
    </SCompFaq>
  );
};
