"use client";
import styled, { css } from "styled-components";
import { SGridPadding } from "../../../base-components/grid-padding/styles/SGridPadding";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  overflow: hidden;

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: unset;
    height: auto;
  }

  ${SGridPadding} {
    overflow: hidden;
  }

  button {
    height: 100% !important;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompImageSlider = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
