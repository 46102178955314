"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody02,
  CssBody02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompHeadlineWithStatisticsContentDescription {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ICompHeadlineWithStatisticsContentDescription>`
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-04)" : "var(--text-03)"};
  ${CSSBody02};
`;

const Silver = css<ICompHeadlineWithStatisticsContentDescription>``;

const Gold = css<ICompHeadlineWithStatisticsContentDescription>``;

const Platinum = css<ICompHeadlineWithStatisticsContentDescription>``;

const Diamond = css<ICompHeadlineWithStatisticsContentDescription>`
  ${CssBody02XLarge}
`;

export const SCompHeadlineWithStatisticsContentDescription = styled.p<ICompHeadlineWithStatisticsContentDescription>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
