"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSTitle01,
  CSSTitle01Xlarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  ${CSSTitle01}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${CSSTitle01Xlarge}
`;

export const SCompPricingHeaderWrapperCardTitle = styled.h3`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
