"use client";
import styled, { css } from "styled-components";
import {
  MIN_DIAMOND,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_SILVER,
} from "../../../../constants/styles/mediaquerys";
import {
  CSSTitle02,
  CSSTitle02Xlarge,
} from "../../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  position: relative;
  cursor: pointer;
  color: var(--text-01);
  ${CSSTitle02};
  padding: 24px 20px 24px 24px;
  transition: color 300ms;

  :hover {
    color: var(--hover-text-01);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  padding: 32px 24px 32px 32px;
  ${CSSTitle02Xlarge};
`;

export const SCompFaqItemItemHeaderWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
