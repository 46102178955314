"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSHeading01Small } from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  background: var(--violet-gradient);
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  ${CSSHeading01Small};
`;

const Silver = css`
  padding-top: 152px;
  padding-bottom: 120px;
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  padding-top: 200px;
  padding-bottom: 180px;
`;

export const SCompChangelogHero = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
