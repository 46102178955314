export const CmsSvgAnimationItemImgEditorTag = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="22" height="22" rx="11" fill="#FF008A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 16.5H14.5C15.3284 16.5 16 15.8284 16 15V10.1858C16 9.78801 15.842 9.40648 15.5607 9.12517L14.3748 7.93934C14.0935 7.65804 13.712 7.5 13.3142 7.5H9.5C8.67157 7.5 8 8.17157 8 9V15C8 15.8284 8.67157 16.5 9.5 16.5Z"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 10.25H14.25C13.6977 10.25 13.25 9.80228 13.25 9.25V7.5"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="#0B1527"
      strokeWidth="2"
    />
  </svg>
);
