"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { SCompCardWithBackgroundColorText } from "./SCompCardWithBackgroundColorText";

interface ISCompCardWithBackgroundColorTitleAndStrap {
  isIcon: boolean;
  isCentred: boolean;
}

const CSSCentred = css`
  display: flex;
  align-items: center;
`;

const Bronze = css<ISCompCardWithBackgroundColorTitleAndStrap>`
  margin-right: ${({ isIcon }) => (isIcon ? "40px" : "0")};
  ${({ isCentred }) => (isCentred ? CSSCentred : "")};

  ${SCompCardWithBackgroundColorText} {
    display: none;
  }
`;

const Silver = css<ISCompCardWithBackgroundColorTitleAndStrap>``;

const Gold = css<ISCompCardWithBackgroundColorTitleAndStrap>`
  margin-left: ${({ isIcon }) => (isIcon ? "28px" : "0")};
  margin-right: 0;
  padding-top: ${({ isIcon, isCentred }) =>
    isIcon && !isCentred ? "8px" : "0"};

  ${SCompCardWithBackgroundColorText} {
    display: block;
  }
`;

const Platinum = css<ISCompCardWithBackgroundColorTitleAndStrap>``;

const Diamond = css<ISCompCardWithBackgroundColorTitleAndStrap>``;

export const SCompCardWithBackgroundColorTitleAndStrap = styled.div<ISCompCardWithBackgroundColorTitleAndStrap>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
