"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompInfoComponentWithCta {
  bgGradient?: string;
}

const Bronze = css<ISCompInfoComponentWithCta>`
  background: ${({ bgGradient }) =>
    bgGradient ? backgroundGradients[bgGradient] : "var(--violet-gradient)"};
  border-radius: 16px;
  padding: 24px;
  box-shadow: var(--big-elevation);
`;

const Silver = css<ISCompInfoComponentWithCta>``;

const Gold = css<ISCompInfoComponentWithCta>`
  padding: 40px;
`;

const Platinum = css<ISCompInfoComponentWithCta>``;

const Diamond = css<ISCompInfoComponentWithCta>``;

export const SCompInfoComponentWithCta = styled.div<ISCompInfoComponentWithCta>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
