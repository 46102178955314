import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  item1Position,
  item2Position,
  item1SlideIn,
  item2SlideIn,
  item3SlideIn,
  item6SlideIn,
  item4SlideIn,
} from "./SAnimatedSvgItemsKeyframes";

// convert animation positioning from translate to top/left - to dynamically move with changing wrapper width

const Bronze = css<{ itemIndex?: number }>`
  text-align: center;
  width: 10%;
  height: 10%;
  color: #57709c;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  line-height: 16px; /* 145.455% */
  letter-spacing: -0.22px;

  .item-img {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
    span {
      position: absolute;
      left: -5px;
      top: -5px;
      width: 35%;
      height: 35%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .item-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:nth-child(1) {
    opacity: 1;
    top: 49%;
    left: 1%;
    translate: 0px -55%;
    animation: ${item1SlideIn} 1s 500ms forwards,
      ${item1Position} 1s 2.5s forwards;
  }
  &:nth-child(2) {
    opacity: 0;
    top: 49%;
    translate: -0px -58%;
    right: 0;
    animation: ${item2SlideIn} 1s 500ms forwards,
      ${item2Position} 1s 5s forwards;
  }
  &:nth-child(3) {
    opacity: 0;
    bottom: 9%;
    left: 1%;
    translate: 0px -50%;
    animation: ${item3SlideIn} 1s 2.7s forwards;
  }
  &:nth-child(4) {
    opacity: 0;
    bottom: 9%;
    right: 0;
    translate: 0px -50%;
    animation: ${item4SlideIn} 1s 5.3s forwards;
  }
  &:nth-child(5) {
    opacity: 0;
    translate: 0px;
    top: 49%;
    translate: 0 -55%;
    left: 1%;
    animation: ${item6SlideIn} 1s 7.3s forwards;
  }
  &:nth-child(6) {
    opacity: 0;
    top: 49%;
    translate: 0 -55%;
    right: 0;
    animation: ${item6SlideIn} 1s 7.3s forwards;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SAnimatedSvgItem = styled.div<{ itemIndex?: number }>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
