import styled, { css, keyframes } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const SVGCircleRotation = keyframes`
0%{
  transform: rotate(0);
}
100%{
  transform: rotate(360deg);
}
`;
export const SVGLogoFadeIn = keyframes`
0%{
  opacity: 0;
}
100%{
  opacity: 1;
}
`;
export const FadeInSlideIn = keyframes`
0%{
  opacity: 0;
  transform: translateX(50px);
}
100%{
  transform: translateX(0px);
  opacity: 1;
}
`;
const SVGLogoCirclesPulses = keyframes`
0%{
  fill-opacity: 0.05;
  r:65;
}
50%{
  fill-opacity: 0.08;
  r:68;
}
100%{
  fill-opacity: 0.05;
  r:65;
}
`;

const Bronze = css`
  background-color: transparent;
  width: 100%;
  opacity: 0;
  border-radius: 10px;
  animation: ${SVGLogoFadeIn} 1s forwards;
  /* aspect-ratio: 1; */
  .svg {
    opacity: 0;
    animation: ${SVGLogoFadeIn} 1s forwards 1s;

    &.visible {
      opacity: 0;
      animation: ${SVGLogoFadeIn} 1s forwards;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 3;

      .items {
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        /* aspect-ratio: 1; */
        display: grid;
        grid-template-columns: 60px 60px;
        align-items: center;
        justify-content: space-between;
        /* overflow: hidden; */
        padding: 0 5px;
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 35%;
        height: 35%;
        transform: translate(-50%, -50%);
        opacity: 0;
        z-index: 3;
        animation: ${SVGLogoFadeIn} 900ms linear forwards;
        .logoCricles {
          animation: ${SVGLogoCirclesPulses} 2s linear infinite;
        }
      }

      .dots {
        position: relative;
        aspect-ratio: 1;
        width: 75%;
        height: 75%;
        top: 0%;
        left: 50%;
        translate: -50% -0%;
        animation: ${SVGCircleRotation} 100s linear infinite;
      }
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SAnimatedSvg = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
