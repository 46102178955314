import { FC } from "react";
import { IGenBlogArticleHeighlight } from "../../utils/types_gen";
import { SCompBlogArticleHighlight } from "./styles/SCompBlogArticleHighlight";
import { NextImage } from "../next-image/NextImage";
import { SCompBlogArticleHighlightContainer } from "./styles/SCompBlogArticleHighlightContainer";
import { SCompBlogArticleHighlightImageWrapper } from "./styles/SCompBlogArticleHighlightImageWrapper";
import { SCompBlogArticleHighlightInfo } from "./styles/SCompBlogArticleHighlightInfo";
import { SCompBlogArticleHighlightInfoTag } from "./styles/SCompBlogArticleHighlightInfoTag";
import { SCompBlogArticleHighlightInfoTagAndTitle } from "./styles/SCompBlogArticleHighlightInfoTagAndTitle";
import { SCompBlogArticleHighlightInfoTitle } from "./styles/SCompBlogArticleHighlightInfoTitle";
import { SCompBlogArticleHighlightInfoDescription } from "./styles/SCompBlogArticleHighlightInfoDescription";
import { Button } from "../button/Button";
import { SCompBlogArticleHighlightImageWrapperSmall } from "./styles/SCompBlogArticleHighlightImageWrapperSmall";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { RichText } from "../../base-components/rich-text/RichText";
import Link from "next/link";

export interface ICompBlogArticleHighlight extends IGenBlogArticleHeighlight {
  isSectionBgWhite: boolean;
}

export const CompBlogArticleHighlight: FC<ICompBlogArticleHighlight> = ({
  article,
}) => {
  return (
    <SCompBlogArticleHighlight>
      <GridPadding>
        <Link href={`/blog/${article.slug}`}>
          <SCompBlogArticleHighlightContainer>
            <SCompBlogArticleHighlightImageWrapper>
              {article.featuredImage && (
                <NextImage
                  src={article.featuredImage.src}
                  alt={article.featuredImage.description}
                  fill
                  style={{ objectFit: "cover" }}
                  loading="lazy"
                />
              )}
            </SCompBlogArticleHighlightImageWrapper>
            <SCompBlogArticleHighlightInfo>
              <SCompBlogArticleHighlightInfoTagAndTitle>
                <SCompBlogArticleHighlightInfoTag>
                  {article?.tags?.[0]?.name}
                </SCompBlogArticleHighlightInfoTag>
                <SCompBlogArticleHighlightInfoTitle>
                  {article?.featuredText}
                </SCompBlogArticleHighlightInfoTitle>
              </SCompBlogArticleHighlightInfoTagAndTitle>
              <SCompBlogArticleHighlightImageWrapperSmall>
                {article.featuredImage && (
                  <NextImage
                    src={article.featuredImage.src}
                    alt={article.featuredImage.description}
                    fill
                    style={{ objectFit: "cover" }}
                    loading="lazy"
                  />
                )}
              </SCompBlogArticleHighlightImageWrapperSmall>
              <SCompBlogArticleHighlightInfoDescription>
                {article?.featuredDescription && (
                  <RichText
                    content={article.featuredDescription?.json.content}
                  />
                )}
              </SCompBlogArticleHighlightInfoDescription>
              <Button>Go to article</Button>
            </SCompBlogArticleHighlightInfo>
          </SCompBlogArticleHighlightContainer>
        </Link>
      </GridPadding>
    </SCompBlogArticleHighlight>
  );
};
