"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompChangelogReleasesImage {
  moreThanOne?: boolean | undefined | null;
}

const Bronze = css<ISCompChangelogReleasesImage>`
  position: relative;
  width: 100%;
  padding-top: ${({ moreThanOne }) => (moreThanOne ? "91.31%" : "106.42%")};
`;

const Silver = css<ISCompChangelogReleasesImage>``;

const Gold = css<ISCompChangelogReleasesImage>`
  padding-top: ${({ moreThanOne }) => (moreThanOne ? "69.14%" : "44.84%")};
`;

const Platinum = css<ISCompChangelogReleasesImage>``;

const Diamond = css<ISCompChangelogReleasesImage>`
  padding-top: ${({ moreThanOne }) => (moreThanOne ? "68.41%" : "40.34%")};
`;

export const SCompChangelogReleasesImage = styled.div<ISCompChangelogReleasesImage>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
