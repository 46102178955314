"use client";
import styled, { css } from "styled-components";
import { SGrid } from "../../../base-components/grid/styles/SGrid";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  margin-bottom: 24px;
  box-shadow: var(--big-elevasion);
  &:last-child {
    margin-bottom: 0;
  }
  &:nth-of-type(even) {
    ${SGrid} {
      direction: rtl;
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompQuote = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
