"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  svgFirstStopsVariables,
  svgSecondStopsVariables,
} from "../../../svgs/GradientCheckmarkIcon";

interface ISCompTableCheckmarkWrapper {
  gradientAccent?: string;
}

const Bronze = css<ISCompTableCheckmarkWrapper>`
  line-height: 0;

  .stop-color-1 {
    color: ${({ gradientAccent }) =>
      svgFirstStopsVariables[gradientAccent || "blue"]};
  }

  .stop-color-2 {
    color: ${({ gradientAccent }) =>
      svgSecondStopsVariables[gradientAccent || "blue"]};
  }

  .stroke {
    color: var(--icon-02);
  }
`;

const Silver = css<ISCompTableCheckmarkWrapper>``;

const Gold = css<ISCompTableCheckmarkWrapper>``;

const Platinum = css<ISCompTableCheckmarkWrapper>``;

const Diamond = css<ISCompTableCheckmarkWrapper>``;

export const SCompTableCheckmarkWrapper = styled.div<ISCompTableCheckmarkWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
