"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading02,
  CSSHeading02Small,
  CSSHeading02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ICompImageTextBlockTitle {
  $isSectionBgWhite: boolean;
}

const Bronze = css<ICompImageTextBlockTitle>`
  ${CSSHeading02Small};
  width: 100%;
  color: ${({ $isSectionBgWhite }) =>
    $isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};
`;

const Silver = css<ICompImageTextBlockTitle>``;

const Gold = css<ICompImageTextBlockTitle>`
  width: auto;
  ${CSSHeading02};
`;

const Platinum = css<ICompImageTextBlockTitle>``;

const Diamond = css<ICompImageTextBlockTitle>`
  ${CSSHeading02XLarge};
`;

export const SCompImageTextBlockTitle = styled.h3<ICompImageTextBlockTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
