import Link from "next/link";
import { FC, Fragment } from "react";
import { SOnboardingTemplatesTemplate } from "./styles/SOnboardingTemplatesTemplate";
import { SOnboardingTemplatesTemplateContent } from "./styles/SOnboardingTemplatesTemplateContent";
import { SOnboardingTemplatesTemplateDescription } from "./styles/SOnboardingTemplatesTemplateDescription";
import { SOnboardingTemplatesTemplateFooter } from "./styles/SOnboardingTemplatesTemplateFooter";
import { SOnboardingTemplatesTemplateImage } from "./styles/SOnboardingTemplatesTemplateImage";
import { SOnboardingTemplatesTemplateName } from "./styles/SOnboardingTemplatesTemplateName";
import { SOnboardingTemplatesTemplateStack } from "./styles/SOnboardingTemplatesTemplateStack";
import { SOnboardingTemplatesTemplateStackIcon } from "./styles/SOnboardingTemplatesTemplateStackIcon";
import { SOnboardingTemplatesTemplateStackSeparator } from "./styles/SOnboardingTemplatesTemplateStackSeparator";
import { NextImage } from "../next-image/NextImage";

interface IOnboardingTemplatesTemplate {
  template: any;
}

export const OnboardingTemplatesTemplate: FC<IOnboardingTemplatesTemplate> = ({
  template,
}) => {
  return (
    <Link href={template?.slug ? `/starter-templates/${template.slug}` : "#"}>
      <SOnboardingTemplatesTemplate>
        <SOnboardingTemplatesTemplateImage>
          <NextImage
            src={template.previewImage.src}
            width={template.previewImage.width ?? 1920}
            height={template.previewImage.height ?? 1080}
            alt={template.previewImage.title ?? ""}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </SOnboardingTemplatesTemplateImage>
        <SOnboardingTemplatesTemplateContent>
          <SOnboardingTemplatesTemplateName>
            {template.name}
          </SOnboardingTemplatesTemplateName>
          <SOnboardingTemplatesTemplateDescription>
            {template.shortDescription}
          </SOnboardingTemplatesTemplateDescription>
        </SOnboardingTemplatesTemplateContent>
        <SOnboardingTemplatesTemplateFooter>
          {template.stack.length > 0 ? (
            <SOnboardingTemplatesTemplateStack>
              {template.stack.map(({ icon, id }, index) => (
                <Fragment key={id}>
                  <SOnboardingTemplatesTemplateStackIcon>
                    <NextImage
                      alt={icon.title}
                      src={icon.src}
                      width={icon.width ?? 100}
                      height={icon.height ?? 100}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </SOnboardingTemplatesTemplateStackIcon>
                  {index !== template.stack.length - 1 ? (
                    <SOnboardingTemplatesTemplateStackSeparator />
                  ) : null}
                </Fragment>
              ))}
            </SOnboardingTemplatesTemplateStack>
          ) : null}
        </SOnboardingTemplatesTemplateFooter>
      </SOnboardingTemplatesTemplate>
    </Link>
  );
};
