"use client";
import styled from "styled-components";

export const SLazyImagePlaceholder: any = styled.div.attrs<any>(
  (props: { blurHashCss?: string; isLoading?: boolean }) => ({
    style: {
      background: props.blurHashCss || "",
      opacity: props.isLoading ? 1 : 0,
    },
  })
)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  inset: 0;
  opacity: 1;
  transition: 0.2s opacity ease-in;
  background: var(--ui-03);
`;
