import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const CSSSmall = css`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CSSBig = css`
  display: none;
`;

const Bronze = css<{ small?: boolean; big?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;

  ${({ small }) => small && CSSSmall}
  ${({ big }) => big && CSSBig}
`;

const Silver = css<{ small?: boolean; big?: boolean }>`
  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;

  ${({ small }) => small && "display: none;"}
  ${({ big }) => big && "display: flex;"}
`;

const Gold = css`
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompTableCellRichIcon = styled.div<{
  small?: boolean;
  big?: boolean;
}>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
