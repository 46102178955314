"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { SCompCardWithBackgroundColorImgCont } from "./SCompCardWithBackgroundColorImgCont";
import { SCompCardWithBackgroundColorText } from "./SCompCardWithBackgroundColorText";

interface ISCompCardWithBackgroundColor {
  bgGradient?: string;
  text?: string;
}

const Bronze = css<ISCompCardWithBackgroundColor>`
  border-radius: 12px;
  padding: 24px;
  background: ${({ bgGradient }) =>
    bgGradient ? backgroundGradients[bgGradient] : "var(--blue-gradient)"};

  & > ${SCompCardWithBackgroundColorImgCont} {
    display: none;
  }
`;

const Silver = css<ISCompCardWithBackgroundColor>``;

const Gold = css<ISCompCardWithBackgroundColor>`
  padding: 32px;
  display: flex;

  & > ${SCompCardWithBackgroundColorText} {
    display: none;
  }

  & > ${SCompCardWithBackgroundColorImgCont} {
    display: flex;
  }
`;

const Platinum = css<ISCompCardWithBackgroundColor>``;

const Diamond = css<ISCompCardWithBackgroundColor>`
  align-items: ${({ text }) =>
    text === null || text?.length === 0 ? "center" : ""};
  padding: 44px;
`;

export const SCompCardWithBackgroundColor = styled.div<ISCompCardWithBackgroundColor>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
