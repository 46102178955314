"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody02Semi,
  CSSBody03,
  CSSTitle02,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompChangeLogDescription {
  hasImages: boolean;
}

const Bronze = css<ISCompChangeLogDescription>`
  margin-top: ${({ hasImages }) => (hasImages ? "48px" : 0)};
  color: var(--text-01);
  max-width: 776px;
  h1,
  h2 {
    ${CSSTitle02};
    margin-bottom: 1.5rem;
  }

  h3,
  h4 {
    ${CSSBody02Semi};
    margin-bottom: 1rem;
  }

  p,
  li {
    ${CSSBody03};
    letter-spacing: -0.01em;
    margin-bottom: 0.5rem;
  }

  ul,
  ol {
    margin-bottom: 1.5rem;
  }

  li {
    list-style: disc;

    ::marker {
      color: #172c55;
    }
  }
`;

const Silver = css<ISCompChangeLogDescription>``;

const Gold = css<ISCompChangeLogDescription>``;

const Platinum = css<ISCompChangeLogDescription>``;

const Diamond = css<ISCompChangeLogDescription>``;

export const SCompChangelogReleasesText = styled.div<ISCompChangeLogDescription>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
