import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import React, { FC, PropsWithChildren } from "react";

const Paragraph: FC<PropsWithChildren> = ({ children }) => {
  return children ? <p>{children}</p> : <br />;
};

export interface IRichText {
  overwrites?: any;
  content?: any;
  json?: any;
  connections?: any;
  connectionRenderer?: any;
}

export const attrsToStyle = (attrs: any) => {
  const style: any = {};
  if (attrs && attrs.textAlign) {
    style.textAlign = attrs.textAlign;
  }

  if (Object.keys(style).length == 0) {
    return undefined;
  }

  return style;
};

const Heading: FC<{ node: any; path: string } & PropsWithChildren> = ({
  node,
  children,
  path,
}) => {
  const attrs = node.attrs;
  const level = node.attrs.level;

  const textContent = children?.[0]?.props?.node?.text;
  const safeText = textContent?.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
  const id = `${safeText}-${path}`;

  switch (level) {
    case 1:
      return (
        <section id={id} className="article-section">
          <h1 style={attrsToStyle(attrs)}>{children}</h1>
        </section>
      );
    case 2:
      return (
        <section id={id} className="article-section">
          <h2 style={attrsToStyle(attrs)}>{children}</h2>
        </section>
      );
    case 3:
      return <h3 style={attrsToStyle(attrs)}>{children}</h3>;
    case 4:
      return <h4 style={attrsToStyle(attrs)}>{children}</h4>;
    case 5:
      return <h5 style={attrsToStyle(attrs)}>{children}</h5>;
    case 6:
      return <h6 style={attrsToStyle(attrs)}>{children}</h6>;
  }

  return <></>;
};

export const RichText = ({
  content,
  json,
  connections,
  connectionRenderer,
  overwrites,
}: IRichText) => {
  return (
    <>
      <RichTextRenderer
        node={{ type: "doc", content: content || json?.content }}
        overwrites={{
          paragraph: Paragraph,
          heading: Heading,
          documentLink: (props) => {
            return connectionRenderer && connections
              ? connectionRenderer(
                  connections.find(
                    (c) => c?.id === props?.node?.attrs?.documentId
                  ) ?? null
                )
              : null;
          },
          ...overwrites,
        }}
      />
    </>
  );
};
