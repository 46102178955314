"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody02Semi,
  CSSBody02SemiXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ICompPricingHeaderWrapperPricingYear {
  highlightPricingType?: boolean;
  bgGradient?: string;
}

const Bronze = css<ICompPricingHeaderWrapperPricingYear>`
  ${CSSBody02Semi};
  color: ${({ bgGradient }) =>
    bgGradient !== "white" ? "var(--text-02)" : "var(--text-01)"};
  position: relative;

  span:first-of-type {
    opacity: ${({ highlightPricingType }) => (highlightPricingType ? 1 : 0)};
    transition: opacity 300ms;
  }
`;

const Silver = css<ICompPricingHeaderWrapperPricingYear>``;

const Gold = css<ICompPricingHeaderWrapperPricingYear>``;

const Platinum = css<ICompPricingHeaderWrapperPricingYear>``;

const Diamond = css<ICompPricingHeaderWrapperPricingYear>`
  ${CSSBody02SemiXLarge};
`;

export const SCompPricingHeaderWrapperPricingYear = styled.p<ICompPricingHeaderWrapperPricingYear>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
