import React from "react";
import { SResponseDisplayDiamond } from "./styles/SResponseDisplayDiamond";
import { SResponseDisplayGold } from "./styles/SResponseDisplayGold";
import { SResponseDisplayBronze } from "./styles/SResponseDisplayBronze";

export const ResponseDisplay: React.FC<{
  bronze: any;
  gold: any;
  diamond: any;
}> = ({ bronze, gold, diamond }) => {
  return (
    <>
      <SResponseDisplayBronze>{bronze}</SResponseDisplayBronze>
      <SResponseDisplayGold>{gold}</SResponseDisplayGold>
      <SResponseDisplayDiamond>{diamond}</SResponseDisplayDiamond>
    </>
  );
};
