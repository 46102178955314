"use client";
import { createGlobalStyle } from "styled-components";
import { CSSBoxShadows } from "./boxShadows/CSSBoxShadows";
import { CSSColors } from "./colours/CSSColors";
import { CSSReset } from "./css-reset";
import { CSSScrollbars } from "./css-scrollbars";

export const GSBase = createGlobalStyle`
  ${CSSReset};
  ${CSSScrollbars};
  ${CSSBoxShadows};
  ${CSSColors}

  body {
    /* font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  }
  
  .lazyload-wrapper {
    height: inherit;
    width: inherit;
    max-height: inherit;
  }
  
  a{
    color: inherit;
  }
  
  img{
    pointer-events: none;
  }
  
  ol {
     li {
      list-style-type: decimal;
      margin-left: 1rem;
     }
  }
  ul { 
    li {
      list-style-type: circle;
      margin-left: 1rem;
    }
  }
  strong, b {
    font-weight: bold;
  }
`;
