"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ITableCellTitle {
  colorChange?: boolean;
  bgGradient?: boolean;
}

const Bronze = css<ITableCellTitle>`
  color: ${({ colorChange, bgGradient }) =>
    colorChange
      ? bgGradient
        ? "var(--text-02)"
        : "var(--text-01)"
      : "var(--text-03)"};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
`;
const Silver = css<ITableCellTitle>``;

const Gold = css<ITableCellTitle>`
  padding-left: 0;
`;

const Platinum = css<ITableCellTitle>``;

const Diamond = css<ITableCellTitle>``;

export const SCompTableCellTitle = styled.h4<ITableCellTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
