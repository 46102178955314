"use client";
import { FC } from "react";
import { IGenBlogReferenceGrid } from "../../utils/types_gen";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { SCompBlogReferenceGrid } from "./styles/SCompBlogReferenceGrid";
import { SCompBlogReferenceGridItem } from "./styles/SCompBlogReferenceGridItem";
import { SCompBlogReferenceGridItemImageWrapper } from "./styles/SCompBlogReferenceGridItemImageWrapper";
import { NextImage } from "../next-image/NextImage";
import { RichText } from "../../base-components/rich-text/RichText";
import { SCompBlogReferenceGridItemContent } from "./styles/SCompBlogReferenceGridItemContent";
import { SCompBlogReferenceGridItemTitleAndDesc } from "./styles/SCompBlogReferenceGridItemTitleAndDesc";
import { SCompBlogReferenceGridItemTitle } from "./styles/SCompBlogReferenceGridItemTitle";
import { SCompBlogReferenceGridItemDescription } from "./styles/SCompBlogReferenceGridItemDescription";
import Link from "next/link";
import { CompBlogArticleHighlight } from "../comp-blog-article-highlight/CompBlogArticleHighlight";
import { SCompBlogReferenceGridContainer } from "../comp-blog-category-teaser/styles/SCompBlogReferenceGridContainer";

export interface ICompBlogReferenceGrid extends IGenBlogReferenceGrid {
  isSectionBgWhite: boolean;
}

export const CompBlogReferenceGrid: FC<ICompBlogReferenceGrid> = ({
  isSectionBgWhite,
  blogArticles,
}) => {
  return (
    <SCompBlogReferenceGridContainer>
      <CompBlogArticleHighlight
        article={blogArticles[0]}
        __typename="BlogArticleHeighlight"
        isSectionBgWhite={isSectionBgWhite}
      />
      {blogArticles.length > 1 && (
        <GridPadding>
          <SCompBlogReferenceGrid>
            {blogArticles.slice(1).map((article) => (
              <Link key={article.id} href={`/blog/${article.slug}`}>
                <SCompBlogReferenceGridItem>
                  <SCompBlogReferenceGridItemImageWrapper>
                    <NextImage
                      src={article?.featuredImage?.src}
                      alt={
                        article?.featuredImage?.description ||
                        article?.featuredImage?.title ||
                        ""
                      }
                      fill
                      style={{ objectFit: "cover" }}
                      loading="lazy"
                    />
                  </SCompBlogReferenceGridItemImageWrapper>
                  <SCompBlogReferenceGridItemContent>
                    <SCompBlogReferenceGridItemTitleAndDesc>
                      {article.title && (
                        <SCompBlogReferenceGridItemTitle
                          $isSectionBgWhite={isSectionBgWhite}
                        >
                          {article.title}
                        </SCompBlogReferenceGridItemTitle>
                      )}
                      {!!article.featuredDescription?.json?.content?.length && (
                        <SCompBlogReferenceGridItemDescription>
                          <RichText
                            content={article.featuredDescription?.json.content}
                          />
                        </SCompBlogReferenceGridItemDescription>
                      )}
                    </SCompBlogReferenceGridItemTitleAndDesc>
                  </SCompBlogReferenceGridItemContent>
                </SCompBlogReferenceGridItem>
              </Link>
            ))}
          </SCompBlogReferenceGrid>
        </GridPadding>
      )}
    </SCompBlogReferenceGridContainer>
  );
};
