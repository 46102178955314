import React from "react";
import { SBarButton } from "./styles/SButtonsBar";
import { IGenSliderElement } from "../../utils/types_gen";
// import { IGenSliderElement } from "../../utils/types_gen";
// import { Button } from "../button/Button";

export const BarButton: React.FC<{
  styleType?: string;
  element?: Omit<IGenSliderElement, "__typename">;
  click?: () => void;
}> = ({ styleType, element, click }) => {
  // if (typeof window !== "undefined") {
  //   console.log(`ButtonsBar props: `, props);
  // }
  return (
    <SBarButton onClick={click} styleType={styleType}>
      {element?.headline}
    </SBarButton>
  );
};
