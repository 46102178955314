import React, { PropsWithChildren } from "react";
import { SCompSectionHeadline } from "./styles/SCompSectionHeadline";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { Grid } from "../../base-components/grid/Grid";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompSectionHeadline {
  isSectionBgWhite?: Maybe<boolean>;
}

export const CompSectionHeadline: React.FC<
  ICompSectionHeadline & PropsWithChildren
> = ({ isSectionBgWhite, children }) => {
  return (
    <SCompSectionHeadline $isSectionBgWhite={isSectionBgWhite}>
      <Grid>
        <GridItem
          bronze={{ start: 1, end: 12 }}
          gold={{
            col: { start: 2, end: 11 },
            row: { start: 1, end: 1 },
          }}
        >
          <h2>{children}</h2>
        </GridItem>
      </Grid>
    </SCompSectionHeadline>
  );
};
