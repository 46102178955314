"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody03,
  CSSBody03XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISCompBulletPointText {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ISCompBulletPointText>`
  ${CSSBody03};
  color: var(--text-04);
  /* color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-04)" : "var(--text-02)"}; */
`;

const Silver = css<ISCompBulletPointText>``;

const Gold = css<ISCompBulletPointText>``;

const Platinum = css<ISCompBulletPointText>``;

const Diamond = css<ISCompBulletPointText>`
  ${CSSBody03XLarge};
`;

export const SCompBulletPointText = styled.div<ISCompBulletPointText>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
