"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface ISCompFeatureCardItemImageWrapper {
  isSwiper?: boolean | undefined | null;
}

const Bronze = css<ISCompFeatureCardItemImageWrapper>`
  position: relative;
  margin-bottom: 32px;
  border-radius: 12px;
  overflow: hidden;
  padding-top: ${({ isSwiper }) => (isSwiper ? "81.35%" : " 73.39%")};

  box-shadow: var(--big-elevation);
`;

const Silver = css<ISCompFeatureCardItemImageWrapper>``;

const Gold = css<ISCompFeatureCardItemImageWrapper>`
  padding-top: 63.82%;
`;

const Platinum = css<ISCompFeatureCardItemImageWrapper>``;

const Diamond = css<ISCompFeatureCardItemImageWrapper>`
  padding-top: ${({ isSwiper }) => (isSwiper ? "57.97%" : "55.81%")};
`;

export const SCompFeatureCardItemImageWrapper = styled.div<ISCompFeatureCardItemImageWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
