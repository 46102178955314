"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompWideVideoOrImageCompLoader {
  dominantColor?: string;
}

const Bronze = css<ISCompWideVideoOrImageCompLoader>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ dominantColor }) => dominantColor && dominantColor};
  border-radius: 12px;
`;

const Silver = css<ISCompWideVideoOrImageCompLoader>``;

const Gold = css<ISCompWideVideoOrImageCompLoader>``;

const Platinum = css<ISCompWideVideoOrImageCompLoader>``;

const Diamond = css<ISCompWideVideoOrImageCompLoader>``;

export const SCompWideVideoOrImageCompLoader = styled.div<ISCompWideVideoOrImageCompLoader>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
