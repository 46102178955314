"use client";
import React, { FC, useEffect, useRef, useState } from "react";
import { SLazyImageLoadingWrapper } from "./styles/SLazyImageLoadingWrapper";
import { SLazyImage } from "./styles/SLazyImage";
import { SLazyImagePlaceholder } from "./styles/SLazyImagePlaceholder";
import { useInView } from "../../../hooks/useInView";
import { BarButton } from "../../buttons-bar/ButtonsBar";
import { SImageAnimationWrapper } from "./styles/SImageAnimationWrapper";
import { SButtonBarWrapper } from "../../buttons-bar/styles/SButtonBarWrapper";
import { BrowserWindowImageFrame } from "../../browser-window-image-frame/BrowserWindowImageFrame";
import { SLazyImagePlaceholderNativeFake } from "./styles/SLazyImagePlaceholderNativeFake";

// "blurHash": "UyK,scIUbIWB~VRjbHjZ9aozj?kCD%X9oea}",
// "description": "a girl on a mountain watching the sunset",
// "dominantColor": "#9f825f",
// "height": 3264,
// "id": "e438f60f-c0c0-41ec-a181-3e1900593438",
// "title": "pexels-harrison-candlin-2441461",
// "width": 2611
const ImageDemo = ({ src, description, height, width, variant }) => {
  const [fetchState, setFetchState] = useState<number>(0);
  const [fakeDelayPassed, setFakeDelayPassed] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inView = useInView(containerRef);
  const [currentDelayState, setCurrentDelayState] = useState<number>(0);

  const blurHashCss =
    variant == "blurhash"
      ? `radial-gradient(at 0 0,#93825c,#00000000 50%), radial-gradient(at 33% 0,#9f835c,#00000000 50%), radial-gradient(at 67% 0,#af8b67,#00000000 50%), radial-gradient(at 100% 0,#c3a17e,#00000000 50%), radial-gradient(at 0 50%,#e1bc9c,#00000000 50%), radial-gradient(at 33% 50%,#ecceaf,#00000000 50%), radial-gradient(at 67% 50%,#f9dec0,#00000000 50%), radial-gradient(at 100% 50%,#ffead4,#00000000 50%), radial-gradient(at 0 100%,#560000,#00000000 50%), radial-gradient(at 33% 100%,#6f4000,#00000000 50%), radial-gradient(at 67% 100%,#7f5100,#00000000 50%), radial-gradient(at 100% 100%,#936c32,#00000000 50%)`
      : variant == "color"
      ? "#9f825f"
      : "none";

  const onLoad = () => {
    if (fetchState === 0) {
      setFetchState(1);
    }
  };

  useEffect(() => {
    let d1, d2, d3;

    if (fetchState == 1 && fakeDelayPassed) {
      d1 = setTimeout(() => {
        setCurrentDelayState(33);
      }, 0);
      d2 = setTimeout(() => {
        setCurrentDelayState(66);
      }, 150);
      d3 = setTimeout(() => {
        setCurrentDelayState(100);
      }, 400);
    }

    return () => {
      d1 && clearTimeout(d1);
      d2 && clearTimeout(d2);
      d3 && clearTimeout(d3);
    };
  }, [fetchState, fakeDelayPassed]);

  useEffect(() => {
    if (!inView) {
      return;
    }

    const x = setTimeout(() => {
      setFakeDelayPassed(true);
    }, 400);

    return () => {
      x && clearTimeout(x);
    };
  }, [inView]);

  return (
    <SLazyImageLoadingWrapper aspectRatio={height / width} ref={containerRef}>
      {(fakeDelayPassed || fetchState !== 0) && (
        <SLazyImage
          onLoad={onLoad}
          src={src + `?width=${1000}`}
          alt={description}
        />
      )}
      {variant != "none" && (
        <SLazyImagePlaceholder
          isLoading={fetchState === 0}
          blurHashCss={blurHashCss}
        ></SLazyImagePlaceholder>
      )}
      {variant == "none" && (
        <SLazyImagePlaceholderNativeFake
          clipPath={`inset(${currentDelayState}% 0 0 0)`}
        ></SLazyImagePlaceholderNativeFake>
      )}
    </SLazyImageLoadingWrapper>
  );
};

export const ImageAnimation: FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const height = 1080;
  const width = 1920;
  const [actionButtons] = useState([
    { headline: "Blurhash" },
    { headline: "Avarage Color" },
    { headline: "Without" },
  ]);
  const description = "a girl on a mountain watching the sunset";
  const src =
    "https://assets.caisy.io/assets/24f83c2f-4d07-4666-84ca-420f9ab91dec/e438f60f-c0c0-41ec-a181-3e1900593438/5dea2f9c-ac89-4295-8e69-b0174d516aecpexelsharrisoncandlin2441461.jpg?width=1920&height=1080";
  return (
    <SImageAnimationWrapper>
      <>
        <SButtonBarWrapper>
          {actionButtons?.map((actionButton, index) => (
            <BarButton
              key={index}
              styleType={activeSlide === index ? "primary" : "secondary"}
              element={actionButton}
              click={() => setActiveSlide(index)}
            />
          )) ?? null}
        </SButtonBarWrapper>
        <div>
          <div className="frame">
            <BrowserWindowImageFrame withFrame={true}>
              {activeSlide == 0 || activeSlide == -1 ? (
                <ImageDemo
                  height={height}
                  width={width}
                  src={src}
                  description={description}
                  variant="blurhash"
                />
              ) : null}
              {activeSlide == 1 ? (
                <ImageDemo
                  height={height}
                  width={width}
                  src={src}
                  description={description}
                  variant="color"
                />
              ) : null}
              {activeSlide == 2 ? (
                <ImageDemo
                  height={height}
                  width={width}
                  src={src}
                  description={description}
                  variant="none"
                />
              ) : null}
            </BrowserWindowImageFrame>
          </div>
        </div>
      </>
    </SImageAnimationWrapper>
  );
};
