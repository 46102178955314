"use client";

import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const CSSWithFrame = css`
  .body {
    padding: 24px;
    background-color: #eaeced;
    > * {
      overflow: hidden;
      border-radius: 12px;
    }
  }
`;

const Bronze = css<{ withFrame?: boolean }>`
  width: 100%;
  .browser {
    background-color: #505050;
    border-radius: 12px 12px 0 0;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 3px;
    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.15;
      &:first-child {
        margin-left: 12px;
      }
    }
  }
  .body {
    overflow: hidden;
    border-radius: 0 0 12px 12px;
  }
  ${({ withFrame }) => (withFrame ? CSSWithFrame : "")};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SBrowserWindowImageFrame = styled.div<{ withFrame?: boolean }>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
