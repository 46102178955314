"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSTitle01,
  CSSTitle01Xlarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  text-align: left;
  color: var(--text-01);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  :first-child {
    display: none;
  }
`;

const Silver = css`
  :first-child {
    display: block;
  }
`;

const Gold = css`
  ${CSSTitle01};
`;

const Platinum = css``;

const Diamond = css`
  ${CSSTitle01Xlarge};
`;

export const SCompTableHeader = styled.th`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
