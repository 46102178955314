"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  position: relative;
  border-radius: 12px;
  overflow: visible;
  box-shadow: var(--big-elevation);
  /* height: 0; */
  /* padding-top: 116.5%; */
  margin-bottom: 40px;
`;

const Silver = css``;

const Gold = css`
  margin-bottom: 0;
  /* padding-top: 105%; */
`;

const Platinum = css``;

const Diamond = css`
  /* padding-top: 92.94%; */
`;

export const SCompImageBulletPointsImage = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
