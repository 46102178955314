"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading02,
  CSSHeading02Small,
  CSSHeading02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  ${CSSHeading02Small};
  background-image: var(--blue-gradient);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  width: fit-content;
  margin-top: 24px;
`;

const Silver = css``;

const Gold = css`
  ${CSSHeading02};
  margin-top: 80px;
`;

const Platinum = css``;

const Diamond = css`
  ${CSSHeading02XLarge};
  margin-bottom: 110px;
`;

export const SCompCardsWithTextSubHeadline = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
