"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  position: relative;
  background: #111d34;
  border-radius: 12px;
  padding: 44px 24px 24px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  padding-bottom: 30px;
`;

export const SCompCardWithIcon = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
