"use client";
import styled, { css } from "styled-components";
import { SGridPadding } from "../../../base-components/grid-padding/styles/SGridPadding";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  height: 72px;

  ${SGridPadding} {
    height: 100%;
  }

  .swiper-slide {
    height: fit-content;
  }

  .swiper {
    height: 100%;
    margin: 0 -24px;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      background: linear-gradient(270deg, #0b1527 0%, rgba(11, 21, 39, 0) 100%);
      width: 200px;
      z-index: 2;
      right: 0;
      top: 0;
    }
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      background: linear-gradient(90deg, #0b1527 0%, rgba(11, 21, 39, 0) 100%);
      width: 200px;
      z-index: 2;
    }
  }

  .swiper-wrapper {
    align-items: center;
    height: 100%;
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
`;

const Silver = css``;

const Gold = css`
  height: 114px;

  .swiper {
    margin: 0 -52px;
  }
`;

const Platinum = css``;

const Diamond = css`
  height: 172px;

  .swiper {
    margin: 0 -76px;
  }
`;

export const SCompLogoBand = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
