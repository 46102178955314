"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading03,
  CSSHeading03Small,
  CSSHeading03XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-02);
  margin-bottom: 24px;
  ${CSSHeading03Small};
  position: relative;
  > p {
    display: inline;
  }
`;

const Silver = css``;

const Gold = css`
  margin-bottom: 40px;
  ${CSSHeading03}
`;

const Platinum = css``;

const Diamond = css`
  ${CSSHeading03XLarge}
`;

export const SCompQuoteQuoteParagraph = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
