export const BottomRightLine = () => (
  <svg
    id="bottom-right"
    width="195"
    height="103"
    viewBox="0 0 195 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="bottom-right-path"
      d="M-9.65596e-06 0.999951L34.5378 0.999983C48.3449 0.999995 59.5378 12.1929 59.5378 26L59.5378 77C59.5378 90.8071 70.7307 102 84.5378 102L195 102"
      stroke="url(#paint0_linear_7928_195527)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7928_195527"
        x1="142.011"
        y1="50.6293"
        x2="-38.1527"
        y2="50.6293"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.288449" stopColor="#33425F" />
        <stop offset="1" stopColor="#008CFF" />
      </linearGradient>
    </defs>
  </svg>
);
