import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  draw,
  undraw,
  middleLeftDotPath,
  topLeftDotPath,
  middleRightDotPath,
  bottomLeftDotPath,
  topRightDotPath,
  bottomRightDotPath,
} from "./SAnimatedSvgItemsKeyframes";

const Bronze = css`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  .line-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    svg {
      position: absolute;
      width: 5%;
      height: 5%;
      z-index: 3;

      /* middle left dot */
      &:first-child {
        opacity: 0;
        top: -2.5%;
        animation: ${middleLeftDotPath} 6s linear infinite 9.5s;
      }

      /* top left dot */
      &:nth-child(2) {
        opacity: 0;
        animation: ${topLeftDotPath} 5s linear infinite 8s;
      }

      /* middle right dot */
      &:nth-child(5) {
        top: -2.5%;
        opacity: 0;
        animation: ${middleRightDotPath} 6s infinite 16s;
      }

      /* bottom left dot */
      &:nth-child(3) {
        opacity: 0;
        animation: ${bottomLeftDotPath} 5s linear infinite 9s; // 9
      }

      /* top right dot */
      &:nth-child(4) {
        opacity: 0;
        animation: ${topRightDotPath} 6s linear infinite 12s;
      }

      /* bottom right dot */
      &:nth-child(6) {
        opacity: 0;
        animation: ${bottomRightDotPath} 6s linear infinite 14s; // 14
      }
    }
  }

  #top-right {
    position: absolute;
    top: 0%;
    translate: 0 calc(-100% + 15%);
    width: 45%;
    height: 50%;
    &-path {
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 1s ease-in-out 5300ms forwards;
    }
  }

  #top-left {
    position: absolute;
    /* translate: -225px -109px; */
    width: 45%;
    height: 50%;
    top: 0;
    left: 0%;
    translate: -100% calc(-100% + 15%);
    &-path {
      /* width: 100%; */
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 1s ease-in-out 3000ms forwards;
    }
  }
  #middle-left {
    position: absolute;
    translate: -225px 0;
    width: 45%;
    height: 50%;
    top: 0;
    left: 0%;
    translate: -100% -50%;
    &-path {
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 1s ease-in-out 900ms forwards,
        ${undraw} 1s ease-in-out 2.5s forwards,
        ${draw} 1s ease-in-out 7.5s forwards;
    }
  }
  #middle-right {
    position: absolute;
    width: 45%;
    height: 50%;
    top: 0;
    left: 0%;
    translate: 0% -50%;
    &-path {
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 1s ease-in-out 900ms forwards,
        ${undraw} 1s ease-in-out 4500ms forwards,
        ${draw} 1s ease-in-out 7.5s forwards;
    }
  }

  #bottom-left {
    position: absolute;
    width: 45%;
    height: 50%;
    left: 0%;
    top: 0%;
    translate: -100% -15%;
    &-path {
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 1s ease-in-out 3s forwards;
    }
  }
  #bottom-right {
    position: absolute;
    width: 45%;
    height: 50%;
    left: 0%;
    top: 0%;
    translate: 0% -15%;
    &-path {
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 1s ease-in-out 5300ms forwards;
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SAnimatedSvgLinesWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
