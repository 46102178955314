"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface IRoadmapChevronWrapper {
  showColumnCards: boolean;
}

const Bronze = css<IRoadmapChevronWrapper>`
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: var(--icon-02);
    transition: transform 300ms;
    transform: scale(${({ showColumnCards }) => (showColumnCards ? -1 : 1)});
  }
`;

const Silver = css<IRoadmapChevronWrapper>``;

const Gold = css<IRoadmapChevronWrapper>`
  display: none;
`;

const Platinum = css<IRoadmapChevronWrapper>``;

const Diamond = css<IRoadmapChevronWrapper>``;

export const SRoadmapChevronWrapper = styled.div<IRoadmapChevronWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
