"use client";
import { Button } from "@caisy/league/src/components/button";
import { IconExternalLink } from "@caisy/league/src/icons/IconExternalLink";
import { RichTextRenderer } from "@caisy/rich-text-react-renderer";

import Link from "next/link";
import { FC, useEffect, useState } from "react";
import { IProjectTemplate } from "../../services/starter-template/types";
import IconGithub from "../../svgs/IconGithub";
import { getCookie } from "../../utils/helpers/getCookie";
import { SOnboardingTemplateModalRichtext } from "./styles/SOnboardingTemplateModalRichtext";
import { SOnboardingTemplatesModalArticle } from "./styles/SOnboardingTemplatesModalArticle";
import { SOnboardingTemplatesModalContent } from "./styles/SOnboardingTemplatesModalContent";
import { SOnboardingTemplatesModalSider } from "./styles/SOnboardingTemplatesModalSider";
import { SOnboardingTemplatesModalSiderItem } from "./styles/SOnboardingTemplatesModalSiderItem";
import { SOnboardingTemplatesModalSiderSection } from "./styles/SOnboardingTemplatesModalSiderSection";
import { SOnboardingTemplatesModalSiderTitle } from "./styles/SOnboardingTemplatesModalSiderTitle";
import { NextImage } from "../next-image/NextImage";

interface IOnboardingTemplateModal {
  template: IProjectTemplate;
}

export const StarterTemplateDetails: FC<IOnboardingTemplateModal> = ({
  template,
}) => {
  const [hasSession, setHasSession] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (getCookie("caisyuserid")) {
        setHasSession(true);
      }
    }
  }, []);

  return (
    <SOnboardingTemplatesModalContent>
      <SOnboardingTemplatesModalArticle>
        <SOnboardingTemplateModalRichtext>
          <RichTextRenderer node={template?.fullDescription?.json} />
        </SOnboardingTemplateModalRichtext>
      </SOnboardingTemplatesModalArticle>
      <SOnboardingTemplatesModalSider>
        {/* right now its only possible to use this in the onboarding, thats why we do not show it for logged in users */}
        {!hasSession && (
          <Link href="/app/signup">
            <Button style={{ display: "block" }} type="primary">
              Start with this template
            </Button>
          </Link>
        )}
        <SOnboardingTemplatesModalSiderSection>
          <SOnboardingTemplatesModalSiderTitle>
            Resources
          </SOnboardingTemplatesModalSiderTitle>
          <SOnboardingTemplatesModalSiderItem isLink>
            <IconExternalLink size={16} />
            <a rel="noreferrer" href={template?.demoLink} target="_blank">
              Live demo
            </a>
          </SOnboardingTemplatesModalSiderItem>
          <SOnboardingTemplatesModalSiderItem isLink>
            <IconGithub />
            <a rel="noreferrer" href={template?.githubLink} target="_blank">
              Github repo
            </a>
          </SOnboardingTemplatesModalSiderItem>
        </SOnboardingTemplatesModalSiderSection>
        <SOnboardingTemplatesModalSiderSection>
          <SOnboardingTemplatesModalSiderTitle>
            Stack
          </SOnboardingTemplatesModalSiderTitle>
          {template?.stack?.map(({ id, icon, label }) => (
            <SOnboardingTemplatesModalSiderItem key={id}>
              <NextImage
                alt={icon.title}
                src={icon.src}
                height={24}
                width={24}
                style={{ objectFit: "contain" }}
              />

              {label}
            </SOnboardingTemplatesModalSiderItem>
          ))}
        </SOnboardingTemplatesModalSiderSection>
        <SOnboardingTemplatesModalSiderSection>
          <SOnboardingTemplatesModalSiderTitle>
            Publisher
          </SOnboardingTemplatesModalSiderTitle>
          <div style={{ width: "fit-content" }}>
            <NextImage
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              src="https://assets.caisy.io/asset/1bcdc513-e692-4fe8-abaa-21ca6335036c/e5cb239884df56d2897e9bb37d16625d/CAISY_FULL.svg"
              alt={"caisy-logo"}
            />
          </div>
        </SOnboardingTemplatesModalSiderSection>
      </SOnboardingTemplatesModalSider>
    </SOnboardingTemplatesModalContent>
  );
};
