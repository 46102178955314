"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody03Semi,
  CSSBody03SemiXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompCardWithIconTitle {
  text?: string;
}

const Bronze = css<ISCompCardWithIconTitle>`
  ${CSSBody03Semi};
  color: var(--text-02);
  text-align: center;
  margin-bottom: ${({ text }) => (text ? "8px" : "0")};
`;

const Silver = css<ISCompCardWithIconTitle>``;

const Gold = css<ISCompCardWithIconTitle>``;

const Platinum = css<ISCompCardWithIconTitle>``;

const Diamond = css<ISCompCardWithIconTitle>`
  ${CSSBody03SemiXLarge};
`;

export const SCompCardWithIconTitle = styled.p<ISCompCardWithIconTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
