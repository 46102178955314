import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const CustomButtonPrimaryStyles = css`
  background: var(
    --interactional-interactional-primary-01-interactional-primary-01,
    #008cff
  );

  color: var(--text-text-02, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  line-height: 20px; /* 166.667% */
`;
const CustomButtonSecondaryStyles = css`
  color: var(--text-text-01-text-01, #172c55);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
`;

const Bronze = css<{ styleType?: string }>`
  background-color: transparent;
  outline: 0;
  border: 0;
  flex: 1;
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
  ${(props) =>
    props.styleType === "primary"
      ? CustomButtonPrimaryStyles
      : CustomButtonSecondaryStyles};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SBarButton = styled.button<{ styleType?: string }>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
