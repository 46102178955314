"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  height: calc((100vw - (24px * 2)) * (261 / 400));
  width: 100%;
  border-bottom: 1px solid var(--ui-03);
  object-fit: cover;
`;

const Silver = css``;

const Gold = css`
  height: min(
    calc((100vw - 104px) * (274.75 / 1171) * (261 / 400)),
    calc((1920px - 104px) * (274.75 / 1171) * (261 / 400))
  );
`;

const Platinum = css``;

const Diamond = css`
  height: min(
    calc((100vw - 152px) * (424 / 1768) * (261 / 400)),
    calc((1920px - 152px) * (424 / 1768) * (261 / 400))
  );
`;

export const SOnboardingTemplatesTemplateImage = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
