"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  padding: 62px 0;

  &:nth-of-type(2n + 1) {
    background-color: #f7f9fa;
  }
`;

const Silver = css``;

const Gold = css`
  padding: 62px 0;
`;

const Platinum = css``;

const Diamond = css`
  padding: 62px 0;
`;

export const SCompBlogCategoryTeaser = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
