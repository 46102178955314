"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  background: rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 20px;
  width: 80px;
  height: 80px;
  display: flex;
  margin: 0 auto 32px;
`;

const Silver = css``;

const Gold = css`
  width: 124px;
  height: 124px;
  padding: 36px;
  margin: 0 0 32px;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompQuoteCardCompanyGridCardLogo = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
