import { css } from "styled-components";

export const CSSColors = css`
  :root {
    --activated-icon-02: #ffffff;
    --activated-icon-02-description: #ffffff;
    --activated-icon-02-1: #ed3e3e;
    --activated-icon-02-1-description: #ffffff;
    --active-icon-01: #008cff;
    --active-icon-01-1: #567a98;
    --active-icon-01-2: #172c55;
    --active-icon-02: #ffffff66;
    --active-icon-02-description: #ffffff;
    --active-icon-03: #172c55;
    --active-icon-03-1: #007cc1;
    --active-icon-04: #008cff;
    --active-icon-04-1: #ffffff;
    --active-icon-06: #cb2d3b;
    --active-icon-07: #0f7100;
    --active-icon-08: #008cff;
    --active-icon-09: #0077d9;
    --active-icon-10: #ffffff;
    --active-interactional-primary-01: #0077d9;
    --active-interactional-primary-02: #687387;
    --active-interactional-secondary-01: #6f8599;
    --active-interactional-secondary-02: #cb2d3b;
    --active-interactional-secondary-03: #c3d1d9;
    --active-interactional-secondary-03-1: #008cff;
    --active-interactional-secondary-04: #00000099;
    --active-interactional-secondary-05: #baf5c1;
    --active-interactional-secondary-06: #fdc5b2;
    --active-interactional-secondary-07: #d1ebfc;
    --active-interactional-secondary-08: #c3d1d9;
    --active-interactional-tertiary-01: #0077d9;
    --active-interactional-tertiary-01-1: #008cff;
    --active-interactional-tertiary-02: #567a98;
    --active-interactional-tertiary-03: #e8eff3;
    --active-interactional-tertiary-03-1: #008cff;
    --active-interactional-tertiary-04: #f3f7f9;
    --active-interactional-tertiary-04-1: #008cff;
    --active-interactional-tertiary-05: #c3d1d9;
    --active-interactional-tertiary-06: #4c677d;
    --active-text-01: #008cff;
    --active-text-01-1: #567a98;
    --active-text-03: #008cff;
    --active-text-04: #008cff;
    --active-text-04-1: #ffffff;
    --active-text-06: #cb2d3b;
    --active-text-07: #0f7100;
    --active-text-08: #b16800;
    --active-text-09: #0077d9;
    --active-text-10: #ffffff;
    --active-ui-01: #172c55;
    --active-ui-01-1: #f3f7f9;
    --active-ui-01-2: #f3f8fb;
    --active-ui-01-3: #008cff;
    --active-ui-02: #c3d1d9;
    --active-ui-03: #ffffff;
    --active-ui-03-1: #c3d1d9;
    --active-ui-03-2: #008cff;
    --disabled-icon: #c7cad1;
    --disabled-interactional-01: #e1e1e1;
    --disabled-interactional-02: #ebeaea;
    --disabled-interactional-03: #f7f9fa;
    --disabled-interactional-04: #e2e9ed;
    --disabled-text: #c7cad1;
    --hover-icon-01: #567a98;
    --hover-icon-01-1: #008cff;
    --hover-icon-02: #ffffffb3;
    --hover-icon-02-description: #ffffff;
    --hover-icon-03: #567a98;
    --hover-icon-04: #007ee6;
    --hover-icon-06: #cb2d3b;
    --hover-icon-07: #1c8c00;
    --hover-icon-08: #829db4;
    --hover-icon-09: #007ee6;
    --hover-icon-10: #ffffff;
    --hover-interactional-primary-01: #007ee6;
    --hover-interactional-primary-02: #374156;
    --hover-interactional-secondary-01: #758da2;
    --hover-interactional-secondary-02: #cb2d3b;
    --hover-interactional-secondary-03: #e8eff3;
    --hover-interactional-secondary-04: #0000004d;
    --hover-interactional-secondary-05: #defce2;
    --hover-interactional-secondary-06: #fee5d8;
    --hover-interactional-secondary-07: #e4f1f9;
    --hover-interactional-secondary-08: #dde8ed;
    --hover-interactional-tertiary-01: #007ee6;
    --hover-interactional-tertiary-02: #8ca9bd;
    --hover-interactional-tertiary-03: #f3f8fb;
    --hover-interactional-tertiary-04: #e8eff3;
    --hover-interactional-tertiary-05: #e8eff3;
    --hover-interactional-tertiary-06: #53728c;
    --hover-text-01: #008cff;
    --hover-text-01-1: #567a98;
    --hover-text-03: #567a98;
    --hover-text-04: #007ee6;
    --hover-text-06: #cb2d3b;
    --hover-text-07: #1c8c00;
    --hover-text-08: #d48400;
    --hover-text-09: #007ee6;
    --hover-text-10: #ffffff;
    --hover-ui-01: #e8eff3;
    --hover-ui-01-1: #f7f9fa;
    --hover-ui-02: #e8eff3;
    --hover-ui-03: #dde8ed;
    --icon-01: #172c55;
    --icon-02: #ffffff;
    --icon-02-description: #ffffff;
    --icon-03: #829db4;
    --icon-04: #567a98;
    --icon-05: #f7f9fa;
    --icon-06: #ed3e3e;
    --icon-07: #41c300;
    --icon-08: #b4c9d7;
    --icon-09: #008cff;
    --icon-10: #ffffffa3;
    --icon-supportive-01: #ffffffa3;
    --icon-supportive-02: #0077d9;
    --icon-supportive-03: #6100ff;
    --interactional-primary-01: #008cff;
    --interactional-primary-02: #172c55;
    --interactional-secondary-01: #829db4;
    --interactional-secondary-02: #ed3e3e;
    --interactional-secondary-03: #f3f8fb;
    --interactional-secondary-04: #00000000;
    --interactional-secondary-05: #defce200;
    --interactional-secondary-06: #fee5d800;
    --interactional-secondary-07: #e4f4f900;
    --interactional-secondary-08: #e8eff3;
    --interactional-supporitve-01: #41c300;
    --interactional-tertiary-01: #e8f0f3;
    --interactional-tertiary-02: #b4c9d7;
    --interactional-tertiary-03: #ffffff;
    --interactional-tertiary-04: #ffffff00;
    --interactional-tertiary-05: #f7f9fa;
    --interactional-tertiary-06: #567a98;
    --tag-01: #ff008a;
    --tag-02: #6100ff;
    --tag-03: #ffae03;
    --tag-04: #ed3e3e;
    --tag-05: #35e918;
    --tag-06: #0047ff;
    --text-01: #172c55;
    --text-02: #ffffff;
    --text-02-description: #ffffff;
    --text-03: #829db4;
    --text-04: #567a98;
    --text-05: #f7f9fa;
    --text-06: #ed3e3e;
    --text-07: #41c300;
    --text-08: #f7a300;
    --text-09: #008cff;
    --text-10: #ffffffa3;
    --text-code-01: #172c55;
    --text-code-02: #96afc4;
    --text-code-03: #007cc1;
    --text-code-04: #008cff;
    --text-code-05: #b80084;
    --text-code-06: #e910ac;
    --text-code-07: #f7a300;
    --text-suppportive-02: #008cff;
    --text-supprotive-01: #ffffffa3;
    --ui-01: #ffffff;
    --ui-01-description: #ffffff;
    --ui-02: #f7f9fa;
    --ui-03: #e8eff3;
    --ui-04: #96afc4;
    --ui-05: #829db4;
    --ui-06: #567a98;
    --ui-07: #0b1527;
    --ui-background: #f7f9fa;
    --ui-overlay-01: #8295a5b8;
    --ui-overlay-02: #000000bd;
    --ui-overlay-03: #00000073;
    --ui-supportive-01: #ed3e3e;
    --ui-supportive-02: #df2e2e;
    --ui-supportive-03: #fff3f0;
    --ui-supportive-04: #fdf3e4;
    --ui-supportive-05: #edfaf5;
    --ui-supportive-06: #008cff;
    --ui-supportive-07: #0077d9;
    --ui-supportive-08: #172c55;
    --ui-supportive-09: #fafafa;
    --ui-supportive-10: #eff4f7;
    --ui-supportive-11: #ffffff33;
    --ui-supportive-12: #e8f5ff;
    --ui-supportive-13: #e2e5ff;
    --ui-supportive-14: #9fcef2;
    --ui-supportive-15: #6ba5d2;
    --blue-gradient: linear-gradient(102.45deg, #008cff 16.49%, #0047ff 89.22%);
    --green-gradient: linear-gradient(285.51deg, #00cc76 21.99%, #00af51 96.73%),
      linear-gradient(102.45deg, #008cff 16.49%, #0047ff 89.22%);
    --violet-gradient: linear-gradient(
      102.45deg,
      #6100ff 16.49%,
      #8000ff 89.22%
    );
    --orange-gradient: linear-gradient(
      102.45deg,
      #ff8a00 16.49%,
      #ef7301 89.22%
    );
    --lime-gradient: linear-gradient(102.45deg, #9eff00 16.49%, #01ef6f 89.22%);
  }
`;
