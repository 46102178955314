"use client";
import { NextImage } from "src/components/next-image/NextImage";
import React, { useEffect, useRef, useState } from "react";
import { RichText } from "../../../base-components/rich-text/RichText";
import { ChevronDown } from "../../../svgs/ChevronDown";
import { IGenRoadmapColumn } from "../../../utils/types_gen";
import { SRoadmapCardDescription } from "./styles/SRoadmapCardDescription";
import { SRoadmapCardHeaderWrapperWithChev } from "./styles/SRoadmapCardHeaderWrapperWithChev";
import { SRoadmapCardTitle } from "./styles/SRoadmapCardTitle";
import { SRoadmapChevronWrapper } from "./styles/SRoadmapChevronWrapper";
import { SRoadmapColIcon } from "./styles/SRoadmapColIcon";
import { SRoadmapColTitle } from "./styles/SRoadmapColTitle";
import { SRoadmapColumn } from "./styles/SRoadmapColumn";
import { SRoadmapColumnCard } from "./styles/SRoadmapColumnCard";
import { SRoadmapColumnCards } from "./styles/SRoadmapColumnCards";
import { SRoadmapColumnHeader } from "./styles/SRoadmapColumnHeader";

interface IRoadmapCardColumn extends IGenRoadmapColumn {
  showColumnCards: string;
  desktopCardDescriptionHeight: number;
  setShowColumnCards: React.Dispatch<React.SetStateAction<string>>;
}

export const RoadmapCardColumn: React.FC<IRoadmapCardColumn> = ({
  icon,
  cards,
  title,
  id,
  showColumnCards,
  setShowColumnCards,
  desktopCardDescriptionHeight,
}) => {
  const roadMapItemHeight = useRef<HTMLDivElement>();
  const [isMobile, setIsMobile] = useState<boolean>();
  useEffect(() => {
    if (typeof window === "undefined") return null;
    const media = window.matchMedia("(max-width:1279.98px)");
    const updateMedia = () => {
      setIsMobile(media.matches);
    };
    media.addEventListener("change", updateMedia);
    updateMedia();

    return () => {
      media.removeEventListener("change", updateMedia);
    };
  }, []);

  // const desktopTargetCardHeight = maxHeight;

  return (
    <SRoadmapColumn id={id}>
      <SRoadmapCardHeaderWrapperWithChev
        onClick={() => {
          setShowColumnCards(showColumnCards === id ? "" : id);
        }}
      >
        <SRoadmapColumnHeader>
          {icon?.src && (
            <SRoadmapColIcon>
              <NextImage
                src={icon.src}
                alt={icon.description || icon.title}
                fill
              />
            </SRoadmapColIcon>
          )}
          {title && <SRoadmapColTitle>{title}</SRoadmapColTitle>}
        </SRoadmapColumnHeader>
        <SRoadmapChevronWrapper showColumnCards={showColumnCards === id}>
          <ChevronDown />
        </SRoadmapChevronWrapper>
      </SRoadmapCardHeaderWrapperWithChev>
      {!!cards?.length && (
        <SRoadmapColumnCards
          ref={roadMapItemHeight}
          style={{
            height: isMobile
              ? showColumnCards === id
                ? roadMapItemHeight.current?.scrollHeight
                : 0
              : undefined,
          }}
        >
          {cards.map(
            (card) =>
              card && (
                <SRoadmapColumnCard
                  key={card.id}
                  showDescription={showColumnCards === id}
                >
                  {card.title && (
                    <SRoadmapCardTitle>{card.title}</SRoadmapCardTitle>
                  )}
                  {!!card.text?.json?.content?.length && (
                    <SRoadmapCardDescription
                      style={{
                        height: !isMobile
                          ? `${desktopCardDescriptionHeight}px`
                          : undefined,
                      }}
                    >
                      <RichText content={card.text?.json.content} />
                    </SRoadmapCardDescription>
                  )}
                </SRoadmapColumnCard>
              )
          )}
        </SRoadmapColumnCards>
      )}
    </SRoadmapColumn>
  );
};
