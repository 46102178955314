"use client";
import { useEffect, useState } from "react";
import { debounce } from "../utils/helpers/debounce";
import { IGenAsset } from "../utils/types_gen";

export default function useResponseImage(responseImages: {
  smallScreenImage?: IGenAsset;
  mediumScreenImage?: IGenAsset;
  largeScreenImage?: IGenAsset;
}): IGenAsset | undefined {
  const [imageForDisplay, setImageForDisplay] = useState<IGenAsset | undefined>(
    undefined
  );

  const imageForDisplayHandler = () => {
    const { smallScreenImage, mediumScreenImage, largeScreenImage } =
      responseImages;

    if (window.innerWidth < 1280) {
      setImageForDisplay(smallScreenImage);
    } else if (window.innerWidth < 1920) {
      setImageForDisplay(mediumScreenImage || smallScreenImage);
    } else {
      setImageForDisplay(
        largeScreenImage || mediumScreenImage || smallScreenImage
      );
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") return null;

    if (!responseImages) {
      return;
    }

    imageForDisplayHandler();
    const debouncedHandleResize = debounce(imageForDisplayHandler, 100);
    window.addEventListener("resize", debouncedHandleResize);

    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);

  return imageForDisplay;
}
