"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css``;

const Silver = css``;

const Gold = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  justify-self: center;
  height: 100%;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompSubpageHeroDescriptionWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
