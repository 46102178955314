"use client";
import React, { useEffect, useRef, useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { Grid } from "../../base-components/grid/Grid";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import GradientCheckmarkIcon from "../../svgs/GradientCheckmarkIcon";
import MinusClear from "../../svgs/MinusClear";
import { IGenTable, Maybe } from "../../utils/types_gen";
import { SCompTable } from "./styles/SCompTable";
import { SCompTableBlockTitle } from "./styles/SCompTableBlockTitle";
import { SCompTableCellHeaders } from "./styles/SCompTableCellHeaders";
import { SCompTableCellTitle } from "./styles/SCompTableCellTitle";
import { SCompTableCheckmarkWrapper } from "./styles/SCompTableCheckmarkWrapper";
import { SCompTableCustomButton } from "./styles/SCompTableCustomButton";
import { SCompTableCustomizedRow } from "./styles/SCompTableCustomizedRow";
import { SCompTableHeaderRow } from "./styles/SCompTableHeaderRow";
import { SCompTableHeader } from "./styles/SCompTableHeader";
import { SCompTableWrapper } from "./styles/SCompTableWrapper";
import { SCompTableCell } from "./styles/SCompTableCell";
import { SCompTableSection } from "./styles/SCompTableSection";
import { IconAngleDown, IconAngleUp } from "@caisy/league/dist/src/icons";
import { SCompTableSectionHeader } from "./styles/SCompTableSectionHeader";
import { SCompTableCellRich } from "./styles/SCompTableCellRich";
import { SCompTableCellRichTitle } from "./styles/SCompTableCellRichTitle";
import { SCompTableCellRichContent } from "./styles/SCompTableCellRichContent";
import { SCompTableCellRichText } from "./styles/SCompTableCellRichText";
import { NextImage } from "../next-image/NextImage";
import { SCompTableCellRichIcon } from "./styles/SCompTableCellRichIcon";
import { SCompTableBadge } from "./styles/SCompTableBadge";
import { SCompTableHead } from "./styles/SCompTableHead";
import { SCompTableHeaderScroller } from "./styles/SCompTableHeaderScroller";
import { createGlobalStyle } from "styled-components";
import { SCompTableSectionsWrapper } from "./styles/SCompTableSectionsWrapper";

export interface IGenTableWithBg extends IGenTable {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

interface ITableCell {
  badgeText?: string;
  id?: string;
  infoText?: string;
  title?: string;
  __typename?: string;
  value?: string;
  checkmark?: boolean;
  button?: any;
  gradientAccent?: any;
  icon?: any;
}

interface ITableDataItem {
  id?: string;
  sectionCount?: number;
  tableCell?: ITableCell[];
  title?: string;
  __typename?: string;
  icon?: any;
}

interface ITableData {
  items: ITableDataItem[];
  title: string;
}

const GCSSAddonForTable = createGlobalStyle`
    html, body {
    overflow-x: clip;
  }
`;

export const CompTableInner: React.FC<IGenTableWithBg> = ({
  id,
  isSectionBgWhite,
  header,
  rows,
  title,
}) => {
  const [collapsedSections, setCollapsedSections] = useState<number[]>([]);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [scrollerRef, setScrollerRef] = useState<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let sectionCount = 0;
    const newTableData = [{ items: [], title: "" }];
    rows?.map((row) => {
      if (row?.__typename == "TableSection") {
        if (newTableData.length - 1 >= sectionCount) {
          sectionCount++;
        }
        if (!newTableData[sectionCount]) {
          newTableData[sectionCount] = { items: [], title: "" };
        }
        if (newTableData[sectionCount]?.title !== row.title) {
          newTableData[sectionCount].title = row.title ?? "";
        }
      }
      if (row?.__typename == "TableRow" && sectionCount >= 0 && !!row) {
        newTableData[sectionCount].items.push({ ...row, sectionCount });
      }
    });
    setTableData(newTableData);
  }, [rows]);

  const handleTableScroll = (e) => {
    if (headerRef?.current?.scrollLeft)
      headerRef.current.scrollLeft = e.target.scrollLeft;
  };

  const [scrollerWidth, setScrollerWidth] = useState(0);
  const [headWidth, setHeadWidth] = useState(0);

  const handleResize = () => {
    setScrollerWidth(scrollerRef?.clientWidth);
    setHeadWidth(scrollerRef?.offsetWidth);
  };

  useEffect(() => {
    if (typeof window === "undefined" || !scrollerRef) return;

    window.addEventListener("resize", handleResize);

    return () => document.removeEventListener("resize", handleResize);
  }, [typeof window, scrollerRef]);

  return (
    <SCompTable id={id ?? ""}>
      <GCSSAddonForTable />
      <GridPadding>
        {title && (
          <Grid>
            <GridItem
              bronze={{ start: 1, end: 12 }}
              gold={{ start: 2, end: 11 }}
            >
              <SCompTableBlockTitle isSectionBgWhite={isSectionBgWhite}>
                {title}
              </SCompTableBlockTitle>
            </GridItem>
          </Grid>
        )}

        <SCompTableWrapper>
          <SCompTableHeaderScroller ref={headerRef} width={scrollerWidth}>
            <SCompTableHead width={headWidth}>
              <SCompTableHeaderRow>
                {!!header?.length &&
                  header?.map((h, i) => (
                    <SCompTableHeader key={i}>
                      <SCompTableCellHeaders>
                        {h && (
                          <SCompTableCellTitle
                            colorChange={i !== header.length - 1}
                            bgGradient={!isSectionBgWhite}
                          >
                            {h.title}
                          </SCompTableCellTitle>
                        )}
                      </SCompTableCellHeaders>
                    </SCompTableHeader>
                  ))}
              </SCompTableHeaderRow>
            </SCompTableHead>
          </SCompTableHeaderScroller>
          <SCompTableSectionsWrapper
            onScroll={handleTableScroll}
            ref={setScrollerRef}
          >
            {tableData?.map((row, index) => {
              return (
                <>
                  {row.title && (
                    <SCompTableSection key={index}>
                      {row.title && (
                        <SCompTableSectionHeader
                          onClick={() => {
                            setCollapsedSections(
                              collapsedSections.includes(index)
                                ? collapsedSections.filter((el) => el !== index)
                                : [...collapsedSections, index]
                            );
                          }}
                        >
                          <td>{row.title}</td>
                          <td>
                            {!collapsedSections.includes(index) ? (
                              <IconAngleUp />
                            ) : (
                              <IconAngleDown />
                            )}
                          </td>
                        </SCompTableSectionHeader>
                      )}

                      {collapsedSections &&
                      !collapsedSections.includes(index) ? (
                        <>
                          {row?.items?.map((cell, index) => (
                            <SCompTableCustomizedRow key={index}>
                              {cell?.tableCell?.map((el, index) =>
                                el.__typename === "TableCellRich" ? (
                                  <SCompTableCellRich key={`${el.id}-${index}`}>
                                    <SCompTableCellRichIcon big>
                                      <NextImage
                                        src={el.icon?.src}
                                        alt={
                                          cell.icon?.description ||
                                          cell.icon?.title
                                        }
                                        height={40}
                                        width={40}
                                      />
                                    </SCompTableCellRichIcon>
                                    <SCompTableCellRichContent>
                                      <SCompTableCellRichTitle>
                                        <SCompTableCellRichIcon small>
                                          <NextImage
                                            src={el.icon?.src}
                                            alt={
                                              cell.icon?.description ||
                                              cell.icon?.title
                                            }
                                            height={24}
                                            width={24}
                                          />
                                        </SCompTableCellRichIcon>
                                        {el.title}
                                        {el.badgeText && el.badgeText != "" && (
                                          <SCompTableBadge>
                                            {el.badgeText}
                                          </SCompTableBadge>
                                        )}
                                      </SCompTableCellRichTitle>
                                      <SCompTableCellRichText>
                                        {el.infoText}
                                      </SCompTableCellRichText>
                                    </SCompTableCellRichContent>
                                  </SCompTableCellRich>
                                ) : (
                                  <SCompTableCell key={`${el.id}-${index}`}>
                                    {el.value ? (
                                      <>{el.value}</>
                                    ) : el.checkmark ? (
                                      <SCompTableCheckmarkWrapper
                                        gradientAccent={el.gradientAccent}
                                      >
                                        <GradientCheckmarkIcon
                                          xkey={el.id ?? ""}
                                        />
                                      </SCompTableCheckmarkWrapper>
                                    ) : (
                                      <MinusClear />
                                    )}
                                    {el.button?.map(
                                      (btn) =>
                                        btn && (
                                          <SCompTableCustomButton
                                            key={btn.id}
                                            {...btn}
                                          >
                                            {btn.title}
                                          </SCompTableCustomButton>
                                        )
                                    )}
                                  </SCompTableCell>
                                )
                              )}
                            </SCompTableCustomizedRow>
                          ))}
                        </>
                      ) : null}
                    </SCompTableSection>
                  )}
                </>
              );
            })}
          </SCompTableSectionsWrapper>
        </SCompTableWrapper>
      </GridPadding>
    </SCompTable>
  );
};
