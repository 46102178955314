"use client";

import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface ISOnboardingTemplatesTabsItemIcon {
  active: boolean;
}

const Bronze = css<ISOnboardingTemplatesTabsItemIcon>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 200ms;

  img {
    height: 20px;
    width: 20px;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOnboardingTemplatesTabItemIcon = styled.div<ISOnboardingTemplatesTabsItemIcon>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
