export const TopLeftLine = () => (
  <svg
    id="top-left"
    width="195"
    height="103"
    viewBox="0 0 195 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="top-left-path"
      d="M195 102L160.462 102C146.655 102 135.462 90.8071 135.462 77L135.462 26C135.462 12.1929 124.269 1.00001 110.462 1.00001L-4.62408e-06 1.00001"
      stroke="url(#paint0_linear_7928_195507)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7928_195507"
        x1="52.9893"
        y1="52.3707"
        x2="233.153"
        y2="52.3707"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.288449" stopColor="#33425F" />
        <stop offset="1" stopColor="#008CFF" />
      </linearGradient>
    </defs>
  </svg>
);
