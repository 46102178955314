"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading02,
  CSSHeading02Small,
  CSSHeading02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface ISCompImageBulletPointsTitle {
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const Bronze = css<ISCompImageBulletPointsTitle>`
  color: ${({ isSectionBgWhite }) =>
    isSectionBgWhite ? "var(--text-01)" : `var(--text-02)`};
  ${CSSHeading02Small};
`;

const Silver = css<ISCompImageBulletPointsTitle>``;

const Gold = css<ISCompImageBulletPointsTitle>`
  ${CSSHeading02};
`;

const Platinum = css<ISCompImageBulletPointsTitle>``;

const Diamond = css<ISCompImageBulletPointsTitle>`
  ${CSSHeading02XLarge};
`;

export const SCompImageBulletPointsTitle = styled.h3<ISCompImageBulletPointsTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
