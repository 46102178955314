"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  iframe {
    height: 1470px; /* important because the calendry package is using inline styles and we need to overwrite them*/
  }
`;

const Silver = css``;

const Gold = css`
  iframe {
    height: 690px; /* important because the calendry package is using inline styles and we need to overwrite them*/
  }
`;

const Platinum = css``;

const Diamond = css``;

export const SCompCalenderBooking = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
