"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  display: none;
  position: relative;
  line-height: 0;

  svg {
    width: 32px;
    height: 32px;
    color: var(--icon-02);
  }
`;

const Silver = css``;

const Gold = css`
  display: flex;
  border: 1px solid var(--icon-02);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompQuoteSvgWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
