"use client";
import { NextImage } from "src/components/next-image/NextImage";
import React, { useRef, useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import PlayButton from "../../svgs/PlayButton";
import { IGenWideVideoOrImage } from "../../utils/types_gen";
import { Button } from "../button/Button";
import { SCompImageTextPlayButtonWrapper } from "../comp-image-text/styles/SCompImageTextPlayButtonWrapper";
import { SCompWideVideoOrImage } from "./styles/SCompWideVideoOrImage";
import { SCompWideVideoThumbnail } from "./styles/SCompWideVideoThumbnail";
import { SCompWideVideoThumbnailContainer } from "./styles/SCompWideVideoThumbnailContainer";
import { SCompWideVideoWrapper } from "./styles/SCompWideVideoWrapper";
import { SMediaContentWrapper } from "./styles/SMediaContentWrapper";

export const CompWideVideo: React.FC<
  Omit<IGenWideVideoOrImage, "__typename">
> = ({ id, overlapTop, image, videoVimeoId }) => {
  const iframeRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  return (
    <SCompWideVideoOrImage id={id} overlapTop={overlapTop}>
      <GridPadding>
        <Grid>
          <GridItem
            bronze={{ start: 1, end: 12 }}
            gold={{ start: 2, end: 11 }}
            diamond={{ start: 2, end: 11 }}
          >
            <SMediaContentWrapper>
              <SCompWideVideoThumbnailContainer
                isPlaying={playing}
                onClick={async () => {
                  if (videoVimeoId) {
                    const Player = (await import("@vimeo/player")).default;
                    setPlaying(true);

                    const player = new Player(iframeRef.current, {
                      id: videoVimeoId,
                      responsive: true,
                      portrait: false,
                      playsinline: true,
                      byline: false,
                    });
                    player?.play();
                  }
                }}
              >
                <SCompWideVideoThumbnail>
                  {image?.src && (
                    <NextImage
                      // priority will preload for better lighthouse score
                      priority
                      src={image.src}
                      fill
                      style={{ objectFit: "cover" }}
                      alt={image.description || image.title}
                      // loading="lazy"
                    />
                  )}
                </SCompWideVideoThumbnail>
                <SCompImageTextPlayButtonWrapper>
                  <Button shape="Round">
                    <PlayButton />
                  </Button>
                </SCompImageTextPlayButtonWrapper>
              </SCompWideVideoThumbnailContainer>
              <SCompWideVideoWrapper ref={iframeRef} />
            </SMediaContentWrapper>
          </GridItem>
        </Grid>
      </GridPadding>
    </SCompWideVideoOrImage>
  );
};
