import React from "react";

export const svgFirstStopsVariables = {
  blue: "var(--icon-09)",
  purple: "#6100FF",
  lime: "#9EFF00",
  orange: "#FF8A00",
  green: "#00CC76",
  grey: "var(--icon-04)",
  white: "var(--icon-02)",
};

export const svgSecondStopsVariables = {
  blue: "#0047FF",
  purple: "#8000FF",
  lime: "#01EF6F",
  orange: "#EF7301",
  green: "#00AF51",
  grey: "var(--icon-04)",
  white: "var(--icon-02)",
};

const GradientCheckmarkIcon = ({ xkey }: { xkey: string }) => {
  const first = xkey + 1;
  const second = xkey + 2;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle
        cx="9.999"
        cy="9.999"
        r="7.503"
        fill={`url(#${first})`}
        stroke={`url(#${second})`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        stroke="currentColor"
        className="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.035 10.281l1.807 1.806-.012-.011L12.906 8"
      />
      <defs>
        <linearGradient
          id={first}
          x1="4.718"
          x2="17.42"
          y1="6.12"
          y2="8.923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" className="stop-color-1" />
          <stop offset="1" stopColor="currentColor" className="stop-color-2" />
        </linearGradient>
        <linearGradient
          id={second}
          x1="4.718"
          x2="17.42"
          y1="6.12"
          y2="8.923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" className="stop-color-1" />
          <stop offset="1" stopColor="currentColor" className="stop-color-2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientCheckmarkIcon;
