"use client";
import React, {
  createContext,
  FC,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

interface ISearchProvider {
  searchActive: boolean;
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  searchTerm: string;
  setSearchTerm: (name: string) => void;
  searchByTag: (name: string) => void;
}
export const SearchProviderContext = createContext<ISearchProvider>(
  {} as ISearchProvider
);

export const useSearch = (): ISearchProvider => {
  return useContext(SearchProviderContext);
};

export const SearchProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const searchByTag = (name: string) => {
    setSearchTerm(name);
    setSearchActive(true);
  };

  return (
    <SearchProviderContext.Provider
      value={{
        searchActive,
        setSearchActive,
        searchTerm,
        setSearchTerm,
        searchByTag,
      }}
    >
      {children}
    </SearchProviderContext.Provider>
  );
};
