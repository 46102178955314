import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css<{ extendImageOverGrid?: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin: 24px 0;
  border: 1px solid #e8eff3;
  padding: 8px;
  border-radius: 12px;
  white-space: nowrap;
`;

const Silver = css``;

const Gold = css<{ extendImageOverGrid?: boolean }>``;

const Platinum = css``;

const Diamond = css<{ extendImageOverGrid?: boolean }>`
${({ extendImageOverGrid }) =>
  extendImageOverGrid
    ? css`
        margin-left: -76px;
      `
    : ""})}
`;

export const SButtonBarWrapper = styled.div<{ extendImageOverGrid?: boolean }>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
