"use client";
import styled, { css } from "styled-components";

const Default = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: var(--ui-03);
  opacity: 1;
  transition: 0.2s opacity ease-in;
  cursor: pointer;
  position: absolute;
  top: 0;
`;

export const SLazyImage: any = styled.img<{ loading?: boolean }>`
  ${Default};
`;
