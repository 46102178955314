"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSHighlight } from "../../../constants/styles/typographies/CSSTypographies";

interface ICompPricingHeaderWrapperDiscountBatch {
  bgGradient?: string;
}

const Bronze = css<ICompPricingHeaderWrapperDiscountBatch>`
  background: ${({ bgGradient }) =>
    bgGradient && backgroundGradients[bgGradient]};
  padding: 4px 8px;
  border-radius: 12px;
  color: var(--text-02);
  text-transform: uppercase;
  ${CSSHighlight}
`;

const Silver = css<ICompPricingHeaderWrapperDiscountBatch>``;

const Gold = css<ICompPricingHeaderWrapperDiscountBatch>``;

const Platinum = css<ICompPricingHeaderWrapperDiscountBatch>``;

const Diamond = css<ICompPricingHeaderWrapperDiscountBatch>``;

export const SCompPricingHeaderWrapperDiscountBatch = styled.p<ICompPricingHeaderWrapperDiscountBatch>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
