"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { IGenSpacer, Maybe } from "../../../utils/types_gen";

const generateCss = (val) => {
  return val ? `padding-top: ${val}${isNaN(parseInt(val)) ? "" : "px"};` : "";
};

interface ISSectionContainerWrapper {
  $overlapBottom: Maybe<boolean> | undefined;
  $distanceAboveTitle: Maybe<IGenSpacer> | undefined;
}

const CSSOverlapBottom = css`
  position: relative;
  z-index: 1;
  margin-bottom: -40px;
`;

const Bronze = css<ISSectionContainerWrapper>`
  ${({ $overlapBottom }) => $overlapBottom && CSSOverlapBottom};
  ${({ $distanceAboveTitle }) => generateCss($distanceAboveTitle?.bronze)}
`;

const Silver = css<ISSectionContainerWrapper>``;

const Gold = css<ISSectionContainerWrapper>`
  ${({ $distanceAboveTitle }) => generateCss($distanceAboveTitle?.gold)}
`;

const Platinum = css<ISSectionContainerWrapper>`
  margin-bottom: ${({ $overlapBottom }) => ($overlapBottom ? "-80px" : 0)};
  ${({ $distanceAboveTitle }) => generateCss($distanceAboveTitle?.platinum)}
`;

const Diamond = css<ISSectionContainerWrapper>``;

export const SSectionContainerWrapper = styled.div<ISSectionContainerWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
