"use client";
import React, { useEffect, useRef, useState } from "react";
import { SAnimatedSvg } from "./styles/SAnimatedSvg";
import { CmsAnimatedCircleDots } from "../../svgs/CmsSvgAnimationCircleDots";
import { CmsAnimatedLogo } from "../../svgs/CmsSvgAnimationLogo";
import DevProfilePic from "../../svgs/devProfilePic.png";
import EditorProfilePic from "../../svgs/editorProfilePic.png";
import WebsitePic from "../../svgs/WebsitePic.png";
import TranslatorProfilePic from "../../svgs/translatorProfilePic.png";
import { CmsAnimatedItemImageDevTag } from "../../svgs/cmsSvgAnimationItemImgDevTag";
import Image from "next/image";
import { SAnimatedSvgItem } from "./styles/SAnimatedSvgItem";
import { CmsSvgAnimationItemImgEditorTag } from "../../svgs/cmsSvgAnimationItemImgEditorTag";
import { CmsAnimatiedSvgItemImgTranslatorTag } from "../../svgs/cmsSvgAnimationItemImgTranslatorTag";
import { BottomLeftLine } from "../../svgs/animatedSvgLines/bottomLeftLine";
import { SAnimatedSvgLinesWrapper } from "./styles/SAnimatedSvgLinesWrapper";
import { BottomRightLine } from "../../svgs/animatedSvgLines/bottomRightLine";
import { useInView } from "../../hooks/useInView";
import { MiddleLeftLine } from "../../svgs/animatedSvgLines/middleLeftLine";
import { MiddleRightLine } from "../../svgs/animatedSvgLines/middleRightLine";
import { TopLeftLine } from "../../svgs/animatedSvgLines/topLeftLine";
import { TopRightLine } from "../../svgs/animatedSvgLines/topRightLine";
import { LineCircle } from "../../svgs/animatedSvgLines/lineCircle";

interface IAnimatedSvgItems {
  id: number;
  title: string;
  image?: { src: string; alt: string; tag?: any };
}

export const AnimatedSvg: React.FC<any> = () => {
  const [items] = useState<IAnimatedSvgItems[]>([
    {
      id: 1,
      title: "Developer",
      image: {
        src: DevProfilePic?.src,
        alt: "alt text",
        tag: <CmsAnimatedItemImageDevTag />,
      },
    },
    {
      id: 2,
      title: "Website",
      image: { src: WebsitePic?.src, alt: "alt text" },
    },

    {
      id: 5,
      title: "Translator",
      image: {
        src: TranslatorProfilePic?.src,
        alt: "alt text",
        tag: <CmsAnimatiedSvgItemImgTranslatorTag />,
      },
    },
    {
      id: 6,
      title: "Store",
      image: { src: WebsitePic?.src, alt: "alt text" },
    },
    {
      id: 3,
      title: "Editor",
      image: {
        src: EditorProfilePic?.src,
        alt: "alt text",
        tag: <CmsSvgAnimationItemImgEditorTag />,
      },
    },
    {
      id: 4,
      title: "Blog",
      image: { src: WebsitePic?.src, alt: "alt text" },
    },
  ]);
  const elementRef = useRef(null);
  const inView = useInView(elementRef);

  useEffect(() => {
    if (inView) {
      elementRef.current?.children?.[0]?.classList?.add("visible");
    }
    if (!inView) {
      elementRef.current?.children?.[0]?.classList?.remove("visible");
    }
  }, [inView]);

  return (
    <SAnimatedSvg ref={elementRef}>
      {inView && (
        <div className="svg">
          <CmsAnimatedCircleDots />
          <CmsAnimatedLogo />

          <SAnimatedSvgLinesWrapper>
            <div>
              <div className="line-dots">
                {Array(6)
                  .fill(0)
                  .map((_, index) => (
                    <>
                      <LineCircle key={index} />
                    </>
                  ))}
              </div>
              <TopRightLine />
              <TopLeftLine />
              <MiddleLeftLine />
              <MiddleRightLine />
              <BottomLeftLine />
              <BottomRightLine />
            </div>
          </SAnimatedSvgLinesWrapper>

          <div className="items">
            {items?.map(
              (item: IAnimatedSvgItems, itemIndex: number) =>
                item &&
                inView && (
                  <SAnimatedSvgItem
                    key={itemIndex}
                    itemIndex={itemIndex}
                    className={`item item-${itemIndex + 1}`}
                  >
                    <div className="item-img">
                      <Image
                        width={200}
                        height={200}
                        src={item.image.src}
                        alt={item.image.alt}
                      />
                      <span>{item?.image?.tag && item.image.tag}</span>
                    </div>
                    <div className="item-title">{item?.title}</div>
                  </SAnimatedSvgItem>
                )
            )}
          </div>
        </div>
      )}
    </SAnimatedSvg>
  );
};
