"use client";
import { FC, useMemo, useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import { IOnboardingProjectTemplates } from "../../services/starter-template/types";
import { Spacer } from "../spacer/Spacer";
import { OnboardingTemplatesTemplate } from "./OnboardingTemplatesTemplate";
import { OnboardingTemplatesTabs } from "./OnboardingTemplateTabs/OnboardingTemplatesTabs";
import { SOnboardingTemplatesGrid } from "./styles/SOnboardingTemplatesGrid";

export const OnboardingTemplatesInner: FC<IOnboardingProjectTemplates> = ({
  projectTemplates = [],
  tabs = [],
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]?.id);

  const filteredTemplates = useMemo(() => {
    return projectTemplates.filter((template) =>
      template.stack.some(({ id }) => id === selectedTab)
    );
  }, [projectTemplates, selectedTab]);

  return (
    <GridPadding>
      <Grid>
        <GridItem
          bronze={{ col: { start: 1, end: 12 }, row: 1 }}
          gold={{ col: { start: 1, end: 3 }, row: 1 }}
        >
          <OnboardingTemplatesTabs
            tabs={tabs.filter((tab) => {
              return !!projectTemplates?.find((template) =>
                template?.stack?.find(({ id }) => id === tab.id)
              );
            })}
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
          />
        </GridItem>
        <GridItem
          bronze={{ col: { start: 1, end: 12 }, row: 2 }}
          gold={{ col: { start: 4, end: 12 }, row: 1 }}
        >
          <Spacer bronze={40} gold={0}></Spacer>
          <SOnboardingTemplatesGrid>
            {filteredTemplates.map((template) => (
              <OnboardingTemplatesTemplate
                key={template.id}
                template={template}
              />
            ))}
          </SOnboardingTemplatesGrid>
        </GridItem>
      </Grid>
    </GridPadding>
  );
};
