"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  border: 1px solid var(--ui-03);
  border-radius: 50%;
  width: 52px;
  height: 52px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Silver = css``;

const Gold = css`
  position: absolute;
  left: calc(0px - 64px - 24px);
  width: 64px;
  height: 64px;
  margin-top: 0;
`;

const Platinum = css``;

const Diamond = css`
  left: calc(0px - 80px - 24px);
  width: 80px;
  height: 80px;
`;

export const SCompImageTextIconWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};

`;
