"use client";
import styled, { css } from "styled-components";
import {
  MIN_DIAMOND,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_SILVER,
} from "../../../../constants/styles/mediaquerys";
import {
  CSSSubtitle01,
  CSSSubtitle01Semi,
} from "../../../../constants/styles/typographies/CSSTypographies";

interface ICompFaqItemTextWrapper {
  height?: number;
  showDescription?: boolean;
}

const Bronze = css<ICompFaqItemTextWrapper>`
  ${CSSSubtitle01};
  border-top: 1px solid var(--ui-03);
  padding: 24px;

  a {
    ${CSSSubtitle01Semi};
    color: var(--text-09);
    cursor: pointer;
    transition: color 300ms;

    &:hover {
      color: var(--hover-text-09);
    }
  }
`;

const Silver = css<ICompFaqItemTextWrapper>``;

const Gold = css<ICompFaqItemTextWrapper>``;

const Platinum = css<ICompFaqItemTextWrapper>``;

const Diamond = css<ICompFaqItemTextWrapper>``;

export const SCompFaqItemText = styled.div<ICompFaqItemTextWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
