"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { CSSBody03SemiXLarge } from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 12px;
  background-color: var(--active-ui-01-2);
  ${CSSBody03SemiXLarge};
  color: var(--text-01);
  border-radius: 12px;
  max-width: 100%;
  min-width: 512px;
  cursor: pointer;

  > td:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--hover-icon-08);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompTableSectionHeader = styled.tr`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
