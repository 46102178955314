"use client";
import styled from "styled-components";

export const SLazyImagePlaceholderNativeFake: any = (styled.div as any).attrs(
  (props: { clipPath?: string }) => ({
    style: {
      clipPath: props.clipPath || "inset(0 0 0 0)",
    },
  })
)`
  clip-path: inset(0 0 0 0);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  margin-left: -4px;
  margin-top: -4px;
  inset: 0;
  background: #eaeced;
`;
