import { css } from "styled-components";
import { CSSArticleSectionScrollbar } from "../../components/comp-article-section/CSSArticleSectionScrollbar";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "./mediaquerys";
import {
  CssBody02Med,
  CSSBody03,
  CSSBody03Semi,
  CSSHighlight,
  CSSBody03Med,
  CSSCaption01Semi,
  CSSTitle01,
  CSSTitle02,
  CSSBody03SemiXLarge,
  CSSHighlightXLarge,
  CSSBody03XLarge,
  CSSBody03MedXLarge,
  CSSCaption01SemiXLarge,
  CSSCaption01MedXLarge,
  CSSTitle01Xlarge,
  CSSTitle02Xlarge,
} from "./typographies/CSSTypographies";

const Bronze = css`
  ${CssBody02Med};
  color: var(--text-04);

  a {
    color: var(--text-09);
  }

  *:not(pre) > code {
    font-family: var(--font-ibm-plex-mono);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--active-text-03);
    padding: 2px 4px;
    background: var(--ui-03);
    border-radius: 4px;
    word-break: break-word;
  }

  table {
    border-collapse: unset;
    border: 1px solid var(--ui-03);
    border-radius: 8px;
    max-width: 100%;
    display: block;
    width: fit-content;
    position: relative;
    overflow: hidden;

    tbody {
      width: 100%;
      display: block;
      overflow-x: auto;
      ${CSSArticleSectionScrollbar};

      ::-webkit-scrollbar-thumb {
        border: 4px solid var(--ui-03);
        background-clip: padding-box;
        -webkit-border-radius: 49px;
        background-color: var(--interactional-secondary-08);
        width: 4px;
      }
    }

    tr {
      td {
        min-width: 81px;
        ${CSSBody03};
        color: var(--text-01);
      }

      th,
      td {
        border-bottom: 1px solid var(--ui-03);
        border-right: 1px solid var(--ui-03);
        padding: 16px;

        &:last-of-type {
          border-right: 0;
        }
      }

      &:first-of-type {
        td,
        th {
          ${CSSHighlight};
          background: var(--ui-02);
          text-transform: uppercase;
          color: var(--text-04);
          text-align: left;
        }
      }
    }
  }

  li {
    > p {
      padding: 0;
    }
  }

  ol {
    p {
      padding: 0;
      min-height: 20px;
    }

    > li {
      > p {
        ${CSSBody03Med};
        padding: 0;
      }

      color: var(--text-01);
      padding: 12px 0;
      counter-increment: circle-counter;
      display: flex;
      align-items: center;
      gap: 12px;
      margin-left: 0;

      &:before {
        content: counter(circle-counter);
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        ${CSSCaption01Semi};
        border-radius: 50%;
        text-align: center;
        position: relative;
        background: var(--ui-03);
        color: var(--text-04);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    + ul {
      margin-top: 8px;
      margin-left: 20px;
    }
  }

  ul {
    p {
      padding: 0;
    }

    li {
      padding: 0;
      list-style-type: disc;
      color: var(--text-04);
      margin-bottom: 4px;

      > p {
        padding: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  iframe {
    display: flex;
    width: 100%;
    min-height: 480px;
  }

  blockquote,
  ul,
  ol {
    p {
      padding: 0;
    }
  }

  ul,
  ol {
    margin-top: 16px;
  }

  blockquote {
    > * {
      &:not(p) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      padding: 20px 28px 20px 20px;
      gap: 16px;
      background: var(--ui-02);
      border-radius: 8px;

      &:last-child:not(:first-child) {
        border-radius: 0 0 8px 8px;
        padding: 0 20px 20px 20px;
      }

      &:first-child:not(:last-child) {
        border-radius: 8px 8px 0 0;
        padding: 20px 28px 0 20px;
      }
    }

    > p {
      &,
      &:last-child,
      &:first-child,
      &:first-child + &:last-child {
        padding: 20px 28px 20px 20px;
      }
    }
  }

  section:not(.article-section),
  & {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
      color: var(--text-01);
      strong,
      span > strong {
        color: var(--text-01);
      }
    }
  }
  & > p {
    strong,
    span > strong {
      color: var(--text-01);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;
  }

  h1 {
    ${CSSTitle01};
  }

  h2 {
    ${CSSTitle02};
  }

  h3 {
    ${CSSBody03Semi};
  }
`;

const Silver = css``;

const Gold = css`
  table {
    tr:first-of-type {
      td {
        ${CSSHighlightXLarge};
      }
    }

    th,
    td {
      min-width: 94px;
      ${CSSBody03XLarge};
    }
  }
`;

const Platinum = css``;

const Diamond = css`
  ${CSSCaption01MedXLarge};
  line-height: 140%;

  *:not(pre) > code {
    font-size: 16px;
  }

  h1 {
    ${CSSTitle01Xlarge};
  }

  h2 {
    ${CSSTitle02Xlarge};
  }

  h3 {
    ${CSSBody03SemiXLarge};
  }

  ol {
    > li {
      padding: 16px 0;
      gap: 16px;

      > p {
        ${CSSBody03MedXLarge};
      }

      &::before {
        ${CSSCaption01SemiXLarge};
        width: 28px;
        height: 28px;
      }
    }

    + ul {
      margin-left: 28px;
    }
  }

  ul {
    p {
      padding: 0;
    }

    li {
      padding: 0;
      margin-bottom: 8px;
      > p {
        padding: 0;
        font-size: 18px;
        line-height: 140%;
      }
    }
  }
`;

export const CSSRichtext = css`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
