"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    background: #111d34;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 68px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  width: 32px;
  height: 32px;

  &:after {
    width: calc(100% + 32px);
    height: calc(100% + 32px);
  }
`;

export const SCompCardWithIconIcon = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
