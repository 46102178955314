export const CmsAnimatedCircleDots = () => (
  <svg
    width="372"
    height="372"
    viewBox="0 0 372 372"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="dots"
  >
    <circle
      cx="186"
      cy="186"
      r="185.5"
      stroke="#415983"
      strokeDasharray="1 8"
    />
    <circle
      cx="186"
      cy="186"
      r="121.5"
      stroke="#415983"
      strokeDasharray="1 8"
    />
    <circle
      cx="186"
      cy="186"
      r="155.5"
      stroke="#415983"
      strokeDasharray="1 8"
    />
    <circle cx="186" cy="186" r="86.5" stroke="#415983" strokeDasharray="1 8" />
  </svg>
);
