export const TopRightLine = () => (
  <svg
    id="top-right"
    width="195"
    height="103"
    viewBox="0 0 195 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="top-right-path"
      d="M-9.65596e-06 102L34.5378 102C48.3449 102 59.5378 90.8071 59.5378 77L59.5378 26C59.5378 12.1929 70.7307 1.00001 84.5378 1.00001L195 1.00001"
      stroke="url(#paint0_linear_7928_195526)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7928_195526"
        x1="142.011"
        y1="52.3707"
        x2="-38.1527"
        y2="52.3707"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.288449" stopColor="#33425F" />
        <stop offset="1" stopColor="#008CFF" />
      </linearGradient>
    </defs>
  </svg>
);
