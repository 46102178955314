import styled, { css } from "styled-components";
import {
  CSSBody03Semi,
  CSSBody03SemiXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  color: var(--text-01);
  display: flex;
  align-items: center;
  gap: 12px;
  ${CSSBody03Semi};
`;

const Silver = css`
  ${CSSBody03SemiXLarge};
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SCompTableCellRichTitle = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
