"use client";
import { NextImage } from "src/components/next-image/NextImage";
import React, { useState } from "react";
import { GridItem } from "../../base-components/grid-item/GridItem";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { Grid } from "../../base-components/grid/Grid";
import { RichText } from "../../base-components/rich-text/RichText";
import { IGenSliderWithText } from "../../utils/types_gen";
import { Button } from "../button/Button";
import { Label } from "../label/Label";
import { SCompSliderWithText } from "./styles/SCompSliderWithText";
import { SCompImageTextBlockTitle } from "../comp-image-text/styles/SCompImageTextBlockTitle";
import { SCompImageTextDescription } from "../comp-image-text/styles/SCompImageTextDescription";
import { SCompImageTextDescriptionWrapper } from "../comp-image-text/styles/SCompImageTextDescriptionWrapper";
import { SCompImageTextIcon } from "../comp-image-text/styles/SCompImageTextIcon";
import { SCompImageTextIconTextBlockWrapper } from "../comp-image-text/styles/SCompImageTextIconTextBlockWrapper";
import { SCompImageTextIconWrapper } from "../comp-image-text/styles/SCompImageTextIconWrapper";
import { SCompImageTextLabelBtnContainer } from "../comp-image-text/styles/SCompImageTextLabelBtnContainer";
// import { ImgV2 } from "../../base-components/img-v2/Img";
import { SCompSliderWithTextImageWrapper } from "./styles/SCompSliderWithTextImageWrapper";
import { BarButton } from "../buttons-bar/ButtonsBar";
import { SImageAnimationWrapper } from "../comp-custom-element-with-text/image-animation/styles/SImageAnimationWrapper";
import { SButtonBarWrapper } from "../buttons-bar/styles/SButtonBarWrapper";
// import { SCompImageTextAssetWrapper } from "../comp-image-text/styles/SCompImageTextAssetWrapper";

export interface ICompSliderWithText extends IGenSliderWithText {
  isSectionBgWhite?: boolean;
}

export const CompSliderWithTextInner: React.FC<ICompSliderWithText> = ({
  id,
  icon,
  title,
  text,
  label,
  button,
  isSectionBgWhite,
  elements,
  extendImageOverGrid,
  // noBorderRadius,
  alignTextblockInCenter,
  // imageHasShadow,
  swap,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);

  let paddingTopAspectRatio = 0;
  elements?.forEach((element) => {
    if (
      element.image?.width &&
      element.image?.height &&
      element.image?.height / element.image?.width > paddingTopAspectRatio
    ) {
      paddingTopAspectRatio = element.image?.height / element.image?.width;
    }
  });

  return (
    <SCompSliderWithText id={id}>
      <GridPadding>
        <Grid
          style={{ alignItems: alignTextblockInCenter ? "center" : undefined }}
        >
          <GridItem
            bronze={{
              row: { start: 3, end: 3 },
              col: { start: 1, end: 12 },
            }}
            gold={{
              row: { start: 1, end: 1 },
              col: {
                start: swap ? 2 : extendImageOverGrid ? 9 : 8,
                end: swap ? 5 : 12,
              },
            }}
          >
            <SCompImageTextIconTextBlockWrapper>
              {icon?.src ? (
                <SCompImageTextIconWrapper>
                  <SCompImageTextIcon>
                    <NextImage
                      src={icon.src}
                      alt={icon.description || icon.title}
                      width={100}
                      height={100}
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </SCompImageTextIcon>
                </SCompImageTextIconWrapper>
              ) : null}
              <SCompImageTextDescriptionWrapper isCompHasIcon={!!icon?.src}>
                <SCompImageTextBlockTitle $isSectionBgWhite={isSectionBgWhite}>
                  {title}
                </SCompImageTextBlockTitle>
                <SCompImageTextDescription $isSectionBgWhite={isSectionBgWhite}>
                  <RichText content={text?.json?.content} />
                </SCompImageTextDescription>
                <SCompImageTextLabelBtnContainer>
                  {label?.length > 0 &&
                    label &&
                    label?.map(
                      (item) => item && <Label key={item.id} {...item} />
                    )}
                  {button?.length > 0 &&
                    button &&
                    button?.map(
                      (item) =>
                        item && (
                          <Button key={item.id} {...item}>
                            {item.title}
                          </Button>
                        )
                    )}
                </SCompImageTextLabelBtnContainer>
              </SCompImageTextDescriptionWrapper>
            </SCompImageTextIconTextBlockWrapper>
          </GridItem>
          {elements && (
            <GridItem
              bronze={{
                row: { start: 1, end: 1 },
                col: { start: 1, end: 12 },
              }}
              gold={{
                row: { start: 1, end: 1 },
                col: {
                  start: swap ? 7 : extendImageOverGrid ? 1 : 2,
                  end: swap ? (extendImageOverGrid ? 12 : 11) : 6,
                },
              }}
            >
              <div
                style={{
                  display: "block",
                }}
              >
                {elements?.length > 0 && (
                  <>
                    <SImageAnimationWrapper>
                      <SButtonBarWrapper
                        extendImageOverGrid={extendImageOverGrid}
                      >
                        {elements?.map(
                          (element, index) =>
                            (element && (
                              <>
                                <BarButton
                                  key={index}
                                  styleType={
                                    activeSlide === index
                                      ? "primary"
                                      : "secondary"
                                  }
                                  element={element}
                                  click={() => setActiveSlide(index)}
                                />
                              </>
                            )) ??
                            null
                        )}
                      </SButtonBarWrapper>

                      {elements?.map(
                        (element, index) =>
                          element &&
                          index == activeSlide && (
                            <SCompSliderWithTextImageWrapper key={index}>
                              <NextImage
                                alt={
                                  element?.image?.description ||
                                  element?.image?.title
                                }
                                width={element?.image?.width}
                                height={element?.image?.height}
                                style={{
                                  objectFit: "contain",
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={element?.image?.src}
                                //cover={false}
                              />
                            </SCompSliderWithTextImageWrapper>
                          )
                      )}
                    </SImageAnimationWrapper>
                  </>
                )}
              </div>
            </GridItem>
          )}
        </Grid>
      </GridPadding>
    </SCompSliderWithText>
  );
};
