"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  svgFirstStopsVariables,
  svgSecondStopsVariables,
} from "../../../svgs/GradientCheckmarkIcon";

interface ISCompPricingHeaderWrapperCardOptionSvg {
  gradientAccent?: string;
  bgGradient?: string;
}

const Bronze = css<ISCompPricingHeaderWrapperCardOptionSvg>`
  flex-shrink: 0;
  line-height: 0;

  svg {
    width: 20px;
    height: 20px;

    .stop-color-1 {
      color: ${({ gradientAccent }) =>
        svgFirstStopsVariables[gradientAccent || "blue"]};
    }

    .stop-color-2 {
      color: ${({ gradientAccent }) =>
        svgSecondStopsVariables[gradientAccent || "blue"]};
    }

    .stroke {
      color: ${({ gradientAccent, bgGradient }) =>
        gradientAccent === "white"
          ? svgFirstStopsVariables[bgGradient]
          : "var(--icon-02)"};
    }
  }
`;

const Silver = css<ISCompPricingHeaderWrapperCardOptionSvg>``;

const Gold = css<ISCompPricingHeaderWrapperCardOptionSvg>``;

const Platinum = css<ISCompPricingHeaderWrapperCardOptionSvg>``;

const Diamond = css<ISCompPricingHeaderWrapperCardOptionSvg>`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SCompPricingHeaderWrapperCardOptionSvg = styled.div<ISCompPricingHeaderWrapperCardOptionSvg>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
