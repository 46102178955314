"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody03,
  CSSBody03XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompPricingHeaderWrapperCardOptionText {
  bgGradient?: string;
}

const Bronze = css<ISCompPricingHeaderWrapperCardOptionText>`
  color: ${({ bgGradient }) => {
    return bgGradient != "white" ? "var(--text-02)" : "var(--text-01)";
  }};
  ${CSSBody03};
`;

const Silver = css<ISCompPricingHeaderWrapperCardOptionText>``;

const Gold = css<ISCompPricingHeaderWrapperCardOptionText>``;

const Platinum = css<ISCompPricingHeaderWrapperCardOptionText>``;

const Diamond = css<ISCompPricingHeaderWrapperCardOptionText>`
  ${CSSBody03XLarge};
`;

export const SCompPricingHeaderWrapperCardOptionText = styled.p<ISCompPricingHeaderWrapperCardOptionText>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
