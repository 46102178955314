"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompWideVideoOrImage {
  overlapTop?: boolean;
}

const Bronze = css<ISCompWideVideoOrImage>`
  margin-top: ${({ overlapTop }) => overlapTop && "-60px"};
`;

const Silver = css<ISCompWideVideoOrImage>``;

const Gold = css<ISCompWideVideoOrImage>`
  margin-top: ${({ overlapTop }) => overlapTop && "-114px"};
`;

const Platinum = css<ISCompWideVideoOrImage>``;

const Diamond = css<ISCompWideVideoOrImage>`
  margin-top: ${({ overlapTop }) => overlapTop && "-260px"};
`;

export const SCompWideVideoOrImage = styled.div<ISCompWideVideoOrImage>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
